import styled, { css } from 'styled-components';

interface Props {
  marginRight: number;
  active: boolean;
  theme: any;
}

const style: any = {};

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.Root = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 768px;
  height: 100%;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 5px 32px 0 rgba(34, 49, 63, 0.25);
  z-index: 999;

  :hover + div {
    background: rgba(0, 0, 0, 0.1);
  }

  .document-view {
    padding: 0;
    margin: 0;
    border: none;
    overflow: hidden;
  }
`;

style.Background = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  background: transparent;

  transition: background 0.1s ease-out;
  z-index: 998;
`;

style.Header = styled(Tab)`
  height: 56px;
  padding-right: 8px;
  align-items: center;
  flex-shrink: 0;
`;

style.HeaderCloseButton = styled.div`
  padding: 12px 8px 12px 12px;
`;

style.HeaderTitle = styled.div`
  font-size: 17px;
  font-family: "Roboto-Medium", Helvetica, Arial, sans-serif;
  padding-top: 1px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
`;

style.Tools = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: end;
  align-items: center;
`;

style.Tool = styled.div`
  margin-right: ${(props: Props) => props.marginRight || 8}px;
`;

style.TabBar = styled(Tab)`
  height: 48px;
  padding: 0 8px;
`;

style.Tab = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

style.TabItem = styled.div`
  text-transform: uppercase;
  font: 13px Roboto-Medium;
  height: 48px;
  display: flex;
  margin: 0 8px;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.colors.stone};
  position: relative;
  cursor: pointer;

  transition: color 0.2s ease;

  &:hover {
    color: ${(props: Props) => props.theme.colors.defaultText};
  }

  ${(props: Props) => props.active && css`
    color: ${props.theme.colors.defaultText};
    ::after {
      content: '';
      background: ${props.theme.colors.orange};
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
    }
  `}

  :nth-child(0) {
    margin: 0 15px 0 8px;
  }

  :nth-child(2) {
    margin: 0 15px 0 14px;
  }
`;

style.Workspace = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: ${(props: Props) => props.theme.colors.backgroundGrey};
`;

style.Label = styled.div`
  display: flex;
  align-items: center;
`;

export default style;
