import styled from 'styled-components';

interface Props {
  theme: any,
}

const style: any = {};

style.Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

style.Drawer = styled.div`
  width: 1px;
  height: 32px;
  margin: 0 8px;
  background: ${(props: Props) => props.theme.colors.borderColor};
`;

export default style;
