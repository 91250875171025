import { store } from '@redux/store';
import { StateBuilder } from './state';

export interface Block {
  id: string;
  title: string;
  body: string;
}

export type Body = Block[];
export interface ActionData {
  id: string;
  title: string;
  type: string;
  body: Body;
  is_actual: boolean;
}

export interface Action {
  type: string;
  data: ActionData;
}

export const fetchLawDocumentReduce = (state: ReturnType<typeof store.state>, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);
  newState.setDocumentId(action.data.id);
  newState.prepareTitles(action.data.body);
  newState.prepareDocumentBody(action.data.body);
  newState.prepareAttributes(action.data);
  newState.setDocumentLoading(false);

  return newState.getState();

};

export default fetchLawDocumentReduce;
