import React from 'react';
import { CellItemWithTooltip } from './itemWithTooltip';

import s from './item.style';

export interface Props {
  cell: {
    id: string;
    title: string;
    tooltip: string;
    isPercent: boolean;
  },
  columns: number;
  link: string;
  onClick: (e: MouseEvent) => void;
}

export const Cell = (props: Props) => {
  const { id, title } = props.cell;
  const isTitle = id === 'title';
  if (isTitle) {
    return (
      <s.CellTitle
        key={id}
      >
        <s.ColumnWidthTitle>
          <s.CellContainer>
            <s.BodyTextContainer
              isTitle
              isInner
            >
              <s.BodyTextLink
                href={props.link}
                target="_blank"
              >
                {title}
              </s.BodyTextLink>
            </s.BodyTextContainer>
          </s.CellContainer>
        </s.ColumnWidthTitle>
      </s.CellTitle>
    );
  }

  const isEmpty = title === '0' || title === '0%';

  return (
    <CellItemWithTooltip
      onClick={props.onClick}
      key={id}
      isEmpty={isEmpty}
      isPercent={props.cell.isPercent}
      title={title}
      tooltip={props.cell.tooltip}
      columns={props.columns}
    />
  );
};

export default Cell;
