import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';
import { UserRoleType } from '@pages/userPages/redux/state';
import {
  prepareHighlightIds,
  prepareReasonBlockIds,
  prepareReasonIds,
} from '@pages/searchResult/documents/utils';

import { props } from '@pages/searchResult/documents/preview/documentPreview.connect';

import { fetchDocumentThunk } from './redux/fetchDocument.thunk';
import { saveMarkersThunk } from './redux/saveMarkers.thunk';
import { saveCommentaryThunk } from './redux/saveCommentary.thunk';

import { DocumentPreview } from './documentPreview';

const mapStateToProps = (state, ownProps) => {
  const { currentPageNumber } = state.authorPackages.documents.previewDocument;
  const { claimant, defendant } = state.document.reasons;
  const reasonsIds = prepareReasonBlockIds(claimant, defendant);
  const activeReason = state.document.reasons.showReasons;
  const loadingReasons = state.document.reasons.loading;
  const loadingSuccess = state.document.reasons.loadingSuccess;
  const document = state.authorPackages.documents.pages[currentPageNumber].itemsMap.get(ownProps.documentId);
  if (!document) {
    return props;
  }

  const title = `${document.documentType} от ${document.date}`;

  const source = `${Source.authorPackage}s`;
  const sourceId = state.authorPackages.packages.activeItemId;
  const documentId = ownProps.documentId;

  return {
    isReal: state.currentUser.role !== UserRoleType.guest,
    baseHref: `#/${source}/${sourceId}/documents/${documentId}`,
    attributesLoaded: document.attributesLoaded,
    documentLoaded: document.documentLoaded,
    source,
    sourceId,
    documentId,
    data: {
      title,
      attributes: document.details.attributes,
      document: {
        title: document.details.title,
        department: document.details.attributes.department,
        body: document.details.document,
        activeReason,
        loadingReasons,
        loadingSuccess,
        isUserGuest: state.currentUser.role === UserRoleType.guest,
        highlightIds: prepareHighlightIds(document.details.document),
        reasonsIds: prepareReasonIds(document.details.document, reasonsIds),
      },
      resolution: {
        body: document.details.resolution,
        activeReason,
        loadingReasons,
        loadingSuccess,
        isUserGuest: state.currentUser.role === UserRoleType.guest,
        highlightIds: prepareHighlightIds(document.details.resolution),
        reasonsIds: prepareReasonIds(document.details.resolution, reasonsIds),
      },
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetch() {
    const params = {
      documentId: ownProps.documentId,
      source: Source.authorPackage,
    };

    fetchDocumentThunk(dispatch, params);
  },
  onCommentaryChange(commentary) {
    saveCommentaryThunk(dispatch, { commentary, documentId: ownProps.documentId });
  },
  onSaveMarkers(entities, sourceId) {
    const requestParams = {
      documentId: ownProps.documentId,
      source: Source.subscription,
      sourceId,
      data: {
        entities,
      },
    };

    saveMarkersThunk(dispatch, requestParams);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSaveMarkers(entities) {
    dispatchProps.onSaveMarkers(entities, stateProps.sourceId);
  },
});

export const DocumentPreviewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DocumentPreview);

export default DocumentPreviewConnect;
