import React from 'react';

import { Loader } from '@components/loader';

import { AnnotationConnect } from './annotation.connect';
import { SummaryConnect } from './summary.connect';
import { DocumentBodyConnect } from './documentBody.connect';
import { HighlightNavigation } from './highlightNavigation';
import { ReasonNavigation } from './reasonNavigation';

import type { Paragraph } from './redux/document';

import s from './documentTab.style';

interface DocumentTabProps {
  title: string;
  bookmarkTitle: string;
  documentId: number;
  source: string;
  sourceId: number;
  additionalSourceId?: number;
  department: string;
  body: Paragraph[];
  highlightIds: string[];
  reasonIds: string[];
  activeReason: boolean;
  loadingReasons: boolean;
  loadingSuccess: boolean;
  loading: boolean;
  isUserGuest?: boolean;
  onSaveMarkers(entities): void;
}

export const DocumentTab = (props: DocumentTabProps) => {

  if (props.loading) {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  const targetId = 'document-preview-container';
  const firstParagraphId = props.body[0] && props.body[0].id;

  return (
    <s.Root
      id={targetId}
    >
      <s.DocumentContainer>
        <AnnotationConnect
          documentId={props.documentId}
        />
        <SummaryConnect
          documentId={props.documentId}
        />
        <DocumentBodyConnect
          renderTitle
          onSaveMarkers={props.onSaveMarkers}
          documentId={props.documentId}
          bookmarkTitle={props.bookmarkTitle}
          source={props.source}
          sourceId={props.sourceId}
          additionalSourceId={props.additionalSourceId}
          title={props.title}
          department={props.department}
          body={props.body}
        />
        <s.Wrapper>
          <HighlightNavigation
            title="Результаты"
            ids={props.highlightIds}
            targetId={targetId}
            firstParagraphId={firstParagraphId}
          />
          <ReasonNavigation
            isUserGuest={props.isUserGuest}
            loadingReasons={props.loadingReasons}
            loadingSuccess={props.loadingSuccess}
            isActive={!props.activeReason}
            title="Доводы"
            ids={props.reasonIds}
            targetId={targetId}
            firstParagraphId={firstParagraphId}
          />
        </s.Wrapper>
      </s.DocumentContainer>
    </s.Root>
  );
};

export default DocumentTab;
