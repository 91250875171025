import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './navigationArrow.style';

interface NavigationArrowProps {
  direction: 'up' | 'down'
}

export const NavigationArrow = ({ direction }: NavigationArrowProps) => {
  const points = iconsPath.get('arrowDown');

  const transform = direction === 'down' ?
    '' :
    'translate(12.500000, 12.000000) rotate(180.000000) translate(-12.500000, -12.000000) ';

  return (
    <s.Root>
      <Icon
        transform={transform}
        points={points}
        color="#ffffff"
      />
    </s.Root>
  );
};

export default NavigationArrow;
