import { v4 as uuid } from 'uuid';
import isBoolean from 'lodash/isBoolean';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

type Id = number;
type Title = string;

export interface BlockData {
  id: Id;
  title: Title;
}

export class Block extends AbstractFilterBlock {

  static type = 'departmentType';

  static DTOType = 'department_type';

  static blockTitle = 'Принявший орган';

  static category = category.main;

  protected defaultData: BlockData = {
    // @ts-ignore
    id: null,
    // @ts-ignore
    title: null,
  };

  constructor() {
    super();

    this.setCategory(Block.category);
    this.setType({
      id: Block.type,
      DTOType: Block.DTOType,
      title: Block.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains, presets) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const updatedData = presets.find((preset) => preset.id === data.id);

    const block = {
      id,
      data: updatedData,
      contains,
    };

    this.create(block);
  }

  public setDataTitle(presets) {
    const id = this.getData().id;
    const title = presets.find((preset) => preset.id === id).title;

    const data = {
      id,
      title,
    };

    this.setData(data);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    const dataId = this.getData().id;

    return {
      id: this.getId(),
      contains: this.getContains(),
      type: Block.DTOType,
      data: {
        id: dataId,
      },
    };
  }

  // eslint-disable-next-line
  public validate() {}

  getItemsForSuggest(): Promise<any> {
    throw new Error('Method not implemented.');
  }

}

export default Block;
