import { Map } from 'immutable';

import type { Attributes } from '@pages/document/sidebar/redux/attributes';

import type {
  Document as DocumentType,
  Resolution as ResolutionType,
} from '../preview/redux/document';

export type LayoutType = 'list' | 'table';

interface Item {
  id: string;
  title: string;
  width: string;
}

type ItemMap = Map<string, Item>;

export interface Column {
  items: string[];
  itemsMap: ItemMap;
}

export interface PresetItem {
  id: string;
  title: string;
}

type TableViewItemsMap = Map<string, PresetItem>;

export interface Layout {
  viewType: LayoutType;
  tableView: {
    columns: {
      itemsList: string[];
      itemsMap: TableViewItemsMap
    };
    defaultColumns: string[];
  }
}

export type Total = number;
export type Loading = boolean;

interface Tag {
  id: string;
  title: string;
}

export interface Details {
  attributes: Attributes;
  document: DocumentType;
  resolution: ResolutionType,
  documentId: number;
}

type DocumentId = number;

export interface Document {
  id: DocumentId;
  selected: boolean;
  date: string;
  documentType: string;
  caseNumber: string;
  instanceNumber: string;
  department: string;
  departmentNameGenitive: string;
  caseResult: string;
  documentTitle: string;
  comment: string;
  packagesCount: number;
  initialData: any;
  attributesLoaded: boolean;
  documentLoaded: boolean;
  details: Details;
  tag?: Tag;
  title: string;
  isVisited: boolean;
  highlights: string;
}

export interface Page {
  items: number[];
  itemsMap: Map<DocumentId, Document>
}

export enum SelectionRule {
  include = 'include',
  exclude = 'exclude',
}

export interface Selection {
  rule: SelectionRule.include | SelectionRule.exclude;
  ids: Map<DocumentId, null>;
}

export interface State {
  shouldLoadSettings: boolean;
  layout: Layout;
  showCommentary: boolean;
  totalDocuments: Total;
  totalPages: Total;
  loading: Loading;
  pageLoading: Loading;
  selection: Selection;
  previewDocument: {
    currentPageNumber: number;
    documentId: number;
  };
  currentPageNumber: number;
  pages: Record<number, Page>;
}

export class StateBuilder {

  private readonly state: State;

  static createState(): State {
    return {
      shouldLoadSettings: true,
      layout: {
        viewType: 'list',
        tableView: {
          columns: {
            itemsList: [],
            itemsMap: Map(),
          },
          defaultColumns: [],
        },
      },
      showCommentary: false,
      totalDocuments: null,
      totalPages: null,
      previewDocument: {
        currentPageNumber: 1,
        documentId: null,
      },
      currentPageNumber: 1,
      selection: {
        rule: SelectionRule.include,
        ids: Map(),
      },
      pages: {},
      loading: true,
      pageLoading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

  public toggleSelectionRule(): State {
    const selection: Selection = {
      rule: this.state.selection.rule,
      ids: Map(),
    };

    if (this.state.selection.ids.count() === this.state.totalDocuments) {
      return {
        ...this.state,
        selection,
      };
    }

    if (this.state.selection.rule === SelectionRule.include) {
      selection.rule = SelectionRule.exclude;
    } else {
      selection.rule = SelectionRule.include;
    }

    return {
      ...this.state,
      selection,
    };
  }

  public toggleItemSelect(id: DocumentId): State {
    const hasId = this.state.selection.ids.has(id);

    let ids;

    if (hasId) {
      ids = this.deleteItemIdFromSelection(id);
    } else {
      ids = this.addItemIdToSelection(id);
    }

    const selection = { ...this.state.selection };
    selection.ids = ids;

    return {
      ...this.state,
      selection,
    };
  }

  private addItemIdToSelection(id: DocumentId) {
    return this.state.selection.ids.set(id, null);
  }

  private deleteItemIdFromSelection(id: DocumentId) {
    return this.state.selection.ids.delete(id);
  }

}

export default StateBuilder;
