import React from 'react';

import s from './tooltipReasonItem.style';

interface ReasonItemProps {
  reason: {
    titles: string[];
    description: string;
  }
}

export const ReasonItem:React.FC<ReasonItemProps> = (props) => {
  if (!props.reason) {
    return null;
  }

  const renderReasonTitle = (title) => (
    <s.Title>
      {title}
    </s.Title>
  );

  return (
    <s.Root>
      <s.Description>
        {props.reason.description}
      </s.Description>
      {props.reason.titles.map(renderReasonTitle)}
    </s.Root>
  );
};

export default ReasonItem;
