import { StateBuilder } from './state';

export const resetToInitialReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    document: StateBuilder.createInitialState(),
  };
};

export default resetToInitialReduce;
