import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

interface Props {
  isActive: boolean;
  isChildren: boolean;
}

style.Root = styled.div`
  cursor: pointer;
  padding: 12px 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  & > div:nth-child(2) {
    display: none;
  }

  :hover {
    background: #4183D712;

    & > div:nth-child(2) {
       display: flex;
    }
  }

  ${(props: Props) => props.isActive && css`
    :hover {
      background: #4183D712;
    }

    &::before {
      content: '';
      width: 2px;
      height: 36px;
      position: absolute;
      background-color: ${theme.colors.blue};
      left: 2px;
      display: block;
    }

    & > div:nth-child(2) {
       display: flex;
    }
  `}

  ${(props: Props) => props.isChildren && css`
    padding-left: 48px;
  `}
`;

export default style;
