/* eslint-disable */
import React from 'react';

import { ReasonItem } from './tooltipReasonItem'

import { withClickOutside } from './clickOutsideHandler';

import s from './tooltipReasons.style';

interface ReasonItem {
  description: string;
  titles: string[];
}

interface Props {
  reasonClaimant: ReasonItem;
  reasonDefendant: ReasonItem;
}

class TooltipReasons extends React.PureComponent<Props> {

  refRoot = React.createRef();

  state = {
    style: {
      top: 0,
      left: 0,
    },
  };

  constructor(props) {
    super(props);

    const callback = () => {
      this.setStyles();
    };

    // @ts-ignore
    this.observer = new MutationObserver(callback);
  }

  componentDidMount() {
    this.setStyles();
    this.setObserver();
  }

  componentWillUnmount() {
    // @ts-ignore
    this.observer.disconnect();
  }

  setObserver = () => {
    const target = this.refRoot.current;
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    };

    // @ts-ignore
    this.observer.observe(target, config);
  };

  setStyles = () => {
    const reference = this.refRoot.current;
    if (!reference) {
      return;
    }

    const PARAGRAPH_INDENT_WIDTH = 128;

    // @ts-ignore
    const top = reference.offsetHeight;

    // @ts-ignore
    const left = ((this.props.tooltipXPosition + PARAGRAPH_INDENT_WIDTH) - (reference.offsetWidth)) / 2;

    this.setState({
      style: {
        top,
        left,
      },
    });
  };

  renderTooltip() {
    return (
      <>
        <ReasonItem
          reason={this.props.reasonClaimant}
        />
        <ReasonItem
          reason={this.props.reasonDefendant}
        />
      </>
    );
  }

  render() {
    return (
      <s.Root
        top={this.state.style.top}
        left={this.state.style.left}
        // @ts-ignore
        ref={this.refRoot}
      >
        {this.renderTooltip()}
      </s.Root>
    );
  }

}

export const TooltipReasonsWithClickOutside = withClickOutside(TooltipReasons);

export default TooltipReasonsWithClickOutside;
