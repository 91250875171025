import { connect } from 'react-redux';

import { Document } from './document';

const mapStateToProps = (state) => {
  const {
    document,
    loading,
  } = state.searchLawResult.document;

  return {
    body: document,
    loading,
  };
};

export const DocumentConnect = connect(
  mapStateToProps,
)(Document);

export default DocumentConnect;
