import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { ItemDetailConnect } from './itemDetail.connect';
import { CellItemWithTooltip } from './itemWithTooltip';
import s from './item.style';

import type { DataFromView } from './item.connect';

export interface Props {
  item: {
    id: string,
    title: string,
    hasArticles: boolean;
    hasClauses: boolean;
    isActual: boolean;
  };
  columns: any;
  onAddLaw(data: DataFromView): void;
}

interface State {
  showDetails: boolean;
}

export class Item extends React.PureComponent<Props, State> {

  state = {
    showDetails: false,
  };

  handleItemClick = (event) => {
    const tag = event.target.tagName.toLowerCase();
    const isNativeAnchor = tag === 'a';
    if (isNativeAnchor) {
      return;
    }

    this.setShowDetails();
  };

  setShowDetails = () => {
    this.setState((prevState) => ({
      showDetails: !prevState.showDetails,
    }));
  };

  handleCellClick = (columnId) => {
    const data = {
      columnId,
      document: this.props.item,
    };

    this.props.onAddLaw(data);
  };

  renderArrowRight() {
    const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';
    const points = iconsPath.get('arrowRight');

    return (
      <s.ArrowItem>
        <Icon
          points={points}
          transform={transform}
          color="#4183d7"
        />
      </s.ArrowItem>
    );
  }

  renderArrowDown() {
    const points = iconsPath.get('arrowDown');

    return (
      <s.ArrowItem>
        <Icon
          points={points}
          color="#4183d7"
        />
      </s.ArrowItem>
    );
  }

  renderIcon() {
    if (!this.state.showDetails) {
      return this.renderArrowRight();
    }

    return this.renderArrowDown();
  }

  renderActualContainer() {
    if (this.props.item.isActual) {
      return null;
    }

    return (
      <s.Actual>
        Утратил силу
      </s.Actual>
    );
  }

  renderColumnInGroup() {
    return this.props.columns.map((column) => {
      const isTitle = column.id === 'title';
      if (isTitle) {
        return (
          <s.ColumnTitle key={column.id} />
        );
      }

      return (
        <s.ColumnItem key={column.id} />
      );
    });
  }

  renderItems() {
    return this.props.columns.map((column) => {
      const isTitle = column.id === 'title';
      if (isTitle) {
        return (
          <s.CellTitle
            key={column.id}
          >
            <s.ColumnWidthTitle>
              <s.CellContainer>
                <s.Title>
                  {this.renderIcon()}
                  <s.BodyTextContainer
                    isTitle
                  >
                    <s.BodyTextLink
                      href={column.link}
                      target="_blank"
                    >
                      {column.title}
                      &nbsp;
                      {this.renderActualContainer()}
                    </s.BodyTextLink>
                  </s.BodyTextContainer>
                </s.Title>
              </s.CellContainer>
            </s.ColumnWidthTitle>
          </s.CellTitle>
        );
      }

      const { title, tooltip, isPercent } = column;
      const isEmpty = title === '0' || title === '0%';
      const itemClick = (e) => {
        e.stopPropagation();
        if (isEmpty) {
          return;
        }

        this.handleCellClick(column.id);
      };

      return (
        <CellItemWithTooltip
          key={column.id}
          title={title}
          isPercent={isPercent}
          tooltip={tooltip}
          isEmpty={isEmpty}
          columns={this.props.columns.length}
          onClick={itemClick}
        />
      );
    });
  }

  renderItemDetails() {
    if (!this.state.showDetails) {
      return null;
    }

    return <ItemDetailConnect item={this.props.item} />;
  }

  render() {
    return (
      <>
        <s.Table>
          <s.Group>
            {this.renderColumnInGroup()}
          </s.Group>
          <s.Row
            onClick={this.handleItemClick}
          >
            {this.renderItems()}
          </s.Row>
        </s.Table>
        {this.renderItemDetails()}
      </>
    );
  }

}

export default Item;
