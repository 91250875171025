import { connect } from 'react-redux';

import { resetForm } from './form/redux/resetForm';

import { SearchLaw } from './searchLaw';

const mapDispatchToProps = (dispatch) => ({
  onResetForm() {
    dispatch(resetForm.createAction());
  },
});

export const SearchLawConnect = connect(
  null,
  mapDispatchToProps,
)(SearchLaw);

export default SearchLawConnect;
