import React from 'react';

import { Userpilot } from '@utils/userpilot';

import { OnlineHelpConnect } from '@layouts/onlineHelp/onlineHelp.connect';
import { BookmarksConnect } from '@layouts/bookmarks/components/bookmarks.connect';
import { NotificationsConnect } from '@layouts/notifications/components/notifications.connect';

import { MenuButton } from '@components/header';
import { Header } from '@pages/search/components/header';

import s from '@pages/search/search.style';

import { TabBarConnect } from './tabBar.connect';

import { BlockConnect } from './form/block.connect';

interface Props {
  onResetForm(): void;
}

export class SearchLaw extends React.PureComponent<Props> {

  componentDidMount() {
    Userpilot.reload();
  }

  render() {
    return (
      <s.Search>
        <Header
          title="Поиск"
          menu={<MenuButton checkWidth={768} />}
        >
          <OnlineHelpConnect />
          <BookmarksConnect />
          <NotificationsConnect />
        </Header>
        <TabBarConnect />
        <s.Workspace>
          <s.RootContainer>
            <s.FilterContainer>
              <BlockConnect onUnmount={this.props.onResetForm} />
            </s.FilterContainer>
          </s.RootContainer>
        </s.Workspace>
      </s.Search>
    );
  }

}

export default SearchLaw;
