/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { v4 as uuid } from 'uuid';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import {
  Notification,
  NotificationType,
  NotificationProps,
} from './notification';

import { notifierManager } from './manager';

import s from './notifier.style';

export class Notifier extends React.PureComponent {

  state = {
    notifications: [],
  };

  subscribers: any[];

  constructor(props: {} | Readonly<{}>) {
    super(props);

    this.subscribers = [];
  }

  componentDidMount() {
    this.subscribers = [
      notifierManager.subscribePending(this.addPending),
      notifierManager.subscribeDone(this.addDone),
      notifierManager.subscribeFail(this.addFail),
      notifierManager.subscribeRemove(this.removeNotification),
      notifierManager.subscribeInformation(this.addInformation),
    ];
  }

  componentWillUnmount() {
    this.subscribers.forEach((p) => p());
  }

  private setStateNotifications(notifications: Notification[] = []) {
    this.setState({ notifications });
  }

  private setId = (id: string | number): string => `${id}`;

  private addPending = (notification: NotificationProps) => {
    const item = {
      id: uuid(),
      parentId: this.setId(notification.id),
      message: notification.message,
      type: NotificationType.pending,
    };

    const notifications = [...this.state.notifications, item];
    this.setStateNotifications(notifications);
  };

  private addDone = (notification: NotificationProps & { url: string }) => {
    const item = {
      id: uuid(),
      parentId: this.setId(notification.id),
      message: notification.message,
      link: notification.url,
      linkTitle: notification.linkTitle,
      type: NotificationType.done,
    };

    const notifications = [...this.state.notifications, item];
    this.setStateNotifications(notifications);
  };

  private addFail = (notification: NotificationProps) => {
    const item = {
      id: uuid(),
      parentId: this.setId(notification.id),
      message: notification.message,
      type: NotificationType.fail,
    };

    const notifications = [...this.state.notifications, item];
    this.setStateNotifications(notifications);
  };

  private addInformation = (notification: NotificationProps) => {
    const item = {
      id: uuid(),
      parentId: this.setId(notification.id),
      message: notification.message,
      type: NotificationType.information,
    };

    const notifications = [...this.state.notifications, item];
    this.setStateNotifications(notifications);
  };

  private removeNotification = (notification: NotificationProps) => {
    const id = this.setId(notification.id);
    const notifications = this.state.notifications.filter((n) => n.parentId !== id);
    this.setStateNotifications(notifications);
  };

  // @ts-ignore
  // eslint-disable-next-line class-methods-use-this
  renderItem = (item) => {
    const { id } = item;

    return (
      <CSSTransition
        key={id}
        timeout={500}
        classNames="notification"
      >
        <Notification {...item} />
      </CSSTransition>
    );
  };

  render() {
    return (
      <s.Root>
        <TransitionGroup component={null}>
          {this.state.notifications.map(this.renderItem)}
        </TransitionGroup>
      </s.Root>
    );
  }

}

export default Notifier;
