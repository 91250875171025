import { v4 as uuid } from 'uuid';
import isBoolean from 'lodash/isBoolean';

import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

type Value = string;

export interface BlockData {
  value: Value;
}

export class Block extends AbstractFilterBlock {

  static type = 'lawNumber';

  static DTOType = 'law_number';

  static blockTitle = 'Номер';

  static category = category.main;

  protected defaultData: BlockData = {
    value: '',
  };

  constructor() {
    super();

    this.setCategory(Block.category);
    this.setType({
      id: Block.type,
      DTOType: Block.DTOType,
      title: Block.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
    this.setContains(block.contains);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
      contains: true,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const hasContains = isBoolean(contains);

    if (!hasContains) {
      throw Error('contains is required');
    }

    const block = {
      id,
      data,
      contains,
    };

    this.create(block);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: this.getContains(),
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    let data = this.getData();
    if (!data) {
      // @ts-ignore
      data = null;
    }

    return {
      id: this.getId(),
      contains: this.getContains(),
      type: Block.DTOType,
      data,
    };
  }

  // eslint-disable-next-line
  public validate() {}

  getItemsForSuggest(): Promise<any> {
    throw new Error('Method not implemented.');
  }

}

export default Block;
