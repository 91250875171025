import {
  StateBuilder,
  DocumentDTO,
} from './state';

interface Action {
  type: string;
  data: DocumentDTO;
}

export const fetchDocumentReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state.document);
  newState.setLoading(false);
  newState.setDocumentId(action.data.documentId);
  newState.setDocument(action.data.document);
  newState.setAttributes(action.data.attributes);

  return {
    ...state,
    document: {
      ...newState.getState(),
    },
  };
};

export default fetchDocumentReduce;
