import React from 'react';

import { DuplicateButton } from '../components/filterControls/duplicateButton';
import { RemoveButton } from '../components/filterControls/removeButton';
import { PinButton } from '../components/filterControls/pinButton';

import { filterBlocksMap } from './filterBlocks.map';

import s from './block.style';

export interface BlockProps {
  item: {
    getId: () => string;
    getType: () => Record<string, unknown>;
  };
  onDuplicateBlock: (data: { id: string }) => void;
  onRemoveBlock: (data: { id: string }) => void;
  onPinBlock?: (data: { id: string }) => void;
  isPinned?: boolean;
}

export const Block = (props: BlockProps) => {

  const Component = filterBlocksMap.get(props.item.getType().id).component;

  const handleDuplicateBlock = () => {
    const data = {
      id: props.item.getId(),
    };

    props.onDuplicateBlock(data);
  };

  const handleRemoveBlock = () => {
    const data = {
      id: props.item.getId(),
    };

    props.onRemoveBlock(data);
  };

  const handlePinBlock = () => {
    const data = {
      id: props.item.getId(),
    };

    props.onPinBlock(data);
  };

  if (!props.onPinBlock) {
    return (
      <s.Root>
        <DuplicateButton onClick={handleDuplicateBlock} />
        <Component {...props} />
        <RemoveButton onClick={handleRemoveBlock} />
      </s.Root>
    );
  }

  return (
    <s.Root>
      <DuplicateButton onClick={handleDuplicateBlock} />
      <Component {...props} />
      <PinButton onClick={handlePinBlock} isPinned={props.isPinned} />
      <RemoveButton onClick={handleRemoveBlock} />
    </s.Root>
  );
};

export default Block;
