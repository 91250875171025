import React from 'react';

import { Switch } from 'react-router-dom';

import { RouteLanding } from '@pages/landing/landing.route';
import { RouteNotFound } from '@pages/404/404.route';
import { RouteLogin } from '@pages/userPages/login/login.route';
import { RoutePasswordRecovery } from '@pages/userPages/passwordRecovery/passwordRecovery.route';
import { RoutePasswordRecoveryEmailSent } from '@pages/userPages/passwordRecovery/passwordRecoveryEmailSent.route';
import { RouteCreateNewPassword } from '@pages/userPages/createNewPassword/createNewPassword.route';
import { RouteOutDatePasswordResetLink } from '@pages/userPages/createNewPassword/outDateLink.route';
import { RouteCreateNewPasswordSuccess } from '@pages/userPages/createNewPassword/createNewPasswordSuccess.route';
import { RouteProfile } from '@pages/userPages/profile/profile.route';
import { RoutePaymentSelect } from '@pages/userPages/paymentSelect/paymentSelect.route';

import { RouteSearch } from '@pages/search/search.route';
import { RouteSearchDocuments } from '@pages/searchResult/documents/documents.route';
import { RouteSearchDocument } from '@pages/searchResult/document/document.route';
import { RouteSearchLaws } from '@pages/searchResult/law/law.route';
import { RouteSearchCourts } from '@pages/searchResult/courts/courts.route';
import { RouteSearchAnalysis } from '@pages/searchResult/analysis/analysis.route';
import { RouteSearchSimilarDocument } from '@pages/searchResult/document/similarDocument.route';
import { RouteSearchChronologyDocument } from '@pages/searchResult/document/chronologyDocument.route';

import { RouteSearchLaw } from '@pages/searchLaw/searchLaw.route';
import { RouteSearchLawDocuments } from '@pages/searchLawResult/documents/documents.route';
import { RouteSearchLawDocument } from '@pages/searchLawResult/document/document.route';
import { RoutePackages } from '@pages/packages/packages.route';
import { RoutePackageDocument } from '@pages/packages/document/document.route';
import { RoutePackageChronologyDocument } from '@pages/packages/document/chronologyDocument.route';
import { RoutePackageSimilarDocument } from '@pages/packages/document/similarDocument.route';

import { RouteAuthorPackages } from '@pages/authorPackages2/authorPackages.route';
import { RouteAuthorPackageDocument } from '@pages/authorPackages2/document/document.route';
import { RouteAuthorPackageChronologyDocument } from '@pages/authorPackages2/document/chronologyDocument.route';
import { RouteAuthorPackageSimilarDocument } from '@pages/authorPackages2/document/similarDocument.route';

import { RouteSubscriptions } from '@pages/subscriptions/subscriptions.route';
import { RouteSubscriptionDocument } from '@pages/subscriptions/document/document.route';
import { RouteSubscriptionChronologyDocument } from '@pages/subscriptions/document/chronologyDocument.route';
import { RouteSubscriptionSimilarDocument } from '@pages/subscriptions/document/similarDocument.route';

import { RouteRegulation } from '@pages/regulation/regulation.route';

import { RouteLawDocument } from '@pages/lawDocument/lawDocument.route';

import { RouteBookmarks } from '@pages/bookmarks/bookmarks.route';

export const Routes = (
  <Switch>
    {RouteLanding}
    {RouteLogin}
    {RoutePasswordRecovery}
    {RoutePasswordRecoveryEmailSent}
    {RouteCreateNewPassword}
    {RouteOutDatePasswordResetLink}
    {RouteCreateNewPasswordSuccess}
    {RouteProfile}
    {RouteSearch}
    {RouteSearchLaw}
    {RoutePaymentSelect}
    {RouteSearchDocuments}
    {RouteSearchLawDocuments}
    {RouteSearchDocument}
    {RouteSearchLawDocument}
    {RouteSearchChronologyDocument}
    {RouteSearchSimilarDocument}
    {RouteSearchCourts}
    {RouteSearchLaws}
    {RouteSearchLaw}
    {RouteSearchAnalysis}
    {RoutePackages}
    {RoutePackageDocument}
    {RoutePackageChronologyDocument}
    {RoutePackageSimilarDocument}
    {RouteAuthorPackages}
    {RouteAuthorPackageDocument}
    {RouteAuthorPackageChronologyDocument}
    {RouteAuthorPackageSimilarDocument}
    {RouteSubscriptions}
    {RouteSubscriptionDocument}
    {RouteSubscriptionChronologyDocument}
    {RouteSubscriptionSimilarDocument}
    {RouteRegulation}
    {RouteLawDocument}
    {RouteBookmarks}
    {RouteNotFound}
  </Switch>
);

export default Routes;
