import { connect } from 'react-redux';

import { Header } from './header';

const mapStateToProps = (state, ownProps) => {
  const {
    document,
    attributes,
  } = state.lawDocument;

  return {
    searchId: ownProps.searchId,
    title: attributes.title,
    isActual: attributes.isActual,
    loading: document.loading,
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
