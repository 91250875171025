import { connect } from 'react-redux';
import { LicenseType } from '@router/pathPermissions';
import { RoutePath } from '@router/path';

import { menuItems as items } from '../menu.items';
import { Navigation } from './navigation';

const mapStateToProps = (state) => {
  const accessTo = state.currentUser.accessTo;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  const multiLicense = caselookLicense && monitoringLicense;

  if (multiLicense) {
    return {
      menuItems: items,
    };
  }

  if (caselookLicense) {
    return {
      menuItems: items.filter((item) => item.id !== 5),
    };
  }

  const validItems = [];

  validItems.push(items[0]);
  validItems.push(items[4]);

  validItems[0].link = RoutePath.searchLaw;

  return {
    menuItems: validItems,
  };
};

export const NavigationConnect = connect(
  mapStateToProps,
)(Navigation);

export default NavigationConnect;
