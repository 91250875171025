import { RESTPath } from '@router/path.rest';
import http from '@http';

export function httpFetchFilters(searchId: number) {
  const request = http.get({
    url: RESTPath.searchLaw.getFilters(searchId),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchFilters;
