import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  background: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.borderColor};
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;

  > div {
    margin-top: 10px;
  }
`;

export default style;
