import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;

  &#resolution-preview-container {
    > div {
      padding: 16px 0;
    }
  }
`;

style.DocumentContainer = styled.div`
  padding: 16px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

style.Item = styled.div`
  padding: 0 0 16px 0;
  .g-highlight {
    background: rgba(255, 190, 37, 0.5);
  }
`;

style.Wrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 32px;
  right: 0;
  gap: 8px;
  z-index: 4;
  width: 768px;
  justify-content: center;
  
`;

style.Loader = styled.div`
  z-index: 2;

  > div {
    margin-top: 10px;
  }
`;

export default style;
