import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { OpenDrawer } from '@components/openDrawer';

import { BlockConnect } from '@pages/searchLaw/form/block.connect';

import s from '@pages/searchResult/components/header/searchDrawer.style';

interface SearchDrawerProps {
  onClose?(): void;
  onMount?(): void;
}

export class SearchDrawer extends React.PureComponent<SearchDrawerProps> {

  state = {
    open: false,
  };

  componentDidMount() {
    this.props.onMount();
  }

  handleCloseDrawer = () => {
    this.setState({
      open: false,
    });
  };

  handleTriggerDrawer = () => {
    this.setState((prevState: { open: boolean }) => ({
      open: !prevState.open,
    }));

    if (!this.state.open) {
      sendAmplitudeData('caselook:law_documents:edit_request');
      Userpilot.track('caselook:law_documents:edit_request');
    }
  };

  renderDrawer = () => {
    if (!this.state.open) {
      return null;
    }

    return (
      <OpenDrawer
        anchor="top"
        withBackground={false}
        component={(
          <s.Container>
            <s.DrawerRoot>
              <BlockConnect
                onUnmount={this.props.onClose}
              />
            </s.DrawerRoot>
            <s.Background
              onClick={this.handleCloseDrawer}
            />
          </s.Container>
        )}
        onClickOutside={this.handleCloseDrawer}
      />
    );
  };

  render() {
    return (
      <s.Root>
        <s.Action
          onClick={this.handleTriggerDrawer}
        >
          <s.ActionTitle>
            Изменить запрос
          </s.ActionTitle>
        </s.Action>
        {this.renderDrawer()}
      </s.Root>
    );
  }

}

export default SearchDrawer;
