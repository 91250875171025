import isString from 'lodash/isString';

export enum UserRoleType {
  virtual = 'virtual',
  guest = 'guest',
  admin = 'admin',
  sales = 'sales',
  user = 'user',
  teamLead = 'teamlead',
  support = 'support',
  trial = 'trial',
}

export enum LicenseType {
  multi = 'multi',
  caselook = 'caselook',
  monitoring = 'monitoring',
}

interface Registry {
  test: string | RegExp;
  roles: string[];
  license: string[];
}

const registry: Registry[] = [];

registry.push({
  test: 'profile',
  roles: [
    UserRoleType.admin,
    UserRoleType.sales,
    UserRoleType.user,
    UserRoleType.teamLead,
    UserRoleType.support,
    UserRoleType.trial,
  ],
  license: [
    LicenseType.caselook,
    LicenseType.monitoring,
    LicenseType.multi,
  ],
});

registry.push({
  test: 'search',
  roles: [
    UserRoleType.admin,
    UserRoleType.sales,
    UserRoleType.user,
    UserRoleType.teamLead,
    UserRoleType.support,
    UserRoleType.trial,
  ],
  license: [
    LicenseType.caselook,
    LicenseType.multi,
  ],
});

registry.push({
  test: 's_law',
  roles: [
    UserRoleType.admin,
    UserRoleType.sales,
    UserRoleType.user,
    UserRoleType.teamLead,
    UserRoleType.support,
    UserRoleType.trial,
  ],
  license: [
    LicenseType.monitoring,
    LicenseType.caselook,
    LicenseType.multi,
  ],
});

registry.push({
  test: /packages?/,
  roles: [
    UserRoleType.admin,
    UserRoleType.sales,
    UserRoleType.user,
    UserRoleType.teamLead,
    UserRoleType.support,
    UserRoleType.trial,
  ],
  license: [
    LicenseType.caselook,
    LicenseType.multi,
  ],
});

registry.push({
  test: /subscriptions?/,
  roles: [
    UserRoleType.admin,
    UserRoleType.sales,
    UserRoleType.user,
    UserRoleType.teamLead,
    UserRoleType.support,
    UserRoleType.trial,
  ],
  license: [
    LicenseType.caselook,
    LicenseType.multi,
  ],
});

registry.push({
  test: /regulation/,
  roles: [
    UserRoleType.admin,
    UserRoleType.sales,
    UserRoleType.user,
    UserRoleType.teamLead,
    UserRoleType.support,
    UserRoleType.trial,
  ],
  license: [
    LicenseType.monitoring,
    LicenseType.multi,
  ],
});

registry.push({
  test: /laws?/,
  roles: [
    UserRoleType.admin,
    UserRoleType.sales,
    UserRoleType.user,
    UserRoleType.teamLead,
    UserRoleType.support,
    UserRoleType.trial,
  ],
  license: [
    LicenseType.caselook,
    LicenseType.multi,
  ],
});

registry.push({
  test: /bookmarks/,
  roles: [
    UserRoleType.admin,
    UserRoleType.sales,
    UserRoleType.user,
    UserRoleType.teamLead,
    UserRoleType.support,
    UserRoleType.trial,
  ],
  license: [
    LicenseType.caselook,
    LicenseType.monitoring,
    LicenseType.multi,
  ],
});

registry.push({
  test: 'paymentselect',
  roles: [
    UserRoleType.admin,
    UserRoleType.user,
    UserRoleType.trial,
  ],
  license: [
    LicenseType.caselook,
    LicenseType.multi,
  ],
});

export const resolveRouteRoles = (location: string | { pathname: string }) => {
  let pathname = isString(location) ? location : location.pathname;
  pathname = pathname.substr(1);

  const permission = registry.find((p) => pathname.match(p.test));

  return permission ? permission.roles : [];
};

export const resolveRoutePermissions = (location: string | { pathname: string }) => {
  let pathname = isString(location) ? location : location.pathname;
  pathname = pathname.substr(1);

  const permission = registry.find((p) => pathname.match(p.test));

  return permission ? permission.license : [];
};

export default resolveRouteRoles;
