import { connect } from 'react-redux';

import {
  Pagination,
  preparePages,
} from '@components/pagination';

import { fetchDocumentsThunk } from '../redux/fetchDocuments.thunk';

const mapStateToProps = (state) => {
  const {
    totalPages,
    currentPageNumber,
  } = state.searchLawResult.documents;

  const isFirstPageSelected = currentPageNumber === 1;
  const isLastPageSelected = currentPageNumber === totalPages;

  return {
    pages: preparePages(totalPages, currentPageNumber),
    currentPageNumber,
    isFirstPageSelected,
    isLastPageSelected,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangePage(pageNumber) {
    fetchDocumentsThunk(dispatch, null, pageNumber);
    const { onAfterPagination } = ownProps;
    onAfterPagination();
  },
  onAfterPagination() {
    const { onAfterPagination } = ownProps;
    onAfterPagination();
  },
});

export const PaginationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Pagination);

export default PaginationConnect;
