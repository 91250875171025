import React from 'react';

import { Loader } from '@components/loader';

import { OpenButton } from '@components/buttons';

import {
  AttributeField,
  FieldProps,
} from '@components/attributeField/attributeField';

import s from '@pages/document/sidebar/attributes.style';

interface DFR {
  title: string;
  url: string;
}

interface AttributesProps {
  attributes: {
    date: string;
    departmentType: string;
    edition: string;
    publicationDate: string;
    editionId: string;
    isActual: boolean;
    number: string;
    publications: FieldProps[];
    dfr: DFR;
    title: string;
    type: string;
    url: string;
  };
  loading: boolean;
}

export class Attributes extends React.PureComponent<AttributesProps> {

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderExternalDFRLink = () => {
    if (!this.props.attributes.dfr.url) {
      return null;
    }

    return (
      <OpenButton
        href={this.props.attributes.dfr.url}
      />

    );
  };

  renderExternalLink = () => {
    if (!this.props.attributes.url) {
      return null;
    }

    return (
      <OpenButton
        href={this.props.attributes.url}
      />

    );
  };

  render() {
    const { loading, attributes } = this.props;

    if (loading) {
      return this.renderLoader();
    }

    const actual = attributes.isActual ? 'Действующий' : 'Утратил силу';

    return (
      <s.Root>
        <AttributeField
          placeholder="Тип документа"
          value={attributes.type}
        />
        <AttributeField
          placeholder="Название"
          value={attributes.title}
        />
        <AttributeField
          placeholder="Принявший орган"
          value={attributes.departmentType}
        />
        <AttributeField
          placeholder="Дата принятия"
          value={attributes.date}
        />
        <AttributeField
          placeholder="Номер"
          value={attributes.number}
        />
        <AttributeField
          placeholder="Статус действия"
          value={actual}
        />
        <AttributeField
          placeholder="Дата публикации исходной редакции"
          value={attributes.publicationDate}
        />
        <s.Link>
          <AttributeField
            placeholder="Источник"
            value="Официальный интернет-портал правовой информации"
          />
          {this.renderExternalLink()}
        </s.Link>
        <AttributeField
          placeholder="Публикации"
          multiple
          values={attributes.publications}
        />
        <AttributeField
          placeholder="Дата формирования редакции"
          value={attributes.edition}
        />
        <s.Link>
          <AttributeField
            placeholder="Документ формирования редакции"
            value={attributes.dfr.title}
          />
          {this.renderExternalDFRLink()}
        </s.Link>
      </s.Root>
    );
  }

}

export default Attributes;
