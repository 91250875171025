import { connect } from 'react-redux';

import { Slider } from '@components/slider/slider';

import { toggleReasonThunk } from '@pages/document/redux/toggleReason.thunk';

const mapStateToProps = (state) => {
  const isShow = state.document.reasons.showReasons;

  return {
    isShow,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onShow() {
    toggleReasonThunk(dispatch);
  },
});

export const SliderConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Slider);

export default SliderConnect;
