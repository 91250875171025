import React from 'react';

import s from './block.style';

export interface BlockProps {
  id: string;
  title: string;
  body: string;
}

export class Block extends React.PureComponent<BlockProps> {

  renderBlock(body) {
    return {
      __html: body,
    };
  }

  render() {
    return (
      <s.Root
        data-block-id={this.props.id}
      >
        <s.Title
          dangerouslySetInnerHTML={this.renderBlock(this.props.title)}
        />
        <s.Content
          dangerouslySetInnerHTML={this.renderBlock(this.props.body)}
        />
      </s.Root>
    );
  }

}

export default Block;
