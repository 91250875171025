export const fetchReasonStartReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    reasons: {
      ...state.reasons,
      loading: true,
      loadingSuccess: true,
    },
  };
};

export default fetchReasonStartReduce;
