import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';

import { fetchDocumentPreviewThunk } from './redux/fetchDocument.thunk';

import { DocumentPreview } from './documentPreview';

const mapStateToProps = (state, ownProps) => {
  const { document } = state.searchLawResult;

  const source = Source.searchLaw;
  const sourceId = state.searchLawResult.id;
  const documentId = ownProps.documentId;
  const baseHref = `#/${source}/${sourceId}/documents/${documentId}`;

  return {
    sourceId,
    documentId,
    title: ownProps.title,
    data: document,
    baseHref,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetch() {
    const params = {
      documentId: ownProps.documentId,
      source: Source.searchLaw,
    };

    fetchDocumentPreviewThunk(dispatch, params);
  },
});

export const DocumentPreviewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPreview);

export default DocumentPreviewConnect;
