import { connect } from 'react-redux';
import { LicenseType } from '@router/pathPermissions';

import { Notifications } from './notifications';

const mapStateToProps = (state) => {
  const { accessTo } = state.currentUser;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    shouldRender: multiLicense || monitoringLicense,
  };
};

export const NotificationsConnect = connect(
  mapStateToProps,
)(Notifications);

export default NotificationsConnect;
