import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './control.style';

type Props = {
  onClick: () => void,
};

const trashPath = iconsPath.get('trash');

export const RemoveButton = ({ onClick }: Props) => (
  <s.Root
    position="relative"
    title="Удалить фильтр"
    alignRight
    className="filter-remove-button"
    onClick={onClick}
  >
    <Icon path={trashPath} color="#6c7b89" />
  </s.Root>
);

export default RemoveButton;
