import { StateBuilder as DocumentsStateBuilder } from '../documents/redux/state';
import type { State as DocumentsState } from '../documents/redux/state';

import { StateBuilder as DocumentStateBuilder } from '../document/redux/state';
import type { State as DocumentState } from '../document/redux/state';

export interface Document {
  id: string;
  body: string;
}

export interface Resolution {
  id: string;
  body: string;
}

export type DocumentId = number;

export type Id = string;

export type Items = Id[];

export type SearchId = number;
export enum ProcessingStatus {
  none = 'NONE',
  processing = 'PROCESSING',
  done = 'DONE',
}

export interface SearchResultState {
  id: SearchId;
  processingStatus: ProcessingStatus;
  documents: DocumentsState;
  document: DocumentState;
}

export const initial = (): SearchResultState => {

  const state = {} as SearchResultState;

  // @ts-ignore
  state.id = null;

  state.processingStatus = ProcessingStatus.none;
  state.documents = DocumentsStateBuilder.createState();
  state.document = DocumentStateBuilder.createInitialState();

  return state;
};

export default initial();
