import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

@media screen and (min-width: 1280px) {
.document-view {
    width: calc(1152px - 320px);
  }
}

@keyframes highligh {
  0% {
    background-color: transparent;
  }
  70% {
    background-color: #ffeb8a;
  }
  100% {
    background-color: transparent;
  }
}

.law-highlighted {
  animation: highligh 0.8s;
}

.g-highlight {
  background: rgba(255, 190, 37, 0.5);
}

.serviceText {
  display: block;
  text-align: center;
  margin: 10px 0;
  font-style: italic;
}
`;

export default GlobalStyle;
