import { connect } from 'react-redux';

import { separator } from '@components/separator';

import { ItemDetail } from './itemDetail';

import { Format } from '../redux/settings';
import { FetchSearchArticlesThunk } from '../redux/fetchArticles.thunk';

import { addLawBlockThunk } from '../redux/addLawBlock.thunk';

export interface DataFromView {
  columnId: string;
  children: {
    id: string;
    type: string;
    title: string;
    hasClauses: boolean;
  },
}

const transformItem = (data, settings, options) => {
  const columns = [];

  const title = {
    id: 'title',
    type: data.type,
    itemId: data.id,
    title: data.title || '',
    hasClauses: data.hasClauses,
    link: data.link,
  };

  // @ts-ignore
  columns.push(title);

  settings.itemsList.forEach((column) => {
    const isShow = settings.itemsMap.get(column).showColumn;
    if (isShow) {
      const isPercent = options.format === Format.percent;
      const value = separator(data[options.type][column][options.format]);
      let cellData = value;
      const tooltipData = separator(data[options.type][column][Format.number]);

      if (isPercent) {
        cellData = `${value}%`;
      }

      columns.push({
        // @ts-ignore
        id: column,
        // @ts-ignore
        title: cellData,
        // @ts-ignore
        tooltip: tooltipData,
        // @ts-ignore
        isPercent,
      });
    }
  });

  return columns;
};

const prepareItems = (items, settings, options) => {
  const data = [];

  if (!items.items.length) {
    return data;
  }

  items.items.forEach((itemId) => {
    const item = items.itemsMap.get(itemId);
    // @ts-ignore
    data.push(transformItem(item, settings, options));
  });

  return data;
};

const mapStateToProps = (state, ownProps) => {
  const { type, format } = state.searchResult.law.layout;
  const { articles } = state.searchResult.law.documents.itemsMap.get(ownProps.item.id);
  const settings = state.searchResult.law.layout.columns[state.searchResult.law.layout.type];
  const items = prepareItems(articles, settings, { type, format });

  return {
    shouldLoadArticles: articles.shouldLoadArticles,
    items,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetch() {
    const thunk = new FetchSearchArticlesThunk(dispatch);
    dispatch((_, getState) => { thunk.getState = getState; });

    const documentId = ownProps.item.id;
    thunk.invoke(documentId);

    return thunk;
  },
  onAddDocument(itemData) {
    const data = {
      columnId: itemData.columnId,
      document: {
        id: ownProps.item.id,
        title: ownProps.item.title,
        hasArticles: ownProps.item.hasArticles,
        hasClauses: ownProps.item.hasClauses,
      },
      children: {
        type: itemData.children.type,
        id: itemData.children.itemId,
        title: itemData.children.title,
        hasClauses: itemData.children.hasClauses,
      },
    };

    addLawBlockThunk(dispatch, data);
  },
});

export const ItemDetailConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemDetail);

export default ItemDetailConnect;
