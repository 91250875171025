import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  padding-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;

  > div {
    background: white;
  }
`;

style.Wrapper = styled.div`
  padding-top: 32px;
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;
  z-index: 2;

  > div {
    margin-top: 10px;
  }
`;

export default style;
