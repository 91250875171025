export interface Category {
  id: string;
  title: string;
  color: string;
}

export const category = {
  main: {
    id: 'main',
    title: 'Основные',
    color: '#d2d7dc',
  },
};

export default category;
