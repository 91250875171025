import { ReducerBuilder } from '@redux/reducer.builder';

import { resetDocuments } from './resetDocuments';
import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';
import { changePreviewDocument } from '../preview/redux/changePreviewDocument';

import {
  loadingStart,
  loadingEnd,
} from './loading';

import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

export const reducerBuilder = new ReducerBuilder([
  changePageNumber,
  changePreviewDocument,
  fetchDocuments,
  loadingEnd,
  loadingStart,
  pageLoadingEnd,
  pageLoadingStart,
  resetDocuments,
]);

export default reducerBuilder;
