import React, {
  useState,
  useRef,
} from 'react';

import { iconsPath } from '@components/icons';

import {
  _DOCUMENT_VIEW_CLASS,
  _HIGHLIGHT_CLASS,
} from '@pages/document/workspace/workspace';

import { TooltipReasonWithClickConnect } from '@pages/document/workspace/components/tooltipReasons.connect';

import { DocumentMarkersConnect } from './documentsMarkers.connect';

import type { Paragraph } from './redux/document';

import { DocumentBodyBlockConnect } from './documentBodyBlock.connect';
import { SimilarListPromoConnect } from './components/similarListPromo/similarListPromo.connect';

import s from './documentBody.style';

interface DocumentTabProps {
  title?: string;
  isReal?: boolean;
  noBookmarks?: boolean;
  bookmarkTitle?: string;
  documentId?: number;
  source?: string;
  sourceId?: number;
  additionalSourceId?: number;
  department?: string;
  renderTitle?: boolean;
  body: Paragraph[];
  activeReason?: boolean;
  reasonIds?: string[];
  onSaveMarkers?(entities): void;
}

function unwrap(dom) {
  const nodes = Array.prototype.slice.call(dom.childNodes);
  let wrapper;

  nodes.forEach((node) => {
    wrapper = node.parentNode;
    dom.parentNode.insertBefore(node, dom);
    wrapper.parentNode.removeChild(wrapper);
    wrapper = null;
  });
}

export const DocumentBody = (props: DocumentTabProps) => {
  const contentRef = useRef(null);
  const [activeTooltipId, setActiveTooltipId] = useState(null);

  const prepareMarkup = (body, id) => ({
    __html: `<div data-entity-id=${id}>${body}</div>`,
  });

  const renderCrest = () => {
    const iconPath = iconsPath.get('crest');

    return (
      <s.Crest>
        <svg
          viewBox="0 0 72 72"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={iconPath} />
        </svg>
      </s.Crest>
    );
  };

  const renderBody = () => (
    <div className={`${_DOCUMENT_VIEW_CLASS}__articles`}>
      <div>
        {
          (props.body || []).map((block) => (<DocumentBodyBlockConnect key={block.id} block={block} {...props} />))
        }
      </div>
      <div className={`${_DOCUMENT_VIEW_CLASS}__clone-articles`} />
    </div>
  );

  const renderReason = (item) => {
    if (props.reasonIds.includes(item.id)) {
      return (
        <s.Reason
          activeReason={props.activeReason}
        />
      );
    }

    return null;
  };

  const handleOnClick = (event) => {
    const paragraphId = event.target.getAttribute('data-entity-id');
    const paragraphIsReason = props.reasonIds.includes(paragraphId);

    const shouldShowTooltip = props.activeReason && paragraphIsReason;
    if (!shouldShowTooltip) {
      return;
    }

    setActiveTooltipId(paragraphId);
  };

  const hideTooltip = () => {
    setActiveTooltipId(null);
  };

  const renderTooltipReason = (item) => {
    const shouldRender = activeTooltipId === item.id;
    if (!shouldRender) {
      return null;
    }

    const tooltipXPosition = contentRef.current.offsetWidth;

    return (
      <TooltipReasonWithClickConnect
        tooltipIdReason={item.id}
        tooltipXPosition={tooltipXPosition}
        onClickOutside={hideTooltip}
      />
    );
  };

  const renderSimpleBody = () => (
    props.body.map((item) => (
      <s.Wrapper
        className="article"
        data-entity-id={item.id}
      >
        {renderReason(item)}
        {renderTooltipReason(item)}
        <s.ResolutionItem
          key={item.id}
          ref={contentRef}
          onClick={handleOnClick}
          dangerouslySetInnerHTML={prepareMarkup(item.body, item.id)}
        />
      </s.Wrapper>
    ))
  );

  const renderSimilarPromo = () => {
    if (!props.isReal) {
      return null;
    }

    return (
      <SimilarListPromoConnect
        source={props.source}
        sourceId={props.sourceId}
        documentId={props.documentId}
      />
    );
  };

  const reRenderSearchHighlight = () => {
    setTimeout(() => {
      const documentSections = document.querySelectorAll(`section.${_DOCUMENT_VIEW_CLASS}`);
      let searchHighlights;
      let documentWrapper;
      let articles;
      let cloneText = '';
      let clArticleTmp;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < documentSections.length; ++i) {
        documentWrapper = documentSections[i].querySelector(`.${_DOCUMENT_VIEW_CLASS}__articles`);
        searchHighlights = documentWrapper ?
          // eslint-disable-next-line max-len
          documentWrapper.querySelectorAll(`:not(.${_DOCUMENT_VIEW_CLASS}__clone-articles)>div>.article span.${_HIGHLIGHT_CLASS}`) :
          [];

        if (documentWrapper && documentWrapper.firstElementChild) {
          cloneText = documentWrapper.firstElementChild.cloneNode(true);
        }

        clArticleTmp = documentSections[i].getElementsByClassName(`${_DOCUMENT_VIEW_CLASS}__clone-articles`)[0];
        if (clArticleTmp && clArticleTmp.children.length === 0) {
          clArticleTmp.appendChild(cloneText);
        }

        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < searchHighlights.length; ++j) {
          unwrap(searchHighlights[j]);
        }

        if (documentWrapper && documentWrapper.firstElementChild) {
          articles = documentWrapper.firstElementChild.childNodes;
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < articles.length; ++k) {
            articles[k].normalize();
          }
        }
      }
    }, 0);
  };

  const renderItems = () => {
    reRenderSearchHighlight();

    return renderBody();
  };

  const renderDepartment = () => {
    if (!props.department) {
      return null;
    }

    return <s.Department>{props.department}</s.Department>;
  };

  const renderTitle = () => {
    if (!props.title) {
      return null;
    }

    return <s.Title>{props.title}</s.Title>;
  };

  const renderHeader = () => {
    if (!props.renderTitle) {
      return null;
    }

    return (
      <s.Meta id={`${_DOCUMENT_VIEW_CLASS}__meta`} className={`${_DOCUMENT_VIEW_CLASS}__meta`}>
        {renderCrest()}
        {renderDepartment()}
        {renderTitle()}
      </s.Meta>
    );
  };

  if (props.noBookmarks) {
    return (
      <s.Root>
        {renderHeader()}
        {renderSimpleBody()}
      </s.Root>
    );
  }

  return (
    <s.Root id="workspace">
      <DocumentMarkersConnect documentId={props.documentId} onSaveMarkers={props.onSaveMarkers} source={props.source} />
      <div>
        <section className="document-view" key="body">
          {renderHeader()}
          {renderItems()}
        </section>
      </div>
      {renderSimilarPromo()}
      <s.LastDivider />
    </s.Root>
  );
};

DocumentBody.defaultProps = {
  title: null,
  department: null,
  renderTitle: false,
};

export default DocumentBody;
