import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

style.IconBlock = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

style.Icon = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

style.IconTitle = styled.div`
  padding: 21px 0 28px 0;
  color: ${(props) => props.theme.colors.stone};
`;

style.Button = styled.div`
  background: rgb(65, 131, 215);
  text-transform: uppercase;
  border-radius: 2px;
  height: 48px;
  width: 195px;
  cursor: pointer;
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto-Medium;
  font-size: 13px;
  font-weight: 500;
`;

export default style;
