import { Block } from '../form/filterBlocks/date/redux/block';
import { Filters } from '../form/filters';

const datePresets = Block.presets;

const filters = new Filters();
filters.createInitial();
const query = {
  blocksList: filters.blocksList,
  blocksMap: filters.blocksMap,
};

export const initial = () => ({
  query,
  defaultQuery: {
    blocksList: query.blocksList,
    blocksMap: query.blocksMap,
    loading: true,
  },
  presets: {
    date: datePresets,
  },
});

export default initial();
