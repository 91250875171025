type Id = string;

interface BodyBlock {
  id: Id;
  body: string;
}

interface TitleItem {
  id: Id;
  title: string;
}

interface Document {
  bodyBlocks: BodyBlock[];
  titles: TitleItem[];
  loading: boolean;
}

interface Edition {
  id: Id;
  title: string;
}

interface DFR {
  title: string;
  url: string;
}

export interface Editions {
  items: Edition[];
  loading: boolean;
}

type Publication = string;

export interface Attributes {
  editionId: Id;
  edition: string;
  date: string;
  publicationDate: string;
  isActual: boolean;
  title: string;
  type: string;
  dfr: DFR;
  departmentType: string;
  url: string;
  number: string;
  publications: Publication[];
}

interface State {
  documentId: string;
  document: Document;
  editions: Editions;
  attributes: Attributes;
  layout: {
    sidebar: {
      activeTabId: TabIds;
    }
  }
}

export enum TabId {
  attributes = 'attributes',
  tableOfContents = 'tableOfContents',
  editions = 'editions',
}

export type TabIds = TabId.attributes | TabId.tableOfContents | TabId.editions;

export interface Layout {
  sidebar: {
    activeTabId: TabIds;
  }
}

export class StateBuilder {

  documentId: string = null;

  document: Document = StateBuilder.createInitialDocument();

  editions: Editions = StateBuilder.createInitialEditions();

  attributes: Attributes = StateBuilder.createInitialAttributes();

  layout: Layout = StateBuilder.createInitialLayout();

  public createState(state) {
    this.documentId = state.documentId;
    this.document = { ...state.document };
    this.editions = { ...state.editions };
    this.attributes = { ...state.attributes };
    this.layout = { ...state.layout };
  }

  static createInitialLayout() {
    return {
      sidebar: {
        activeTabId: TabId.tableOfContents,
      },
    };
  }

  static createInitialDocument() {
    return {
      bodyBlocks: [],
      titles: [],
      loading: true,
    };
  }

  static createInitialEditions() {
    return {
      items: [],
      loading: true,
    };
  }

  static createInitialAttributes() {
    return {
      editionId: null,
      edition: null,
      date: null,
      publicationDate: null,
      isActual: true,
      title: null,
      type: null,
      departmentType: null,
      dfr: {
        title: null,
        url: null,
      },
      url: null,
      number: null,
      publications: [],
    };
  }

  static createInitialState(): State {
    return {
      documentId: null,
      document: StateBuilder.createInitialDocument(),
      editions: StateBuilder.createInitialEditions(),
      attributes: StateBuilder.createInitialAttributes(),
      layout: {
        sidebar: {
          activeTabId: TabId.tableOfContents,
        },
      },
    };
  }

  public setDocumentId(id) {
    this.documentId = id;
  }

  public prepareTitles(body) {
    body.forEach((block) => {
      if (block.title) {
        this.document.titles.push({
          id: block.id,
          title: block.title,
        });
      }
    });
  }

  public prepareAttributes(params) {
    const publications = params.publications ? [...params.publications] : [];
    const publicationDate = params.publication_date || this.attributes.publicationDate;
    const dfr = params.dfr || this.attributes.dfr;
    this.attributes = {
      edition: params.edition,
      editionId: params.edition_id,
      date: params.date,
      publicationDate,
      isActual: params.is_actual,
      title: params.title,
      type: params.type,
      departmentType: params.department_type,
      dfr,
      url: params.url,
      number: params.number,
      publications,
    };
  }

  public prepareDocumentBody(body) {
    this.document.bodyBlocks = body.map((block) => ({
      id: block.id,
      title: block.title,
      body: block.body,
    }));
  }

  public prepareEditions(data) {
    this.editions.items = data.map((item) => ({
      id: item.link,
      title: item.title,
    }));
  }

  public setDocumentLoading(loading = true) {
    this.document.loading = loading;
  }

  public setEditionsLoading(loading = true) {
    this.editions.loading = loading;
  }

  public getState() {
    return {
      documentId: this.documentId,
      attributes: this.attributes,
      document: this.document,
      editions: this.editions,
      layout: this.layout,
    };
  }

}

export default StateBuilder;
