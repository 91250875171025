import { connect } from 'react-redux';
import { LicenseType } from '@router/pathPermissions';

import { TabBar } from '@components/tabBar/tabBar';

const tabs = [
  {
    id: 'search',
    title: 'Судебные акты',
  },
  {
    id: 's_law',
    title: 'ЗАКОНОДАТЕЛЬСТВО',
  },
];

const mapStateToProps = (state) => {
  const accessTo = state.currentUser.accessTo;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    activeId: 'search',
    tabs,
    renderNavigation: !!multiLicense || caselookLicense,
  };
};

const mapDispatchToProps = () => ({
  onTabClick(id) {
    window.location.hash = `#/${id}`;
  },
});

export const TabBarConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabBar);

export default TabBarConnect;
