import React from 'react';

import { iconsPath } from '@components/icons/iconsPaths';
import { Icon } from '@components/icons/icon';

import { NavigationPoint } from '@components/navigationPoint/navigationPoint';

import { scrollToParagraph } from '@pages/searchResult/documents/utils';

import { ReasonItem } from './reasonItem';

import s from './reasonItems.style';

export interface Item {
  id: string;
  title: string;
  blockIds: number[]
}

interface ReasonItemsProps {
  items: Item[];
  title: string;
  onItemClick(id: string, blockId: number): void;
  activeId: string;
  activePointId: number;
}

export function scrollTo(blockId) {
  const blockIds = ['annotation', 'summary', 'document-view__meta'];
  scrollToParagraph('document-view-container', blockId, { blockIds });
}

export class ReasonItems extends React.PureComponent<ReasonItemsProps> {

  handleItemClick = (id) => {
    const currentItem = this.props.items.find((item) => item.id === id);

    const currentPointIndex = currentItem.blockIds.findIndex((item) => item === this.props.activePointId);
    let nextIndex = currentPointIndex + 1;

    if (!currentItem.blockIds[nextIndex]) {
      nextIndex = 0;
    }

    const nextPointId = currentItem.blockIds[nextIndex];
    scrollTo(nextPointId);

    this.props.onItemClick(id, nextPointId);
  };

  handlePointClick = (reasonId, blockId) => {
    scrollTo(blockId);
    this.props.onItemClick(reasonId, blockId);
  };

  renderBetaIcon() {
    const crossPath = iconsPath.get('betaCircle');

    return (
      <s.BetaIcon>
        <Icon path={crossPath} color="#ff6400" size={12} />
      </s.BetaIcon>
    );
  }

  renderTitle() {
    return (
      <s.Title>
        {this.props.title}
        {this.renderBetaIcon()}
      </s.Title>
    );
  }

  renderItem = (item, index) => {
    const isActive = this.props.activeId === this.props.items[index].id;

    return (
      <ReasonItem
        isActive={isActive}
        id={item.id}
        onClick={this.handleItemClick}
      >
        <s.ReasonTitle>
          {item.title}
        </s.ReasonTitle>
        <NavigationPoint
          items={item.blockIds}
          itemId={item.id}
          activePointId={this.props.activePointId}
          onClick={this.handlePointClick}
        />
      </ReasonItem>
    );
  };

  render() {
    if (!this.props.items.length) {
      return null;
    }

    return (
      <>
        {this.renderTitle()}
        {this.props.items.map(this.renderItem)}
      </>
    );
  }

}

export default ReasonItems;
