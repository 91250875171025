import http from '@http';

import { RESTPath } from '@router/path.rest';

type Id = string;

interface RequestParams {
  searchId: Id,
  documentId: Id,
}

interface BodyBlock {
  id: Id;
  title: string;
  body: string;
}

interface DFR {
  title: string;
  url: string;
}

export interface Response {
  id: Id;
  edition_id: Id;
  edition: string;
  date: string;
  publication_date: string;
  is_actual: boolean;
  title: string;
  type: string;
  department_type: string;
  dfr: DFR[];
  url: string;
  number: string;
  publications: string[];
  body: BodyBlock[];
}

export function httpFetchDocument(requestParams: RequestParams): Promise<Response> {
  const request = http.get({
    url: RESTPath.searchLawDocument.getDocument(requestParams),
  });

  return http.fetch(request)
    .then(http.parseResponse);
}

export default httpFetchDocument;
