import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

interface Props {
  title: string;
  position: string;
  activeTitle: string;
  isActive: boolean;
  alignRight: boolean;
  alignLeft: boolean;
}

style.BaseButton = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 2px;

  width: 32px;
  height: 32px;

  padding: 0;

  margin-left: 5px;

  opacity: 0;

  background: transparent;
  cursor: pointer;

  transition: opacity .3s;

  position: ${(props: Props) => props.position};

  ${(props: Props) => props.alignLeft && css`
    top: 4px;
    left: -37px;
    margin-left: 0;
  `};
`;

style.Root = styled(style.BaseButton)`

  &:before {
    content: '${(props: Props) => props.title}';
    background: rgba(85, 99, 112, 0.9);
    color: ${theme.colors.white};
    font-size: 11px;
    padding: 4px 8px;
    width: max-content;
    position: absolute;
    top: -26px;
    display: none;
    border-radius: 2px;
  }

  ${(props: Props) => props.alignLeft && css`
    &:before {
      left: 0;
    }
  `};

  ${(props: Props) => props.alignRight && css`
    &:before {
      right: 0;
    }
  `};

  ${(props: Props) => props.isActive && css`
    opacity: 1;
    &:before {
      content: '${props.activeTitle}';
    }
  `}

  &:hover {
    background: rgba(65, 131, 215, 0.07);
    z-index: 3;

    &:before {
      display: block;
    }
  }


  &:focus {
    outline: none;
  }
`;

export default style;
