import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { WithHeaderNotification } from '@components/withHeaderNotification';

import s from '@pages/document/document.style';
import { ScrollTo } from './components/scrollTo';

import { HeaderConnect } from './header/header.connect';

import { WorkspaceConnect } from './workspace/workspace.connect';
import MarkersGlobalStyles from './workspace/document.global.style';

import { SidebarConnect } from './sidebar/sidebar.connect';

export const LAW_DOCUMENT_ID = 'law-document-view-container';

export interface LawDocumentProps {
  onInit(): void;
  onBootstrap?(): void;

  onUpdate(): void;
  loading: boolean;
  articleId: string;

  editionDate: string;

  searchId: string;
}

export class LawDocument extends React.PureComponent<LawDocumentProps> {

  componentDidMount() {
    sendAmplitudeData('lawDocument: open');
    Userpilot.track('lawDocument: open');

    if (this.props.onBootstrap) {
      this.props.onBootstrap();
    }

    this.props.onInit();
  }

  componentDidUpdate(prevProps: Readonly<LawDocumentProps>) {
    if (this.props.editionDate && this.props.editionDate !== prevProps.editionDate) {
      sendAmplitudeData('lawDocument: change edition', { editionDate: this.props.editionDate });
      Userpilot.track('lawDocument: change edition', { editionDate: this.props.editionDate });
      this.props.onUpdate();
    }
  }

  handleBodyMounted = () => {
    if (!this.props.articleId) {
      return;
    }

    const scrollTo = new ScrollTo();
    scrollTo.setRootNode(LAW_DOCUMENT_ID);
    scrollTo.setTargetNode(this.props.articleId);
    scrollTo.execute();
  };

  render() {
    return (
      <WithHeaderNotification>
        <s.Root>
          <HeaderConnect
            searchId={this.props.searchId}
          />
          <s.Content>
            <s.DocumentRoot
              id={LAW_DOCUMENT_ID}
            >
              <WorkspaceConnect onBodyMounted={this.handleBodyMounted} />
            </s.DocumentRoot>
            <SidebarConnect />
          </s.Content>
        </s.Root>
        <MarkersGlobalStyles />
      </WithHeaderNotification>
    );
  }

}

export default LawDocument;
