import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { TabsMore } from './tabsMore';

import s from './more.style';

interface Tab {
  id: string;
  title: string;
}

interface MenuProps {
  tabsMore: Tab[];
  activeId: string;
  onUpdateActiveTab(tabId: string): void;
}

interface InputProps {
  isReal?: boolean;
  isDocument?: boolean;
  active: boolean;
  tabTitle: string
}

const InputComponent = (props: InputProps) => (
  <s.InputBlock
    active={props.active}
  >
    {props.tabTitle}
  </s.InputBlock>
);

const MenuComponent = (props: MenuProps) => (
  <s.MenuBloks>
    <TabsMore
      tabsMore={props.tabsMore}
      activeId={props.activeId}
      onClick={props.onUpdateActiveTab}
    />
  </s.MenuBloks>
);

export class More extends React.PureComponent<InputProps & MenuProps> {

  render() {
    if (!this.props.isDocument) {
      return null;
    }

    if (!this.props.isReal) {
      return null;
    }

    const active = this.props.active;

    return (
      <s.Root
        active={active}
      >
        <BaseDropdown
          usePortal
          placement="bottom-start"
          renderInput={(p) => (
            <InputComponent
              active={active}
              tabTitle={this.props.tabTitle}
              {...p}
            />
          )}
          renderMenu={(p) => (
            <MenuComponent
              activeId={this.props.activeId}
              tabsMore={this.props.tabsMore}
              onUpdateActiveTab={this.props.onUpdateActiveTab}
              {...p}
            />
          )}
        />
      </s.Root>
    );
  }

}

export default More;
