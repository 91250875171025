export const SEARCH_LAW_FORM_BLOCK = 'SEARCH_LAW/FORM/BLOCK';

class ResetActiveItem {

  private dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  private resetActiveHistoryItem() {

  }

  public invoke(next) {
    return (action) => {
      const returnValue = next(action);

      return returnValue;
    };
  }

}

export function resetActiveItemMiddleware({ dispatch }) {
  const middleware = new ResetActiveItem(dispatch);

  return (next) => middleware.invoke(next);
}
