import React, {
  useState,
  useEffect,
} from 'react';

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import { Loader } from '@components/loader';

import { animateTopScroll } from '@components/scrollTo/animateScroll';
import { NavigationArrow } from '@components/navigationArrow/navigationArrow';

import { SliderConnect } from './slider.connect';

import { scrollToParagraph } from '../utils';

import s from './reasonNavigation.style';

interface DocumentTabProps {
  title: string;
  targetId: string;
  noDuplicateLayer?: boolean;
  firstParagraphId: number;
  ids: string[];
  isActive: boolean;
  loadingReasons: boolean;
  loadingSuccess: boolean;
  isUserGuest: boolean;
}

export const ReasonNavigation = (props: DocumentTabProps) => {

  const [index, setIndex] = useState(null);
  const [prevIndex, setPrevIndex] = useState(null);

  const [firstParagraphId, setFirstParagraphId] = useState(null);

  const handleDownClick = () => {
    // @ts-ignore
    setIndex((prevState) => {
      setPrevIndex(prevState);
      if (isNull(prevState)) {
        return 0;
      }

      const isLast = props.ids.length - 1 === prevState;
      if (isLast) {
        return prevState;
      }

      setPrevIndex(prevState);

      return prevState + 1;
    });
  };

  const handleUpClick = () => {
    if (index === null) {
      return;
    }

    if (index === 0) {
      setPrevIndex(index);
      setFirstParagraphId(null);
      setIndex(null);

      return;
    }

    // @ts-ignore
    setIndex((prevState) => {
      if (isNull(prevState)) {
        return 0;
      }

      setPrevIndex(prevState);

      return prevState - 1;
    });
  };

  const isLast = () => index === props.ids.length - 1;

  const isFirst = () => index === null;

  useEffect(() => {
    const defaultValue = !index && index !== 0;
    if (defaultValue) {
      // @ts-ignore
      setFirstParagraphId(props.firstParagraphId);
    }

    const documentIsChange = props.firstParagraphId !== firstParagraphId;
    if (documentIsChange) {
      const container = document.getElementById(props.targetId);
      animateTopScroll(0, { duration: 1, targetElement: container });
      setIndex(null);

      return;
    }

    // @ts-ignore
    if (!props.ids[index]) {
      return;
    }

    const highlightIsChange = prevIndex !== index;
    if (!highlightIsChange) {
      return;
    }

    const blockIds = ['annotation', 'summary', 'document-view__meta'];
    scrollToParagraph(props.targetId, props.ids[index], { blockIds, noDuplicateLayer: !!props.noDuplicateLayer });
  }, [index]);

  const isEmptyReasons = isEmpty(props.ids);

  const renderSlider = () => {
    if (props.loadingReasons) {
      return (
        <s.Loader>
          <Loader size={16} />
        </s.Loader>
      );
    }

    return <SliderConnect isEmpty={isEmptyReasons} />;
  };

  const shouldDisabled = props.loadingReasons || props.isActive || isEmptyReasons;
  const emptyReasons = !props.loadingReasons && props.loadingSuccess && isEmptyReasons;
  const beta = '(бета)';

  return (
    <s.Root
      isUserGuest={!!props.isUserGuest}
    >
      <s.Navigation
        emptyReasons={emptyReasons}
      >
        {renderSlider()}
        <s.Title
          isEmptyIds={isEmptyReasons}
        >
          {props.title}
        </s.Title>
        <s.Beta
          isEmptyIds={isEmptyReasons}
        >
          {beta}
        </s.Beta>
        <s.Actions>
          <s.Action
            shouldDisabled={shouldDisabled}
            disabled={isFirst()}
            onClick={handleUpClick}
          >
            <NavigationArrow
              direction="up"
            />
          </s.Action>
          <s.Action
            shouldDisabled={shouldDisabled}
            disabled={isLast()}
            onClick={handleDownClick}
          >
            <NavigationArrow
              direction="down"
            />
          </s.Action>
        </s.Actions>
      </s.Navigation>
    </s.Root>
  );
};

export default ReasonNavigation;
