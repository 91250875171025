export const toggleReasonReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    reasons: {
      ...state.reasons,
      showReasons: !state.reasons.showReasons,
    },
  };
};

export default toggleReasonReduce;
