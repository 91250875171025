import styled, { css } from 'styled-components';

interface Props {
  theme: any,
  active: boolean;
}

const style: any = {};

const Bar = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid rgb(223, 226,230);
  font: 17px Roboto-Medium, sans-serif;
  font-weight: 500;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

style.NavigationBar = styled(Bar)`
  height: 49px;
  display: flex;
`;

style.NavigationBarContent = styled.div`
  min-width: 960px;
  max-width: 1152px;
  margin: 0 auto;
  width: 100%;
`;

style.TabBar = styled(Tab)`
  height: 48px;
  padding: 0 8px;
  background: ${(props: Props) => props.theme.colors.white};
`;

style.TabItem = styled.div`
  text-transform: uppercase;
  font: 13px Roboto-Medium;
  height: 48px;
  display: flex;
  margin: 0 8px;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.colors.stone};
  position: relative;
  cursor: pointer;

  transition: color 0.2s ease;

  &:hover {
    color: ${(props: Props) => props.theme.colors.defaultText};
  }

  ${(props: Props) => props.active && css`
    color: ${props.theme.colors.defaultText};
    ::after {
      content: '';
      background: ${props.theme.colors.orange};
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
    }
  `}

  :nth-child(0) {
    margin: 0 15px 0 8px;
  }

  :nth-child(2) {
    margin: 0 15px 0 14px;
  }
`;

export default style;
