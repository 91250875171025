import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { Cell } from './itemDetail.cell';

import s from './item.style';
import { DataFromView } from './itemDetail.connect';

interface Item {
  id: string;
  title: string;
}

interface Props {
  shouldLoadArticles: boolean;
  items: Item[];
  onFetch: () => void;
  onAddDocument(data: DataFromView): void;
}

interface State {
  currentPage: number;
  items: Item[];
}

export class ItemDetail extends React.PureComponent<Props, State> {

  static createPages(items) {
    const perPage = 10;
    const pages = [];
    for (let i = 0; i < Math.ceil(items.length / perPage); i += 1) {
      // @ts-ignore
      pages[i] = items.slice((i * perPage), (i * perPage) + perPage);
    }

    return pages;
  }

  state = {
    currentPage: 1,
    items: [],
  };

  componentDidMount() {
    if (this.props.shouldLoadArticles) {
      this.fetch();
    } else {
      const pages = ItemDetail.createPages(this.props.items);
      this.setStateItems(pages[0]);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {} as State;

    const nextPages = ItemDetail.createPages(nextProps.items);

    if (prevState.currentPage === 1) {
      state.items = nextPages[0];
    }

    if (prevState.currentPage !== 1) {
      state.items = [];

      for (let i = 0; i <= prevState.currentPage - 1; i += 1) {
        state.items = state.items.concat(nextPages[i]);
      }
    }

    return isEmpty(state) ? null : state;
  }

  setStateItems(items) {
    this.setState({
      items,
    });
  }

  setCurrentPage(page) {
    this.setState({
      currentPage: page,
    });
  }

  handleShowMoreClick = () => {
    this.setCurrentPage(this.state.currentPage + 1);
  };

  prepareState() {
    const pages = ItemDetail.createPages(this.props.items);
    this.setStateItems(pages[0]);
    this.setCurrentPage(1);
  }

  fetch() {
    if (this.props.shouldLoadArticles) {
      this.props.onFetch();
    } else {
      this.prepareState();
    }
  }

  renderRow = (column) => (
    <s.InnerRow>
      {column.map((cell) => {
        const handleClick = (e) => {
          e.stopPropagation();

          const isEmptyTitle = cell.title === '0' || cell.title === '0%';
          if (isEmptyTitle) {
            return;
          }

          const data = {
            children: column[0],
            columnId: cell.id,
          };

          this.props.onAddDocument(data);
        };

        return (
          <Cell
            onClick={handleClick}
            key={cell.id}
            cell={cell}
            link={cell.link}
            columns={column.length}
          />
        );

      })}
    </s.InnerRow>
  );

  renderShowMore() {
    const perPage = 10;
    const pages = Math.ceil(this.props.items.length / perPage);
    let showMore = true;

    if (!pages) {
      showMore = false;
    }

    if (this.state.currentPage === pages) {
      showMore = false;
    }

    if (this.props.shouldLoadArticles) {
      return null;
    }

    if (!showMore) {
      return null;
    }

    return (
      <s.ShowMore
        onClick={this.handleShowMoreClick}
      >
        Показать еще
      </s.ShowMore>
    );
  }

  renderArticles() {
    if (this.props.shouldLoadArticles) {
      return null;
    }

    if (isEmpty(this.state.items)) {
      return null;
    }

    return this.state.items.map(this.renderRow);
  }

  render() {
    return (
      <>
        <s.Table>
          {this.renderArticles()}
        </s.Table>
        {this.renderShowMore()}
      </>
    );
  }

}

export default ItemDetail;
