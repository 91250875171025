import React from 'react';

import { Label } from '@components/label';

import s from './tabBar.style';

export type ItemId = string;

interface TabBarItemProps {
  onClick: (id: ItemId) => void;
  title: string | React.Component;
  activeId: ItemId;
  id: ItemId;
}

export const TabBarItem = (props: TabBarItemProps) => {
  const handleClick = () => {
    props.onClick(props.id);
  };

  const isActive = props.activeId === props.id;
  if (props.id === 's_law') {
    return (
      <s.TabItem active={isActive} onClick={handleClick}>
        {props.title}
        {' '}
        <Label title="NEW" color="#ff6400" />
      </s.TabItem>
    );
  }

  return <s.TabItem active={isActive} onClick={handleClick}>{props.title}</s.TabItem>;
};

export default TabBarItem;
