import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './control.style';

type Props = {
  onClick: () => void,
};

const plusPoints = iconsPath.get('plus');

export const DuplicateButton = ({ onClick }: Props) => (
  <s.Root
    position="absolute"
    title="Дублировать фильтр"
    activeTitle="Открепить фильтр"
    alignLeft
    className="filter-duplicate-button"
    onClick={onClick}
  >
    <Icon points={plusPoints} color="#6c7b89" />
  </s.Root>
);

export default DuplicateButton;
