import { Block as TextBlock } from './text/redux/block';
import { BlockConnect as TextConnect } from './text/block.connect';

import { Block as TitleBlock } from './title/redux/block';
import { BlockConnect as TitleConnect } from './title/block.connect';

import { Block as LawBlock } from './law/redux/block';
import { BlockConnect as LawConnect } from './law/block.connect';

import { Block as LawTypeBlock } from './lawType/redux/block';
import { BlockConnect as LawTypeConnect } from './lawType/block.connect';

import { Block as LawNumberBlock } from './lawNumber/redux/block';
import { BlockConnect as LawNumberConnect } from './lawNumber/block.connect';

import { Block as LawStatusBlock } from './lawStatus/redux/block';
import { BlockConnect as LawStatusConnect } from './lawStatus/block.connect';

import { Block as DepartmentTypeBlock } from './departmentType/redux/block';
import { BlockConnect as DepartmentTypeConnect } from './departmentType/block.connect';

import { Block as DateBlock } from './date/redux/block';
import { BlockConnect as DateBlockConnect } from './date/block.connect';

import { Block as PublicationDateBlock } from './publicationDate/redux/block';
import { BlockConnect as PublicationDateBlockConnect } from './publicationDate/block.connect';

import { Block as PublicationStatusBlock } from './publicationStatus/redux/block';
import { BlockConnect as PublicationStatusConnect } from './publicationStatus/block.connect';

export const filterBlocksMap = new Map();

const text = { block: TextBlock, component: TextConnect };
filterBlocksMap.set(TextBlock.type, text);

const title = { block: TitleBlock, component: TitleConnect };
filterBlocksMap.set(TitleBlock.type, title);

const law = { block: LawBlock, component: LawConnect };
filterBlocksMap.set(LawBlock.type, law);

const lawType = { block: LawTypeBlock, component: LawTypeConnect };
filterBlocksMap.set(LawTypeBlock.type, lawType);

const lawNumber = { block: LawNumberBlock, component: LawNumberConnect };
filterBlocksMap.set(LawNumberBlock.type, lawNumber);

const lawStatus = { block: LawStatusBlock, component: LawStatusConnect };
filterBlocksMap.set(LawStatusBlock.type, lawStatus);

const departmentType = { block: DepartmentTypeBlock, component: DepartmentTypeConnect };
filterBlocksMap.set(DepartmentTypeBlock.type, departmentType);

const date = { block: DateBlock, component: DateBlockConnect };
filterBlocksMap.set(DateBlock.type, date);

const publicationDate = { block: PublicationDateBlock, component: PublicationDateBlockConnect };
filterBlocksMap.set(PublicationDateBlock.type, publicationDate);

const publicationStatus = { block: PublicationStatusBlock, component: PublicationStatusConnect };
filterBlocksMap.set(PublicationStatusBlock.type, publicationStatus);

export default filterBlocksMap;
