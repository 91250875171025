import React from 'react';
import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { BaseInput } from '@pages/search/form/components/baseInput/baseInput';

import { Block as TextBlock } from './redux/block';

import { Checkbox } from './checkbox';
import { ProximityRangeLabel } from './proximityRangeLabel';

import s from './settings.style';

interface SettingsProps {
  value: string;
  mode: string;
  proximityRange: number;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  onChangeMode: (mode:any) => void;
  onUpdateProximityRange: (proximityRange: number) => void;
}

export class Settings extends React.PureComponent<SettingsProps> {

  prepareInputValue = () => {
    const modeTitle = TextBlock.presets.mode[this.props.mode].title;
    let value = `${modeTitle}`;

    const isExactlyMode = this.props.mode === TextBlock.presets.mode.exactly.id;
    const showPostfix = !isExactlyMode || (isExactlyMode && this.shouldRenderExtendSettings());
    if (showPostfix) {
      value = `${modeTitle}`;
    }

    const isProximityMode = this.props.mode === TextBlock.presets.mode.proximity.id;
    if (isProximityMode) {
      return `${value} ${this.props.proximityRange}`;
    }

    return value;
  };

  changeMode() {
    const isExactly = this.props.mode === TextBlock.presets.mode.exactly.id;
    if (isExactly) {
      return;
    }

    this.props.onChangeMode(TextBlock.presets.mode.exactly.id);
  }

  shouldRenderExtendSettings() {
    const showExtendSettings = this.props.value &&
      this.props.value.replace(/ +/g, ' ').trim().split(' ')
        .length > 1;

    return showExtendSettings;
  }

  renderInput(disabled = false) {
    const value = this.prepareInputValue();

    return (<BaseInput disabled={disabled} value={value} />);
  }

  renderExtendSection() {
    const isExactly = this.props.mode === TextBlock.presets.mode.exactly.id;
    const isDefault = this.props.mode === TextBlock.presets.mode.default.id;
    const isProximity = this.props.mode === TextBlock.presets.mode.proximity.id;

    return (
      <s.ExtendSection>
        <s.SectionContent>
          <Checkbox
            isActive={isExactly}
            item={TextBlock.presets.mode.exactly}
            onClick={this.props.onChangeMode}
          >
            {TextBlock.presets.mode.exactly.title}
          </Checkbox>
          <Checkbox
            isActive={isProximity}
            item={TextBlock.presets.mode.proximity}
            onClick={this.props.onChangeMode}
          >
            <ProximityRangeLabel
              title={TextBlock.presets.mode.proximity.title}
              proximityRange={this.props.proximityRange}
              onUpdateProximityRange={this.props.onUpdateProximityRange}
            />
          </Checkbox>
          <Checkbox
            isActive={isDefault}
            item={TextBlock.presets.mode.default}
            onClick={this.props.onChangeMode}
          >
            {TextBlock.presets.mode.default.title}
          </Checkbox>
        </s.SectionContent>
      </s.ExtendSection>
    );
  }

  renderSections() {
    return (
      <>
        {this.renderExtendSection()}
      </>
    );
  }

  renderDropdown() {
    return (
      <s.Dropdown>
        {this.renderSections()}
      </s.Dropdown>
    );
  }

  render() {
    if (!this.shouldRenderExtendSettings()) {
      this.changeMode();

      return (
        <s.Settings>
          {this.renderInput(true)}
        </s.Settings>
      );
    }

    return (
      <s.Settings>
        <BaseDropdown
          disabled={this.props.disabled}
          renderInput={() => (this.renderInput())}
          renderMenu={() => (this.renderDropdown())}
        />
      </s.Settings>
    );
  }

}

export default Settings;
