import styled, { css } from 'styled-components';

interface Props {
  theme: {
    colors: Record<string, string>;
  },
  isActive: boolean;
  bold: boolean;
  visible: boolean;
  highlight: boolean;
  isVisited: boolean;
}

function textEllipsis() {
  return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

const style: any = {};

export const width = {
  checkbox: '48px',
  document: '208px',
  text: '100%',
  more: '40px',
};

style.Root = styled.div`
  font-size: 13px;
  background-color: ${(props: Props) => props.theme.colors.white};
`;

style.Table = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
`;

style.Group = styled.div`
  display: table-column-group;
`;

style.ColumnWidthDocument = styled.div`
  width: ${width.document};
`;

style.ColumnWidthText = styled.div`
  width: ${width.text};
`;

style.CellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

style.BodyCellContainer = styled(style.CellContainer)`
  padding: 13px 16px;
`;

style.ColumnDocument = styled(style.ColumnWidthDocument)`
  display: table-column;
`;

style.ColumnText = styled(style.ColumnWidthText)`
  display: table-column;
`;

export const cellStyle = () => css`
  display: table-cell;
  vertical-align: top;
`;

style.CellDocument = styled(style.ColumnWidthDocument)`
  ${cellStyle()}
`;

style.Header = styled.div`
  display: table-header-group;
  line-height: 38px;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

style.HeaderText = styled.div`
  font-size: 11px;
  padding: 1px 16px 0;
  font-family: Roboto-Bold;
`;

style.BodyText = styled.div`
  font-size: 13px;
  line-height: 16px;
  ${textEllipsis};
`;

style.TitleLink = styled.div`
  display: flex;
  color: ${(props: Props) => (!props.isVisited ? props.theme.colors.blue : props.theme.colors.disabledText)};
  ${textEllipsis};
  &:hover {
    *:nth-child(1){
      text-decoration: underline;
    }
    *:nth-child(2){
      display: block;
    }
    *:nth-child(3){
      display: block;
    }
  }
`;

style.Body = styled.div`
  display: table-row-group;
`;

style.Item = styled.div`
  display: table-row;
  cursor: pointer;
  user-select: none;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};

  ${(props: Props) => props.isActive && css`
    background-color: ${props.theme.colors.polar};
  `}

  &:hover {
    background-color: ${(props: Props) => props.theme.colors.polar};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

style.Title = styled.a`
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  color: ${(props: Props) => (!props.isVisited ? props.theme.colors.blue : props.theme.colors.disabledText)};
`;

style.Icon = styled.div`
  display: none;
  margin: -1px 4px;
`;

style.ActualStatus = styled.div`
  margin-left: 8px;
  font: 11px Roboto-Medium;
  color: ${(props: Props) => props.theme.colors.brightOrange};
  border: 1px solid ${(props: Props) => props.theme.colors.brightOrange};
  border-radius: 2px;
  padding: 0 4px;
`;

export default style;
