import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

interface Props {
  isEmpty: boolean;
}

style.Root = styled.div`

`;

style.Tabs = styled.div`

`;

style.Commentary = styled.div`
  border-bottom: 1px solid ${theme.colors.borderColor};
`;

style.DescriptionNote = styled.div`

  height: 14px;

  ${(props: Props) => props.isEmpty && css`
    height: 40px;
    display: flex;
    align-items: center;
    background-color: ${theme.colors.backgroundGrey};
    border-bottom: 1px solid ${theme.colors.borderColor};
    padding: 0 16px;
    font-family: "Roboto-medium", sans-serif;
    font-size: 13px;
  `}
`;

style.DescriptionDocument = styled.div`

  height: 16px;

  ${(props: Props) => props.isEmpty && css`
    height: 40px;
    display: flex;
    align-items: center;
    background-color: ${theme.colors.backgroundGrey};
    border-bottom: 1px solid ${theme.colors.borderColor};
    padding: 0 16px;
    font-family: "Roboto-medium", sans-serif;
    font-size: 13px;
  `}
`;

style.Link = styled.div`
  position: relative;

  > div {
    padding: 8px 40px 7px 16px;
  }

  > a {
    position: absolute;
    top: 11px;
    right: 11px;
  }
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;
  z-index: 2;

  > div {
    margin-top: 10px;
  }
`;

export default style;
