import React, { PropsWithChildren } from 'react';

import s from './reasonItem.style';

interface ReasonItemProps {
  isActive: boolean;
  id: string;
  onClick: (id: string) => void;
}

export class ReasonItem extends React.PureComponent<PropsWithChildren<ReasonItemProps>> {

  handleClick = (e) => {
    e.stopPropagation();

    this.props.onClick(this.props.id);
  };

  render() {
    return (
      <s.Root
        onClick={this.handleClick}
        isActive={this.props.isActive}
        id={this.props.id}
      >
        {this.props.children}
      </s.Root>
    );
  }

}

export default ReasonItem;
