import { SEARCH_LAW_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateFirstDateReduce } from './updateFirstDate.reduce';

const actionType = `${SEARCH_LAW_FORM_BLOCK}/DATE/UPDATE_FIRST_DATE`;

export interface Data {
  value: Date;
}

export interface ActionData {
  id: string;
  data: Data;
}

export interface Action {
  type: typeof actionType;
  data: ActionData;
}

const createAction = (data: ActionData): Action => ({
  type: actionType,
  data,
});

export const updateFirstDate = {
  actionType,
  createAction,
  reduce: updateFirstDateReduce,
};

export default updateFirstDate;
