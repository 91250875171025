import { buildHistoryItem } from '@utils/utils';

function buildForSearch(breadcrumbs, params) {
  breadcrumbs.push(buildHistoryItem(1, 'Поиск', false, '#/s_law'));
  breadcrumbs.push(buildHistoryItem(2, 'Результаты поиска', false, `#/s_law/${params.searchId}/documents`));

  if (!params.title) {
    breadcrumbs.push(buildHistoryItem(3, null, true));
  } else {
    breadcrumbs.push(buildHistoryItem(3, params.title, false));
  }

  return breadcrumbs;
}

function buildForDocument(breadcrumbs, title) {
  breadcrumbs.push(buildHistoryItem(1, title, false));

  return breadcrumbs;
}

export function buildBreadcrumbs(params: { searchId: string; title: string; }) {
  const breadcrumbs = [];

  if (params.searchId) {
    return buildForSearch(breadcrumbs, params);
  }

  return buildForDocument(breadcrumbs, params.title);
}

export default buildBreadcrumbs;
