import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  display: flex;
  width: 320px; 
  flex-direction: column;
  padding: 14px 16px 13px;
  border-bottom: 1px solid #eef3f4;
`;

style.Description = styled.div`
  font-family: Roboto-Regular;
  font-size: 11px;
  font-weight: normal;
  color: #6c7a89;
  line-height: normal;
`;

style.Title = styled.div`
  font-family: Roboto-Regular;
  font-size: 13px;
  font-weight: normal;
  color: #22313f;
  line-height: normal;
`;

export default style;
