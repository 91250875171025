import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';
import { LawConnect } from './law.connect';

import { withFetchSearch } from '../withFetchSearch';

export const RouteSearchLaws = (
  <AuthRouteConnect
    path={RoutePath.law}
    component={withFetchSearch(LawConnect)}
  />
);

export default RouteSearchLaws;
