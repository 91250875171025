import React from 'react';

import { MultipleField } from './multipleField';

import s from './attributeField.style';

export interface FieldProps {
  id: string;
  name: string;
}

interface AttributeFieldProps {
  placeholder: string;
  value?: string;
  values?: FieldProps[];
  multiple?: boolean;
}

export const AttributeField = (props: AttributeFieldProps) => {

  const renderBlock = (body) => ({
    __html: body,
  });

  const renderValue = () => {
    if (!props.multiple) {
      return (
        <s.Value>
          <div
            dangerouslySetInnerHTML={renderBlock(props.value)}
          />
        </s.Value>
      );
    }

    return <MultipleField values={props.values} />;
  };

  const isEmptySingle = !props.multiple && !props.value;
  if (isEmptySingle) {
    return null;
  }

  const isEmptyMultiple = props.multiple && !props.values.length;
  if (isEmptyMultiple) {
    return null;
  }

  return (
    <s.Root>
      <s.Placeholder>{props.placeholder}</s.Placeholder>
      {renderValue()}
    </s.Root>
  );

};

AttributeField.defaultProps = {
  value: '',
  values: [''],
  multiple: false,
};

export default AttributeField;
