import { Dispatch } from 'redux';

import { RequestParams } from '@providers/download/download.http';
import { httpFetchLawDocument } from './fetchLawDocument.http';
import { fetchLawDocument } from './fetchLawDocument';

import { fetchEditionsThunk } from '../sidebar/redux/fetchEditions.thunk';

export class FetchLawDocumentThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: RequestParams) {
    await this.getDocument(params);

    const state = this.getState();
    const hasEditions = !state.lawDocument.editions.loading;
    if (!hasEditions) {
      fetchEditionsThunk(this.dispatch, { documentId: state.lawDocument.documentId });
    }
  }

  private async getDocument(params) {
    try {
      const result = await httpFetchLawDocument(params);
      this.dispatchFetchDocumentSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchDocumentSucceed(result) {
    this.dispatch(fetchLawDocument.createAction(result));
  }

}

export function fetchLawDocumentThunk(dispatch, params) {
  const thunk = new FetchLawDocumentThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchLawDocumentThunk;
