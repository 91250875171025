import React from 'react';
import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { fetchFiltersThunk } from './redux/fetchFilters.thunk';

function ensureConnect(Wrapped) {
  const mapDispatchToProps = (dispatch, ownProps) => ({

    onBootstrap() {
      const { searchId } = getParamsFromUrl(ownProps);
      fetchFiltersThunk(dispatch, searchId);
    },

  });

  return connect(
    null,
    mapDispatchToProps,
  )(Wrapped);
}

export function withFetchSearchLaw(WrappedComponent) {
  class WithBootstrap extends React.PureComponent {

    render() {
      return <WrappedComponent {...this.props} />;
    }

  }

  return ensureConnect(WithBootstrap);
}

export default withFetchSearchLaw;
