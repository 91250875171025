import React from 'react';

import { Loader } from '@components/loader';
import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';
import { OpenDrawer } from '@components/openDrawer';
import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import { NotFound } from '@components/notFound';
import { Header } from '../components/header/header';

import type {
  SearchId,
  DocumentId,
} from '../redux/state';

import { ProcessingStatus } from '../redux/state';

import { HeaderConnect } from './header/header.connect';
import { ListConnect } from './list/list.connect';
import { PaginationConnect } from './pagination/pagination.connect';
import { DocumentPreviewConnect } from './preview/documentPreview.connect';

import type { Loading } from './redux/state';

import s from './documents.style';

export interface DocumentsProps {
  id: SearchId;
  isEmpty: boolean;
  items: DocumentId[];
  loading: Loading;
  pageLoading: Loading;
  processingStatus: ProcessingStatus;
  onFetch(): void;
  onResetPageNumber(): void;
  onBootstrap(): void;
}

interface State {
  showPreview: boolean;
  documentPreviewId: DocumentId
  activeId: string;
  title: string;
}

export class Documents extends React.PureComponent<DocumentsProps, State> {

  state = {
    showPreview: false,
    documentPreviewId: null,
    activeId: null,
    title: null,
  };

  contentRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    Userpilot.reload();
    this.fetchDocumentsMount();
    this.props.onBootstrap();
  }

  componentDidUpdate(prevProps: DocumentsProps) {
    const isNew = this.props.id !== prevProps.id;
    if (!isNew) {
      return;
    }

    this.props.onFetch();
  }

  componentWillUnmount() {
    this.props.onResetPageNumber();
  }

  handleItemClick = (id: DocumentId, activeId, title: string = null) => {
    this.setState({
      showPreview: true,
      activeId,
      documentPreviewId: id,
      title,
    });
  };

  handleCloseDrawer = () => {
    this.setState({
      showPreview: false,
      activeId: null,
      documentPreviewId: null,
    });
  };

  onAfterPagination = () => {
    this.contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  fetchDocumentsMount() {
    const isProcessingStatus = this.props.processingStatus === ProcessingStatus.none ||
      this.props.processingStatus === ProcessingStatus.done;

    if (isProcessingStatus) {
      this.props.onFetch();
    }
  }

  contentSwitcher() {
    if (this.props.isEmpty) {
      return <NotFound />;
    }

    const paginationProps = {
      onAfterPagination: this.onAfterPagination,
    };

    return (
      <s.ContentContainer>
        <HeaderConnect />
        <s.ItemsContent>
          {this.renderPageLoader()}
          <ListConnect
            documentPreviewId={this.state.activeId}
            onItemClick={this.handleItemClick}
          />
        </s.ItemsContent>
        <PaginationConnect {...paginationProps} />
      </s.ContentContainer>
    );
  }

  renderContent = () => (
    <s.Content ref={this.contentRef}>
      {this.contentSwitcher()}
    </s.Content>
  );

  renderDrawer = () => {
    if (!this.state.showPreview) {
      return null;
    }

    const documentPreviewId = this.state.documentPreviewId;
    sendAmplitudeData('caselook:law_documents:preview doc');
    Userpilot.track('caselook:law_documents:preview doc');

    return (
      <OpenDrawer
        anchor="right"
        withBackground={false}
        component={(
          <DocumentPreviewConnect
            title={this.state.title}
            onClose={this.handleCloseDrawer}
            documentId={documentPreviewId}
          />
        )}
        onClickOutside={this.handleCloseDrawer}
      />
    );
  };

  renderLayout = () => (
    <s.Root>
      <Header sourceId={this.props.id} />
      {this.renderContent()}
      {this.renderDrawer()}
    </s.Root>
  );

  renderPageLoader() {
    if (!this.props.pageLoading) {
      return null;
    }

    return (<s.PageLoader />);
  }

  renderMain() {
    if (this.props.loading) {
      return <Loader />;
    }

    return this.renderLayout();
  }

  render() {
    return (
      <WithHeaderNotification>
        {this.renderMain()}
      </WithHeaderNotification>
    );
  }

}

export default Documents;
