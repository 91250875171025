/* eslint-disable */
import React from 'react';

import { AddTagButton } from '@components/addTag/addTagButton';

import { TooltipReasonWithClickConnect } from './components/tooltipReasons.connect';

import { theme } from '@components/styled';

import s from './block.style';

export class Block extends React.PureComponent {

  state = {
    hovered: false,
    tooltipShow: false,
  }

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    this.highlightReason();
  }

  componentDidUpdate() {
    this.highlightReason();
  }

  highlightReason = () => {
    let reasonElements = document.querySelectorAll('.reason');
    if (this.props.activeReason) {
      reasonElements.forEach(element => element.style = `background: ${theme.colors.blue}`);
    }

    if (!this.props.activeReason) {
      reasonElements.forEach(element => element.style = `background: ${theme.colors.white}`);
    }
  }

  createArticleMarkup() {
    return {
      __html: this.props.block.body,
    };
  }

  handleClick = () => {
    if (this.props.paragraphInBookmarks) {
      this.props.onRemoveBookmark()
      return;
    }

    const text = this.contentRef.current.innerText;

    this.props.onAddBookmark(text);
  }

  renderBookmark = () => {
    const isRestrictedDocument = this.props.isSimilarDocument || this.props.isChronologyDocument;
    if (isRestrictedDocument) {
      return null;
    }

    if (!this.props.isReal) {
      return null;
    }

    if(!this.props.paragraphInBookmarks) {
      return (
        <AddTagButton
          renderFromRight={this.props.isPreview}
          path="bookmarkEmpty"
          color="#979797"
        />
      )
    }

    return (
      <AddTagButton
        renderFromRight={this.props.isPreview}
        onAddCommentary={this.props.onAddCommentary}
        commentary={this.props.commentary}
        shouldRenderComment
        path="bookmark"
        color="#e74c3c"
      />
    )
  }

  renderReason() {
    if (!this.props.paragraphInReasons) {
      return null;
    }

    if (!this.props.activeReason) {
      return null;
    }

    return (
      <s.Reason className="reason" />
    )
  }

  renderTooltipReason() {
    if (!this.state.tooltipShow) {
      return null
    }

    const tooltipXPosition = this.contentRef.current.offsetWidth;

    return (
      <TooltipReasonWithClickConnect
        tooltipIdReason={this.props.block.id}
        tooltipXPosition={tooltipXPosition}
        onClickOutside={this.hideTooltip}
      />
    )
  }

  handleMouseOver = () => {
    this.setState({
      hovered: true,
    })
  }

  handleMouseOut = () => {
    this.setState({
      hovered: false,
    })
  }

  handleOnClick = (event) => {
    const tagNameInLowerCase = event.target.tagName.toLowerCase();
    const isLink = tagNameInLowerCase === 'a';
    const shouldRender = this.props.paragraphInReasons && this.props.activeReason;
    if (!shouldRender || isLink) {
      return;
    }

    this.setState({
      tooltipShow: true,
    });
  }

  hideTooltip = () => {
    this.setState({
      tooltipShow: false,
    });
  };

  render() {
    return (
      <s.Root
        data-entity-id={this.props.block.id}
        className="article"
      >
        {this.renderTooltipReason()}
        {this.renderReason()}
        <s.Content
          ref={this.contentRef}
          hightlight={this.state.hovered}
          dangerouslySetInnerHTML={this.createArticleMarkup()}
          onClick={this.handleOnClick}
        />
        <s.Bookmark
          className="caselook__bookmark"
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          shouldAlwaysRender={this.props.paragraphInBookmarks}
          onClick={this.handleClick}
        >
          {this.renderBookmark()}
        </s.Bookmark>
      </s.Root>
    );
  }

}

export default Block;
