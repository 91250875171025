import { GetDocumentsSettings } from '@pages/searchResult/law/redux/fetchLaws.http';
import { GetArticlesSettings } from '@pages/searchResult/law/redux/fetchArticles.http';

import { GetCourtsSettings } from '@pages/searchResult/courts/redux/fetchCourts.http';
import { GetJudgesSettings } from '@pages/searchResult/courts/redux/fetchJudges.http';

import { GetPackagesParams } from '@pages/packages/redux/fetchPackages.http';

export type Query = string;
export type PageNumber = number;
export type Id = number;
export type DocumentId = number;
export type RelationId = string;
export type ProjectNumber = string;

export enum Source {
  search = 'search',
  searchLaw = 'search_law',
  package = 'package',
  subscription = 'subscription',
  chronology = 'history',
  similar = 'similar',
  regulationProject = 'regulation',
  regulationFolder = 'regulationFolder',
  authorPackage = 'authorpackage',
  authorPackage2 = 'authorpackage2',
  companyPackage = 'companypackage',
  lawDocument = 'law',
}

export enum Format {
  xlsx = 'xlsx',
  docx = 'docx',
  pdf = 'pdf',
}

export enum DownloadType {
  all = 'all',
  viewed = 'viewed',
  new = 'new',
}

export enum DownloadFormat {
  original = 'original',
  withMarkers = 'withMarkers',
  docx = 'docx',
}

export interface AddDocument {
  source: Source.search | Source.package | Source.subscription | Source;
  packageId: number;
  documentId: number;
  sourceId: number;
}

export interface DeleteDocumentFromPackage {
  packageId: number;
  documentId: number;
}

export interface FetchLawDocument {
  documentId: string;
  articleId: string;
  editionDate: string;
}

export interface FetchLawDocumentEditions {
  documentId: DocumentId;
}

export const RESTPath = {
  bookmarks: {
    getItems: '/bookmarks',
    getItemsPage(page: number) {
      return `bookmarks/list/${page}`;
    },
    deleteItems: '/bookmarks',
    addItem: '/bookmarks',
    addCommentary(requestParams: { id: string }) {
      return `/bookmarks/${requestParams.id}`;
    },
    deleteItem(requestParams: { id: string }) {
      return `/bookmarks/${requestParams.id}`;
    },
    download: '/download/bookmarks',
  },
  notifications: {
    get(page = 1) {
      return `/notifications/${page}`;
    },
    setVisited(id: Id) {
      return `/notifications/${id}`;
    },
  },
  download(requestParams: { source: Source; id: number | string; format?: Format; downloadType?: DownloadType }) {
    if (requestParams.source === Source.regulationProject) {
      return `download/${Source.regulationProject}/${requestParams.id}`;
    }

    if (requestParams.source === Source.regulationFolder) {
      if (requestParams.downloadType === DownloadType.all) {
        return `download/${Source.regulationProject}/folder/${requestParams.id}`;
      }

      return `download/${Source.regulationProject}/folder/${requestParams.id}/${requestParams.downloadType}`;
    }

    const source = requestParams.source === Source.authorPackage ? Source.authorPackage2 : requestParams.source;

    return `/download/${source}/${requestParams.id}/${requestParams.format}`;
  },
  payment: '/payment/presets',
  user: {
    login: '/user/login',
    recovery: '/user/recovery',
    profile: '/user/profile',
    token: '/user/tokens',
    paymentStatus: '/bootstrap/payment',
    createPayment: '/payment/create',
    passwordResetCodeVerification(requestParams: { code: string }) {
      return `/user/recovery/password/${requestParams.code}`;
    },
    saveFirstname: '/user/firstname',
    saveLastname: '/user/lastname',
    savePosition: '/user/position',
    avatar: '/user/avatar',
    logo: '/user/logo',
    configuration: {
      base: 'user/configuration',
      pinnedilters: 'user/configuration/pinned_filters',
    },
  },
  chronology: {
    documents(requestParams: { documentId: DocumentId, pageNumber: PageNumber }) {
      return `/case_history/${requestParams.documentId}/list/${requestParams.pageNumber}`;
    },
    getDocument(requestParams: { documentId: DocumentId, parentDocumentId: DocumentId }) {
      return `/document_standalone/history/${requestParams.parentDocumentId}/document/${requestParams.documentId}/view`;
    },
    download(requestParams: { documentId: DocumentId, chronologyDocumentId: DocumentId, format: Format }) {
      // eslint-disable-next-line max-len
      return `/document_standalone/history/${requestParams.documentId}/document/${requestParams.chronologyDocumentId}/${requestParams.format}`;
    },
  },
  similar: {
    documents(requestParams: { documentId: DocumentId, pageNumber: PageNumber }) {
      return `/similar/${requestParams.documentId}/list/${requestParams.pageNumber}`;
    },
    getDocument(requestParams: { documentId: DocumentId }) {
      return `/document_standalone/similar/${requestParams.documentId}/view`;
    },
    download(requestParams: { similarDocumentId: DocumentId, format: Format }) {
      // eslint-disable-next-line max-len
      return `/document_standalone/similar/${requestParams.similarDocumentId}/${requestParams.format}`;
    },
  },
  document: {
    // eslint-disable-next-line max-len
    getBody(requestParams: { source: Source.search | Source.package | Source.subscription | Source.authorPackage, sourceId: Id, documentId: DocumentId }) {
      const source = requestParams.source === Source.authorPackage ? 'authorpackages2' : requestParams.source;

      return `/${source}/${requestParams.sourceId}/documents/view/${requestParams.documentId}`;
    },
    getEntities(requestParams: { source: Source, sourceId: Id, documentId: DocumentId }) {
      let source = requestParams.source;
      if (requestParams.source === Source.authorPackage) {
        source = <Source>'authorpackages2';
      }

      return `/${source}/${requestParams.sourceId}/documents/${requestParams.documentId}/entities`;
    },
    getReason(requestParams: { documentId: Id }) {
      return `/document_standalone/reasons/${requestParams.documentId}`;
    },
    saveEntities(requestParams: { source: Source, sourceId: Id, documentId: DocumentId }) {
      return RESTPath.document.getEntities(requestParams);
    },
    saveCommentary(requestParams: { documentId: DocumentId, source?: Source, sourceId?: Id }) {
      if (requestParams.source === Source.package) {
        return `/${requestParams.source}/${requestParams.sourceId}/document/${requestParams.documentId}/comment`;
      }

      return `/document/${requestParams.documentId}/comment`;

    },
    getRelations(requestParams: { documentId: DocumentId }) {
      return `document/${requestParams.documentId}/relations`;
    },
    getRelation(requestParams: { relationId: RelationId }) {
      return `/relations/${requestParams.relationId}`;
    },
    getAnnotation(requestParams: { documentId: DocumentId }) {
      return `/document_standalone/annotation/${requestParams.documentId}/view`;
    },
    getSummary(requestParams: { documentId: DocumentId }) {
      return `/document_standalone/summary/${requestParams.documentId}/view`;
    },
    summaryRating(requestParams: { documentId: DocumentId }) {
      return `/document_standalone/rating/summary/${requestParams.documentId}`;
    },
    annotationRating(requestParams: { documentId: DocumentId }) {
      return `/document_standalone/rating/annotation/${requestParams.documentId}`;
    },
    getAttributes(requestParams: { documentId: DocumentId, sourceId: Id, source: Source, parentDocumentId?: number }) {
      const path = `document/${requestParams.documentId}/information`;

      const isAuthorDocument = requestParams.source === Source.authorPackage && !requestParams.parentDocumentId;
      if (isAuthorDocument) {
        return `${path}?authorpackageId=${requestParams.sourceId}`;
      }

      const isPackageDocument = requestParams.source === Source.package;
      if (isPackageDocument) {
        return `${path}?packageId=${requestParams.sourceId}`;
      }

      return path;
    },
    download(requestParams: { source: string, sourceId: Id, documentId: DocumentId, format: string }) {
      // eslint-disable-next-line max-len
      const base = requestParams.source === Source.authorPackage ? `/${Source.authorPackage2}` : `/${requestParams.source}`;

      let path = `${base}/${requestParams.sourceId}/documents/${requestParams.documentId}/download`;

      if (requestParams.format === DownloadFormat.original) {
        path = `${path}/pdf`;
      }

      if (requestParams.format === DownloadFormat.withMarkers) {
        path = `${path}/pdf?markers=1`;
      }

      if (requestParams.format === DownloadFormat.docx) {
        path = `${path}/docx`;
      }

      return path;
    },
    getSharedLink(requestParams: { source?: string, id: Id, documentId: DocumentId }) {
      let baseUrl = '';
      if (!requestParams.source) {
        baseUrl = 'search';
      } else {
        baseUrl = requestParams.source;
      }

      return `${baseUrl}/${requestParams.id}/documents/${requestParams.documentId}/share`;
    },
  },
  searchLawDocument: {
    getDocument(requestParams: { searchId: string; documentId: string; }) {
      return ` /laws/search/${requestParams.searchId}/law/${requestParams.documentId}`;
    },
  },
  lawDocument: {
    getBody(requestParams: FetchLawDocument) {
      const basePath = `/laws/${requestParams.documentId}`;

      const articleIdQuery = requestParams.articleId ? `articleId=${requestParams.articleId}` : null;
      const editionDateQuery = requestParams.editionDate ? `editionDate=${requestParams.editionDate}` : null;
      if (articleIdQuery && editionDateQuery) {
        return `${basePath}/?${articleIdQuery}&${editionDateQuery}`;
      }

      if (articleIdQuery) {
        return `${basePath}/?${articleIdQuery}`;
      }

      if (editionDateQuery) {
        return `${basePath}/?${editionDateQuery}`;
      }

      return basePath;
    },
    getEditions(requestParams: FetchLawDocumentEditions) {
      return `/laws/${requestParams.documentId}/editions`;
    },
  },
  searchLaw: {
    presets: '/bootstrap/law_search_conditions',
    create: '/laws/search',
    getFilters(id: Id) {
      return `/laws/search/${id}`;
    },
    getDocuments(requestParams: { id: Id, pageNumber: number }) {
      return `/laws/search/${requestParams.id}/${requestParams.pageNumber}`;
    },
  },
  search: {
    bootstrap: {
      documentType: '/bootstrap/document_type',
      caseType: '/bootstrap/case_type',
      courtType: '/bootstrap/court_type',
      caseResult: '/bootstrap/case_result',
      appealState: '/bootstrap/appeal_state',
      sideType: '/bootstrap/side_type',
      sideMode: '/bootstrap/side_mode',
      defaultFilters: '/bootstrap/searchFilters',
    },
    fromCbToken(token: string) {
      return `/search/cb?id=${token}`;
    },
    wizard: '/search/wizard_filters',
    create: '/search',
    result: {
      getDocuments(requestParams: { sourceId: Id, pageNumber: number }) {
        return `/search/${requestParams.sourceId}/list/${requestParams.pageNumber}`;
      },
      getPreview(id: Id, documentId: DocumentId) {
        return `/search/${id}/documents/preview/${documentId}`;
      },
      settings: '/search/documents/settings',
    },
    analytic: {
      courts: {
        getCourts({
          searchId,
          pageNumber = 1,
          perPage = 25,
          filterType,
          valueId,
          sortDirection,
        }: GetCourtsSettings) {
          return `/search/${searchId}/courts/${pageNumber}/${perPage}` +
            `/?sortBy=${filterType}.${valueId}&sortType=${sortDirection}`;
        },
        getJudges({
          searchId,
          courtId,
          filterType,
          valueId,
          sortDirection,
        }: GetJudgesSettings) {
          return `/search/${searchId}/courts/${courtId}/` +
            `judges?sortBy=${filterType}.${valueId}&sortType=${sortDirection}`;
        },
        settings: '/search/analytic/courts/settings',
      },
      documents: {
        getDocuments(
          { searchId,
            filterType,
            valueId,
            sortDirection }: GetDocumentsSettings,
        ) {
          return `/search/${searchId}/documents/?sortBy=${filterType}.${valueId}&sortType=${sortDirection}`;
        },
        getArticles(
          { searchId,
            documentId,
            filterType,
            valueId,
            sortDirection }: GetArticlesSettings,
        ) {
          return `/search/${searchId}/documents/${documentId}/` +
            `articles?sortBy=${filterType}.${valueId}&sortType=${sortDirection}`;
        },
        settings: '/search/analytic/documents/settings',
      },
      documentType: {
        getData(id: Id) {
          return `/search/${id}/analytic/type_id`;
        },
        settings: '/search/analytic/type_id/settings',
      },
      caseType: {
        getData(id: Id) {
          return `/search/${id}/analytic/case_type_id`;
        },
        settings: '/search/analytic/case_type_id/settings',
      },
      definitionContentType: {
        getData(id: Id, page = 1, perPage = 10) {
          return `/search/${id}/analytic/definition_content_type/${page}/${perPage}`;
        },
        settings: '/search/analytic/definition_content_type/settings',
      },
      disputeCategory: {
        getData(id: Id, page = 1, perPage = 10) {
          return `/search/${id}/analytic/category_dispute_id/${page}/${perPage}`;
        },
        settings: '/search/analytic/category_dispute_id/settings',
      },
      date: {
        getYear(id: Id) {
          return `/search/${id}/analytic/date_year`;
        },
        getMonth(searchID: Id, year: number) {
          return `/search/${searchID}/analytic/date_year/${year}`;
        },
        settings: '/search/analytic/date_year/settings',
      },
    },
    deleteHistoryItem(id: Id, requestParams: { page?: number, scope?: string, range?: string[] }) {

      const {
        page,
        scope,
        range,
      } = requestParams;

      const path = `/search/${id}?page=${page}&scope=${scope}`;

      if (scope !== 'range') {
        return path;
      }

      return `${path}&range[]=${range[0]}&range[]=${range[1]}`;
    },
    get(id: number) {
      return `/search/${id}`;
    },
    getHistory(requestParams: { page?: number, scope?: string, range?: string[] }) {

      const {
        page,
        scope,
        range,
      } = requestParams;

      const path = `/search?page=${page}&scope=${scope}`;

      if (scope !== 'range') {
        return path;
      }

      return `${path}&range[]=${range[0]}&range[]=${range[1]}`;
    },
  },
  companyPackage: {
    origin: 'companypackage',
    unpublish: 'companypackage/unpublish',
  },
  packages: {
    origin: '/package',
    bootstrap: {
      tags: '/bootstrap/tags/',
    },
    updateTitle(id: number) {
      return `/package/${id}/title`;
    },
    updateDescription(id: number) {
      return `/package/${id}/description`;
    },
    getPackages(requestParams: GetPackagesParams) {
      let path = 'package/list';

      const {
        query,
        pageNumber,
        documentId,
      } = requestParams;

      const hasParams = query || pageNumber || documentId;
      if (hasParams) {
        path = `${path}?`;
      }

      if (query) {
        path = `${path}query=${query}`;
      }

      if (pageNumber) {
        path = `${path}&page=${pageNumber}`;
      }

      if (documentId) {
        path = `${path}&document_id=${documentId}`;
      }

      return path;
    },
    createPackage(packageName: Query) {
      return `package?name=${packageName}`;
    },
    createPersonalPackage: 'personalpackage',
    deletePackage(requestParams: { packageId: Id }) {
      return `package/${requestParams.packageId}`;
    },
    addDocument(requestParams: AddDocument) {
      // eslint-disable-next-line max-len
      return `/package/${requestParams.packageId}/${requestParams.documentId}?${requestParams.source}_id=${requestParams.sourceId}`;
    },
    addDocuments(requestParams: { packageId: Id }) {
      return `/package/${requestParams.packageId}/documents`;
    },
    getPackageCount(requestParams: { source: Source.search | Source.package | Source.authorPackage, sourceId: Id }) {
      const source = requestParams.source === Source.authorPackage ? Source.authorPackage2 : requestParams.source;

      return `/${source}/${requestParams.sourceId}/list/1/packages_count`;
    },
    removeDocumentFromPackage(requestParams: DeleteDocumentFromPackage) {
      return `package/${requestParams.packageId}/${requestParams.documentId}`;
    },
    getDocuments(requestParams: { sourceId: Id, pageNumber: number, searchId?: Id }) {
      const basePath = `/package/${requestParams.sourceId}/list/${requestParams.pageNumber}`;

      if (!requestParams.searchId) {
        return basePath;
      }

      return `${basePath}/?search=${requestParams.searchId}`;
    },
    setTag(requestParams: { packageId: Id, documentId: Id }) {
      return `/package/${requestParams.packageId}/documents/view/${requestParams.documentId}/tag`;
    },
    getLink(requestParams: { packageId: Id }) {
      return `package/${requestParams.packageId}/share`;
    },
    setFilters(requestParams: { id: Id }) {
      return `/package/${requestParams.id}/search`;
    },
    document: {
      getPreview(requestParams: { id: Id, documentId: DocumentId }) {
        return `/package/${requestParams.id}/preview/${requestParams.documentId}`;
      },
    },
    settings: '/package/settings',
  },
  authorPackage: {
    main: '/authorPackage',
    unpublish: '/authorPackage/unpublish',
    getDocuments(requestParams: { sourceId: Id, pageNumber: number }) {
      return `/authorpackages2/${requestParams.sourceId}/list/${requestParams.pageNumber}`;
    },
  },
  authorPackages: '/authorpackages2',
  subscriptions: {
    origin: '/subscriptions',
    settings: '/subscriptions/settings',
    update(requestParams: { id: Id }) {
      return `/subscriptions/${requestParams.id}`;
    },
    delete(requestParams: { id: Id }) {
      return `/subscriptions/${requestParams.id}`;
    },
    get(requestParams: { query: Query; pageNumber: PageNumber }) {
      let path = 'subscriptions/list';

      const {
        query,
        pageNumber,
      } = requestParams;

      const hasParams = query || pageNumber;
      if (hasParams) {
        path = `${path}?`;
      }

      if (query) {
        path = `${path}query=${query}`;
      }

      if (pageNumber) {
        path = `${path}&page=${pageNumber}`;
      }

      return path;
    },
    getDocuments(requestParams: { sourceId: Id, pageNumber: number, searchId?: Id }) {
      const basePath = `/subscriptions/${requestParams.sourceId}/list/${requestParams.pageNumber}`;

      if (!requestParams.searchId) {
        return basePath;
      }

      return `${basePath}/?search=${requestParams.searchId}`;
    },
    getFilters(requestParams: { subscriptionId: Id }) {
      return `/subscriptions/${requestParams.subscriptionId}/filters`;
    },
    document: {
      getPreview(requestParams: { id: Id, documentId: DocumentId }) {
        return `/subscriptions/${requestParams.id}/preview/${requestParams.documentId}`;
      },
    },
    notifyAll: '/subscriptions/all/is_notify',
  },
  regulation: {
    toggleFamiliarize(projectId: Id) {
      return `/regulation/project/${projectId}/familiarize`;
    },
    calendar: {
      getCalendar(requestParams: { from: string, to: string }) {
        return `/regulation/calendar/dates?from=${requestParams.from}&to=${requestParams.to}`;
      },
      getEvents(requestParams: { date: string }) {
        return `/regulation/calendar/events?date=${requestParams.date}`;
      },
    },
    comments: {
      getComments() {
        return 'regulation/comments/has';
      },
      downloadComments() {
        return '/download/regulation/comments';
      },
    },
    chat: {
      comment: '/regulation/comment',
      users(query: string) {
        return `/autocomplete/user?query=${query}`;
      },
      messages(projectId: Id) {
        return `/regulation/project/${projectId}/comments`;
      },
    },
    settings: '/regulation/settings',
    filters: {
      work(requestParams: { id: Id }) {
        return `/regulation/filters/${requestParams.id}`;
      },
      origin: '/regulation/filters',
      projects: '/regulation/filters/projects',
      folder: '/regulation/filters/folder',
    },
    folders: {
      get(projectNumber?: string) {
        let path = '/regulation/folders';

        if (projectNumber) {
          path = `${path}/list?project_id=${projectNumber}`;
        }

        return path;
      },
      getFolder(folderId: string, pageNumber: number) {
        return `/regulation/folder/${folderId}/list/${pageNumber}`;
      },
      getCounter(folderId: string) {
        if (!folderId) {
          return '/regulation/folder/under_control/counter';
        }

        return `/regulation/folder/${folderId}/counter`;
      },
      inviteUsers(folderId: Id) {
        return `/regulation/folder/${folderId}/invite`;
      },
      changeRole(folderId: Id) {
        return `/regulation/folder/${folderId}/change_role`;
      },
      getUsers(folderId: Id) {
        return `/regulation/folder/${folderId}/roles`;
      },
      create() {
        return '/regulation/folder';
      },
      work(requestParams: { id: string }) {
        return `/regulation/folder/${requestParams.id}`;
      },
      addProject(folderId: string, projectNumber: string) {
        return `/regulation/folder/${folderId}/${projectNumber}`;
      },
      addProjects(folderId: Id) {
        return `/regulation/folder/${folderId}`;
      },
    },
    projects: {
      get(searchAfter: string) {
        if (!searchAfter) {
          return '/regulation/projects';
        }

        return `/regulation/projects?search_after=${searchAfter}`;
      },
      preview: {
        project(projectId: Id) {
          return `/regulation/project/${projectId}/law`;
        },
        note(projectId: Id) {
          return `/regulation/project/${projectId}/note`;
        },
        information(projectId: Id) {
          return `/regulation/project/${projectId}/information`;
        },
        phases(projectId: Id) {
          return `/regulation/project/${projectId}/phases`;
        },
        spectractors(folderId: Id, projectId: Id) {
          return `/regulation/folder/${folderId}/project/${projectId}/familiarized`;
        },
        attributes(projectId:Id) {
          return `/regulation/project/${projectId}/attributes`;
        },
      },
      getFolders(projectId: Id) {
        return `/regulation//folders/list?&project_id=${projectId}`;
      },
    },
  },
};

export default RESTPath;
