import http from '@http';

import {
  Id,
  RESTPath,
} from '@router/path.rest';

export interface Request {
  documentId: Id;
}

export function httpFetchReason(requestParams: Request) {
  const request = http.get({
    url: RESTPath.document.getReason(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchReason;
