import { addBlockReduce } from './addBlock.reduce';
import { SEARCH_LAW_FORM_BLOCK } from '../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_LAW_FORM_BLOCK}/ADD_BLOCK`;

const createAction = (data) => ({
  type: actionType,
  data,
});

export const addBlock = {
  actionType,
  createAction,
  reduce: addBlockReduce,
};

export default addBlock;
