import { fetchFiltersReduce } from './fetchFilters.reduce';

import type { ActionData } from './fetchFilters.reduce';

const actionType = 'SEARCH_LAW/RESULT/FETCH_CURRENT_SEARCH_FILTERS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchFilters = {
  actionType,
  createAction,
  reduce: fetchFiltersReduce,
};

export default fetchFilters;
