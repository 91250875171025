import React from 'react';
import s from './navigationPoint.style';

interface NavigationPointProps {
  items: number[];
  itemId: string;
  activePointId: number;
  onClick:(itemId: string, id: number) => void;
}

export const NavigationPoint = (props: NavigationPointProps) => {

  if (props.items.length <= 1) {
    return null;
  }

  const handleClick = (id) => (e) => {
    e.stopPropagation();

    props.onClick(props.itemId, id);
  };

  const renderPoint = () => props.items.map((id) => {
    const isActive = id === props.activePointId;

    return (
      <s.Point
        key={id}
        id={id}
        isActive={isActive}
        onClick={handleClick(id)}
      />
    );
  });

  return (
    <s.Root>
      {renderPoint()}
    </s.Root>
  );
};

export default NavigationPoint;
