import {
  styled,
  css,
} from '@components/styled';

interface Props {
  isActive: boolean;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-left: 5px;
`;

style.Point = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #6c7a89;
  transition: transform 0.2s ease;

  :hover {
    transform: scale(2);
  }

  ${(props: Props) => props.isActive && css`
    transform: scale(2);
    background-color: #4183d7;
  `}
`;

export default style;
