import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';
import { DocumentsConnect } from './documents.connect';

import { withFetchSearchLaw } from '../withFetchSearchLaw';

export const RouteSearchLawDocuments = (
  <AuthRouteConnect
    path={RoutePath.searchLawDocuments}
    component={withFetchSearchLaw(DocumentsConnect)}
    exact
  />
);

export default RouteSearchLawDocuments;
