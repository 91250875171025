import { Block as TextBlock } from '../filterBlocks/text/redux/block';
import { Block as TitleBlock } from '../filterBlocks/title/redux/block';
import { Block as LawBlock } from '../filterBlocks/law/redux/block';
import { Block as LawNumberBlock } from '../filterBlocks/lawNumber/redux/block';
import { Block as LawTypeBlock } from '../filterBlocks/lawType/redux/block';
import { Block as LawStatusBlock } from '../filterBlocks/lawStatus/redux/block';
import { Block as DepartmentTypeBlock } from '../filterBlocks/departmentType/redux/block';
import { Block as DateBlock } from '../filterBlocks/date/redux/block';
import { Block as PublicationDateBlock } from '../filterBlocks/publicationDate/redux/block';
import { Block as PublicationStatusBlock } from '../filterBlocks/publicationStatus/redux/block';

export type BlockType = typeof LawTypeBlock
                | typeof TitleBlock
                | typeof TextBlock
                | typeof LawBlock
                | typeof LawNumberBlock
                | typeof LawStatusBlock
                | typeof DepartmentTypeBlock
                | typeof DateBlock
                | typeof PublicationStatusBlock
                | typeof PublicationDateBlock;

export interface BlockProps {
  type: string
  block: BlockType
}

export const blocksTypes = {
  main: 'main',
};

export const blocks: BlockProps[] = [
  {
    type: blocksTypes.main,
    block: LawTypeBlock,
  },
  {
    type: blocksTypes.main,
    block: LawNumberBlock,
  },
  {
    type: blocksTypes.main,
    block: TitleBlock,
  },
  {
    type: blocksTypes.main,
    block: TextBlock,
  },
  {
    type: blocksTypes.main,
    block: LawBlock,
  },
  {
    type: blocksTypes.main,
    block: PublicationStatusBlock,
  },
  {
    type: blocksTypes.main,
    block: DateBlock,
  },
  {
    type: blocksTypes.main,
    block: PublicationDateBlock,
  },
  {
    type: blocksTypes.main,
    block: DepartmentTypeBlock,
  },
  {
    type: blocksTypes.main,
    block: LawStatusBlock,
  },
];

export const getBlocks = (type: string, data: BlockProps[]) => data.reduce((acc, block) => {
  if (block.type === type) {
    // @ts-ignore
    acc.push(block.block);
  }

  return acc;
}, []);
