import {
  fetchReasonReduce,
  ActionData,
} from './fetchReason.reduce';

const actionType = 'DOCUMENT/FETCH_REASON';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchReason = {
  actionType,
  createAction,
  reduce: fetchReasonReduce,
};

export default fetchReason;
