import { Dispatch } from 'redux';

import { httpFetchDocument } from './fetchDocument.http';

import { fetchDocument } from './fetchDocument';

import type { Response } from './fetchDocument.http';

export class FetchDocumentThunk {

  getState;

  private readonly dispatch: Dispatch;

  private documentId: string = null;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params) {
    const state = this.getState();
    const searchId = state.searchLawResult.id;

    this.documentId = params.documentId;

    const requestParams = {
      searchId,
      documentId: params.documentId,
    };

    await this.fetchDocument(requestParams);

  }

  private async fetchDocument(requestParams) {
    try {
      const result = await httpFetchDocument(requestParams);
      this.dispatchFetchDocumentSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchDocumentSucceed(response: Response) {
    const actionData = {
      documentId: this.documentId,
      document: response.body,
      attributes: {
        editionId: response.edition_id,
        edition: response.edition,
        date: response.date,
        publicationDate: response.publication_date,
        isActual: response.is_actual,
        title: response.title,
        type: response.type,
        departmentType: response.department_type,
        dfr: response.dfr[0],
        url: response.url,
        number: response.number,
        publications: response.publications,
      },
    };

    this.dispatch(fetchDocument.createAction(actionData));
  }

}

export async function fetchDocumentThunk(dispatch, params) {
  const thunk = new FetchDocumentThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default FetchDocumentThunk;
