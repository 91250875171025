import { Map } from 'immutable';

interface Item {
  id: string;
  title: string;
  width: string;
}

type ItemMap = Map<string, Item>;

export interface Column {
  items: string[];
  itemsMap: ItemMap;
}

export interface PresetItem {
  id: string;
  title: string;
}

type TableViewItemsMap = Map<string, PresetItem>;

export interface Layout {
  tableView: {
    columns: {
      itemsList: string[];
      itemsMap: TableViewItemsMap
    };
  }
}

export type Total = number;
export type Loading = boolean;

type DocumentId = number;

export interface Document {
  id: string;
  uniqueId,
  date: string;
  departmentType: string;
  isActual: boolean;
  number: string;
  title: string;
  type: string;
}

export interface Page {
  items: number[];
  itemsMap: Map<DocumentId, Document>
}

export interface State {
  totalDocuments: Total;
  totalPages: Total;
  loading: Loading;
  pageLoading: Loading;
  previewDocument: {
    currentPageNumber: number;
    documentId: number;
  };
  currentPageNumber: number;
  pages: Record<number, Page>;
}

export class StateBuilder {

  private readonly state: State;

  static createState(): State {
    return {
      previewDocument: {
        currentPageNumber: 1,
        documentId: null,
      },
      totalDocuments: null,
      totalPages: null,
      currentPageNumber: 1,
      pages: {},
      loading: true,
      pageLoading: true,
    };
  }

  constructor(state) {
    this.state = state;
  }

}

export default StateBuilder;
