import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchFilters } from '@pages/searchLawResult/redux/fetchFilters';

import { initial } from './state';

import { searchLawBootstrapped } from './searchLawBootstrapped';

import { reducerBuilder as form } from '../form/redux/reducers';

import { reducerBuilder as filter } from '../form/filterBlocks/redux/reducers';
import { reducerBuilder as law } from '../form/filterBlocks/law/redux/reducers';
import { reducerBuilder as lawType } from '../form/filterBlocks/lawType/redux/reducers';
import { reducerBuilder as lawStatus } from '../form/filterBlocks/lawStatus/redux/reducers';
import { reducerBuilder as lawNumber } from '../form/filterBlocks/lawNumber/redux/reducers';
import { reducerBuilder as text } from '../form/filterBlocks/text/redux/reducers';
import { reducerBuilder as title } from '../form/filterBlocks/title/redux/reducers';
import { reducerBuilder as departmentType } from '../form/filterBlocks/departmentType/redux/reducers';
import { reducerBuilder as date } from '../form/filterBlocks/date/redux/reducers';
import { reducerBuilder as publicationDate } from '../form/filterBlocks/publicationDate/redux/reducers';
import { reducerBuilder as publicationStatus } from '../form/filterBlocks/publicationStatus/redux/reducers';

export const reducerBuilder = new ReducerBuilder([
  searchLawBootstrapped,
  fetchFilters,
]);

reducerBuilder.merge(form);
reducerBuilder.merge(filter);
reducerBuilder.merge(law);
reducerBuilder.merge(lawType);
reducerBuilder.merge(lawStatus);
reducerBuilder.merge(lawNumber);
reducerBuilder.merge(text);
reducerBuilder.merge(title);
reducerBuilder.merge(departmentType);
reducerBuilder.merge(date);
reducerBuilder.merge(publicationDate);
reducerBuilder.merge(publicationStatus);

reducerBuilder.setInitialState(initial());

export default reducerBuilder;
