import React, { PureComponent } from 'react';

import { Button } from '@components/button';

import s from '@pages/search/form/block.style';

import { ProcessingStatus } from '../../searchResult/redux/state';

import { Block as FilterBlock } from './filterBlocks/block';
import { AddNewBlockConnect } from './addNewBlock/addNewBlock.connect';

interface BlockProps {
  resetButtonTitle?: string;
  saveButtonTitle?: string;
  onAddBlock: (block) => void;
  onDuplicateBlock: (block) => void;
  onRemoveBlock: (block) => void;
  onCreateSearch: (block) => void;
  onUnmount?(): void;
  onResetForm: () => void;
  items: any[];
  processingStatus?: string;
}

export class Block extends PureComponent<BlockProps> {

  componentWillUnmount() {
    if (!this.props.onUnmount) {
      return;
    }

    this.props.onUnmount();
  }

  renderFilterBlocks = () => this.props.items.map((item) => (
    <s.Item
      key={item.id}
    >
      <FilterBlock
        item={item}
        onDuplicateBlock={this.props.onDuplicateBlock}
        onRemoveBlock={this.props.onRemoveBlock}
      />
    </s.Item>
  ));

  renderFilters() {
    return (
      <s.FilterBlocksContainer>
        {this.renderFilterBlocks()}
      </s.FilterBlocksContainer>
    );
  }

  renderAddFilterButton() {
    return (
      <s.Button>
        <AddNewBlockConnect
          onAddBlock={this.props.onAddBlock}
        />
      </s.Button>
    );
  }

  renderResetButton() {
    const title = this.props.resetButtonTitle || 'Сбросить';

    return (
      <Button
        linkLike
        disabled={false}
        fullWidth={false}
        onClick={this.props.onResetForm}
      >
        {title}
      </Button>
    );
  }

  renderSaveButton() {
    const title = this.props.saveButtonTitle || 'Найти';
    const isProcessing = this.props.processingStatus === ProcessingStatus.processing;
    const hasNoItems = !!this.props.items.length;
    const isDisabled = isProcessing || !hasNoItems;

    return (
      <Button
        disabled={isDisabled}
        fullWidth={false}
        onClick={this.props.onCreateSearch}
      >
        {title}
      </Button>
    );
  }

  render() {
    return (
      <s.Form>
        <s.FiltersList>
          {this.renderFilters()}
        </s.FiltersList>
        <s.SearchControls>
          <s.Column
            verticalAlign="left"
          >
            {this.renderAddFilterButton()}
          </s.Column>
          <s.Column
            verticalAlign="right"
          >
            {this.renderResetButton()}
            {this.renderSaveButton()}
          </s.Column>
        </s.SearchControls>
      </s.Form>
    );
  }

}

export default Block;
