import { Dispatch } from 'redux';

import { fetchFilters } from './fetchFilters';

import { httpFetchFilters } from './fetchFilters.http';

export class FetchFiltersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(searchId: number) {
    if (!searchId) {
      return;
    }

    try {
      const response = await httpFetchFilters(searchId);
      this.dispatchFetchFiltersSucceed(response);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchFiltersSucceed(response) {
    const actionData = {
      ...response,
    };

    this.dispatch(fetchFilters.createAction(actionData));
  }

}

export function fetchFiltersThunk(dispatch, searchId) {
  const thunk = new FetchFiltersThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(searchId);
}

export default fetchFiltersThunk;
