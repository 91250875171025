export const hideReasonsReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    reasons: {
      ...state.reasons,
      showReasons: false,
    },
  };
};

export default hideReasonsReduce;
