import React from 'react';

import { DuplicateButton } from '@pages/search/form/components/filterControls/duplicateButton';
import { RemoveButton } from '@pages/search/form/components/filterControls/removeButton';

import s from '@pages/search/form/filterBlocks/block.style';

import { filterBlocksMap } from './filterBlocks.map';

export interface BlockProps {
  item: {
    getId: () => string;
    getType: () => Record<string, unknown>;
  };
  onDuplicateBlock: (data: { id: string }) => void;
  onRemoveBlock: (data: { id: string }) => void;
}

export const Block = (props: BlockProps) => {

  const Component = filterBlocksMap.get(props.item.getType().id).component;

  const handleDuplicateBlock = () => {
    const data = {
      id: props.item.getId(),
    };

    props.onDuplicateBlock(data);
  };

  const handleRemoveBlock = () => {
    const data = {
      id: props.item.getId(),
    };

    props.onRemoveBlock(data);
  };

  return (
    <s.Root>
      <DuplicateButton onClick={handleDuplicateBlock} />
      <Component {...props} />
      <RemoveButton onClick={handleRemoveBlock} />
    </s.Root>
  );
};

export default Block;
