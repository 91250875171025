import { updateModeReduce } from './updateMode.reduce';
import { SEARCH_LAW_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_LAW_FORM_BLOCK}/TEXT/UPDATE_MODE`;

export interface Data {
  mode: string;
}

export interface ActionData {
  id: string;
  data: Data;
}

export interface Action {
  type: typeof actionType;
  data: ActionData;
}

const createAction = (data: ActionData): Action => ({
  type: actionType,
  data,
});

export const updateMode = {
  actionType,
  createAction,
  reduce: updateModeReduce,
};

export default updateMode;
