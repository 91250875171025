import { Map } from 'immutable';

const prepareDocument = (document) => ({
  id: document.id,
  title: document.name,
  appealState: document.appeal_state,
  caseResult: document.case_result,
  hasArticles: document.has_articles,
  hasClauses: document.has_clauses,
  isActual: document.is_actual,
  link: document.link,
  articles: {
    shouldLoadArticles: true,
    items: [],
    itemsMap: Map(),
  },
});

const prepareDocuments = (fetchDocuments, currentDocuments) => fetchDocuments.reduce((accumulator, currentValue) => {
  accumulator.items = accumulator.items.concat(currentValue.id);
  accumulator.itemsMap = accumulator.itemsMap.set(currentValue.id, prepareDocument(currentValue));

  return accumulator;
}, currentDocuments);

export interface ActionData {
  searchId: string | number;
  result: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchLawsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const documents = action.data.result;

  const transformDocuments = prepareDocuments(documents, state.law.documents);

  return {
    ...state,
    id: action.data.searchId,
    law: {
      ...state.law,
      initialLoading: false,
      documents: {
        ...state.law.documents,
        ...transformDocuments,
        shouldLoadDocuments: false,
      },
    },
  };
};

export default fetchLawsReduce;
