import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

interface Props {
  active: boolean;
  theme: any;
}

style.Root = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 8px 0;
  background-color: ${(props: Props) => props.theme.colors.white};
  width: 100%;
  flex-direction: column;
`;

style.TabMore = styled.div`
  padding: 0 16px;
  font: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.colors.defaultText};
  position: relative;
  cursor: pointer;
  font-family: "Roboto-regular", sans-serif;

  ${(props: Props) => props.active && css`
    color: ${props.theme.colors.white};
    background-color: ${theme.colors.blue}
  `}
`;

export default style;
