import React from 'react';

import {
  BlockProps, blocksTypes, getBlocks,
} from './utils';

import s from './blocks.style';

interface BlocksProps {
  blocks: BlockProps[];
  // eslint-disable-next-line react/no-unused-prop-types
  onHandleItemSelect: (type: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  closePopover: () => void;
}

// @ts-ignore
const renderMainContainer = (props: BlocksProps) => {
  const blocks = getBlocks(blocksTypes.main, props.blocks);

  const renderItem = (block: { blockTitle: string, type: string }, index: number) => {
    const handleClick = () => {
      props.onHandleItemSelect(block.type);
      props.closePopover();
    };

    return (
      <s.Item
        key={index}
        onClick={handleClick}
      >
        {block.blockTitle}
      </s.Item>
    );
  };

  const renderFilterContent = () => (
    <s.Items>
      { blocks.map(renderItem) }
    </s.Items>
  );

  return renderFilterContent();
};

export const Blocks = (props: BlocksProps) => (
  <s.Root>
    {renderMainContainer(props)}
  </s.Root>
);

export default Blocks;
