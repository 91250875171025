import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';

import { httpFetchDocuments } from './fetchDocuments.http';
import { loadingEnd } from './loading';
import {
  pageLoadingStart, pageLoadingEnd,
} from './pageLoading';

export class FetchDocumentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(searchId: number, pageNumber?: number) {
    if (!searchId) {
      // eslint-disable-next-line no-param-reassign
      searchId = this.getState().searchLawResult.id;
    }

    if (!pageNumber) {
      // eslint-disable-next-line no-param-reassign
      pageNumber = this.getState().searchLawResult.documents.currentPageNumber;
    }

    sendAmplitudeData('go to page_search_law results', { page: pageNumber });
    Userpilot.track('go to page_search_law results', { page: pageNumber });

    // @ts-ignore
    const hasPage = this.getState().searchLawResult.documents.pages[pageNumber];
    if (hasPage) {
      this.dispatchChangePageNumber(pageNumber);

      return;
    }

    this.dispatch(pageLoadingStart.createAction());

    try {
      const response = await httpFetchDocuments({ id: searchId, pageNumber });
      this.dispatchGetDocumentsSucceed(searchId, pageNumber, response);
      this.dispatchChangePageNumber(pageNumber);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetDocumentsSucceed(sourceId, pageNumber, response) {
    const actionData = {
      sourceId,
      pageNumber,
      result: response,
    };

    this.dispatch(fetchDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber(pageNumber) {
    const actionData = {
      pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchDocumentsThunk(dispatch, searchId, pageNumber) {
  const thunk = new FetchDocumentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(searchId, pageNumber);
}

export default fetchDocumentsThunk;
