import React from 'react';

import s from './slider.style';

interface SliderProps {
  isShow: boolean;
  isEmpty?: boolean;
  onShow(): void;
}

export const Slider = (props: SliderProps) => {

  const showHighlight = props.isShow && !props.isEmpty;

  return (
    <s.Root>
      <s.Slider
        showHighlight={showHighlight}
        onClick={props.onShow}
      />
    </s.Root>
  );
};

export default Slider;
