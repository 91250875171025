import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { toggleReason } from './toggleReason';

import { fetchReasonThunk } from './fetchReason.thunk';

export class ToggleReasonThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke() {
    this.dispatch(toggleReason.createAction());

    const documentSearchPreviewId = this.getState().searchResult.documents.previewDocument.documentId;
    const documentSubscriptionPreviewId = this.getState().subscriptions.documents.previewDocument.documentId;
    const documentPackagesPreviewId = this.getState().packages.documents.previewDocument.documentId;
    const documentAuthorPackagesPreviewId = this.getState().authorPackages.documents.previewDocument.documentId;
    const openDocumentId = this.getState().document.id;
    const documentId = documentSearchPreviewId ||
                      documentSubscriptionPreviewId ||
                      documentPackagesPreviewId ||
                      documentAuthorPackagesPreviewId ||
                      openDocumentId;

    const isFetchReasonFail = this.getState().document.reasons.loadingSuccess;
    if (!isFetchReasonFail) {
      fetchReasonThunk(this.dispatch, { documentId });
    }

    sendAmplitudeData('switch reason', { documentId });
    Userpilot.track('switch reason', { documentId });
  }

}

export function toggleReasonThunk(dispatch) {
  const thunk = new ToggleReasonThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();
}

export default toggleReasonThunk;
