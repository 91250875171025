import React from 'react';

import s from './tabsMore.style';

type TabMoreId = string;

interface TabMoreI {
  id: TabMoreId;
  title: string;
}

const TabMore = (props: { tabMore: TabMoreI } & { activeId: TabMoreId; onClick(tabMoreId: string): void; }) => {
  const active = props.tabMore.id === props.activeId;

  const handleClick = () => {
    if (active) {
      return;
    }

    props.onClick(props.tabMore.id);
  };

  return (
    <s.TabMore
      key={props.tabMore.id}
      active={active}
      onClick={handleClick}
    >
      {props.tabMore.title}
    </s.TabMore>
  );
};

interface TabsMoreProps {
  tabsMore: TabMoreI[];
  onClick(tabId: string): void;
  activeId: TabMoreId;
}

export const TabsMore = (props: TabsMoreProps) => (
  <s.Root>
    {props.tabsMore.map((tabMore) => (
      <TabMore
        key={tabMore.id}
        activeId={props.activeId}
        tabMore={tabMore}
        onClick={props.onClick}
      />
    ))}
  </s.Root>
);

export default TabsMore;
