import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { httpFetchReason } from './fetchReason.http';

import { fetchReasonStart } from './fetchReasonStart';
import { hideReasons } from './hideReasons';
import { fetchReasonFail } from './fetchReasonFail';

import { fetchReason } from './fetchReason';

export interface RequestParams {
  documentId: number;
}

export class FetchReasonThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: RequestParams) {
    const {
      documentId,
    } = params;

    if (!documentId) {
      return;
    }

    this.dispatchFetchStart();
    await this.getReason(params);
  }

  private async getReason(params) {
    const reason = await this.fetchReason(params);

    this.dispatchFetchSucceed({ reason });
  }

  private async fetchReason(params) {
    try {
      const result = await httpFetchReason(params);
      const { claimant, defendant } = result.reasons;

      const isEmptyReasons = !claimant.length && !defendant.length;
      if (isEmptyReasons) {
        this.dispatchHide();
        this.showEmptyNotification();
      }

      return result;
    } catch (error) {
      this.errorHandler(error.status);
      this.dispatchFetchFail();

      throw Error(error.status);
    }
  }

  private dispatchFetchStart() {
    this.dispatch(fetchReasonStart.createAction());
  }

  private dispatchHide() {
    this.dispatch(hideReasons.createAction());
  }

  private dispatchFetchSucceed(result) {
    this.dispatch(fetchReason.createAction(result));
  }

  private dispatchFetchFail() {
    this.dispatch(fetchReasonFail.createAction());
  }

  private errorHandler(status) {
    if (status !== 403) {
      this.showFailNotification();
    }

    this.dispatchFetchFail();
  }

  private showEmptyNotification() {
    const notificationId = 'FETCH_REASON_EMPTY';
    notifierManager.information({ id: notificationId, message: 'Документ не содержит доводов' });
    setTimeout(() => {
      notifierManager.remove({ id: notificationId });
    }, 5000);
  }

  private showFailNotification() {
    const notificationFailId = 'FETCH_REASON_FAIL';
    notifierManager.fail({ id: notificationFailId, message: 'Не удалось выделить доводы' });
    setTimeout(() => {
      notifierManager.remove({ id: notificationFailId });
    }, 5000);
  }

}

export function fetchReasonThunk(dispatch, params) {
  const thunk = new FetchReasonThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchReasonThunk;
