import { fetchReasonStartReduce } from './fetchReasonStart.reduce';

const actionType = 'DOCUMENT/FETCH_REASON_START';

const createAction = () => ({
  type: actionType,
});

export const fetchReasonStart = {
  actionType,
  createAction,
  reduce: fetchReasonStartReduce,
};

export default fetchReasonStart;
