import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateValue } from './redux/updateValue';

import { Block } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateCaseNumber(data) {
    dispatch(updateValue.createAction(data));
  },
});

export const BlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default BlockConnect;
