import { searchLawBootstrappedReduce } from './searchLawBootstrapped.reduce';

const actionType = 'SEARCH_LAW/FORM/PRESETS/FETCH_SUCCEED';

const createAction = (data) => ({
  type: actionType,
  data,
});

export const searchLawBootstrapped = {
  actionType,
  createAction,
  reduce: searchLawBootstrappedReduce,
};

export default searchLawBootstrapped;
