import React from 'react';
import { LocationDescriptorObject as Location } from 'history';

import { Icon } from '@components/icons';
import { RoutePath } from '@router/path';

import {
  searchPath,
  subscriptionPath,
  myPackagesPath,
  authorPackagesPath,
  bookmarksPath,
  regulationPath,
} from './icons';

type CheckingActive = (_: any, location: Location) => boolean;

export interface MenuItem {
  id: number
  text: string
  link: string
  icon: JSX.Element
  isActive: CheckingActive
}

export const isActive = (regexp: RegExp, exceptionString = '') => (_: any, location: Location): boolean => {
  // @ts-ignore
  const path = location.pathname.split('/')[1];

  const source = regexp.source.replace('?', '');
  if (path === source) {
    return true;
  }

  const clearPath = path.replace(source, '');
  if (clearPath === exceptionString) {
    return false;
  }

  return regexp.test(path);
};

const BASE_COLOR = 'white';

export const menuItems: MenuItem[] = [
  {
    id: 1,
    text: 'Поиск',
    link: RoutePath.search,
    icon: <Icon path={searchPath} color={BASE_COLOR} />,
    isActive: isActive(/search|s_law/),
  },
  {
    id: 2,
    text: 'Подписки',
    link: RoutePath.subscriptions,
    icon: <Icon path={subscriptionPath} color={BASE_COLOR} />,
    isActive: isActive(/subscriptions?/),
  },
  {
    id: 3,
    text: 'Мои подборки',
    link: RoutePath.packages,
    icon: <Icon path={myPackagesPath} color={BASE_COLOR} />,
    isActive: isActive(/packages?/, 'author'),
  },
  {
    id: 4,
    text: 'Авторские подборки',
    link: RoutePath.authorPackages,
    icon: <Icon path={authorPackagesPath} color={BASE_COLOR} />,
    isActive: isActive(/authorpackages/),
  },
  {
    id: 5,
    text: 'Проекты НПА',
    link: RoutePath.regulations,
    icon: <Icon path={regulationPath} color={BASE_COLOR} />,
    isActive: isActive(/regulations?/),
  },
  {
    id: 6,
    text: 'Мои заметки',
    link: RoutePath.bookmarks,
    icon: <Icon path={bookmarksPath} color={BASE_COLOR} />,
    isActive: isActive(/bookmarks/),
  },
];

export default menuItems;
