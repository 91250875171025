import React from 'react';

import { Contains } from '@pages/search/form/components/contains/contains';
import { BlockType } from '@pages/search/form/components/blockType/blockType';
import { Field } from '@pages/search/form/components/field/field';

import s from '@pages/search/form/filterBlocks/block.style';

import { Block as ReduxBlock } from './redux/block';

import { Data as DataValue } from './redux/updateValue';
import { ActionData as DataMode } from './redux/updateMode';

import { Settings } from './settings';

export interface TextBlockProps {
  // eslint-disable-next-line react/no-unused-prop-types
  roles: any;
  item: ReduxBlock;
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeBlockType: (data: any) => void;
  onChangeContains: (data: any) => void;
  onUpdateProximityRange: (data: any) => void;
  onUpdateValue: (data: DataValue) => void;
  onUpdateMode: (data: DataMode) => void;
}

export class Block extends React.PureComponent<TextBlockProps> {

  handleChangeContains = (contains) => {
    const id = this.props.item.getId();
    const data = {
      id,
      contains,
    };

    this.props.onChangeContains(data);
  };

  handleChangeValue = (e) => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        value: e.target.value,
      },
    };

    this.props.onUpdateValue(data);
  };

  handleChangeMode = (mode) => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        mode,
      },
    };

    this.props.onUpdateMode(data);
  };

  handleChangeProximityRange = (proximityRange) => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        proximityRange,
      },
    };

    this.props.onUpdateProximityRange(data);
  };

  handleRemoveValue = () => {
    const id = this.props.item.getId();
    const data = {
      id,
      data: {
        value: '',
      },
    };

    this.props.onUpdateValue(data);
  };

  renderBlockType() {
    const type = this.props.item.getType();

    return (
      <s.FilterItem size="176px">
        <BlockType
          title={type.title}
        />
      </s.FilterItem>
    );
  }

  renderContains() {
    const contains = this.props.item.getContains();

    return (
      <s.FilterItem size="176px">
        <Contains
          contains={contains}
          onChange={this.handleChangeContains}
        />
      </s.FilterItem>
    );
  }

  renderText() {
    const data = this.props.item.getData();

    return (
      <s.FilterItem isGrow>
        <Field
          placeholder="Текст"
          value={data.value}
          onChange={this.handleChangeValue}
          onRemove={this.handleRemoveValue}
        />
      </s.FilterItem>
    );
  }

  renderSettings() {
    const data = this.props.item.getData();

    return (
      <s.FilterItem size="288px">
        <Settings
          onChangeMode={this.handleChangeMode}
          onUpdateProximityRange={this.handleChangeProximityRange}
          value={data.value}
          mode={data.mode}
          proximityRange={data.proximityRange}
        />
      </s.FilterItem>
    );
  }

  render() {
    const category = this.props.item.getCategory();

    return (
      <s.FilterBlock
        color={category.color}
      >
        {this.renderBlockType()}
        {this.renderContains()}
        {this.renderText()}
        {this.renderSettings()}
      </s.FilterBlock>
    );
  }

}

export default Block;
