import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import { Attributes } from '@pages/lawDocument/sidebar/attributes';

function formatDate(date) {
  if (!date) {
    return '';
  }

  return moment(date).format('DD.MM.yyyy');
}

function preparePublications(publications) {
  if (!publications.length) {
    return publications;
  }

  return publications.map((item) => ({
    id: uuid(),
    name: item,
  }));
}

const mapStateToProps = (state) => {
  const {
    loading,
    attributes,
  } = state.searchLawResult.document;

  const date = formatDate(attributes.date);
  const edition = formatDate(attributes.edition);
  const publicationDate = formatDate(attributes.publicationDate);
  const publications = preparePublications(attributes.publications);

  return {
    attributes: {
      ...attributes,
      date,
      edition,
      publications,
      publicationDate,
    },
    loading,
  };
};

export const AttributesConnect = connect(
  mapStateToProps,
)(Attributes);

export default AttributesConnect;
