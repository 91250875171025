import {
  styled,
  theme,
} from '@components/styled';

interface Props {
  color: string;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  width: 32px;
  height: 16px;
  font: 11px Roboto-Medium;
  color: ${theme.colors.white};
  line-height: 16px;
  padding-top: 2px;
  margin-left: 8px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  background-color: ${(props: Props) => props.color || theme.colors.orange};
`;

export default style;
