import { v4 as uuid } from 'uuid';

import moment from 'moment';
import { AbstractFilterBlock } from '../../abstract';
import { category } from '../../categories';

interface Scope {
  id: string;
  title: string;
  range: boolean;
}

type Value = string[];

export interface DateBlockData {
  value: Value;
  scope: Scope;
}

type Title =
  'За последний год' | 'За последние 2 года' | 'За последние 5 лет' | 'Конкретная дата' | 'Произвольный диапазон';

export enum Id {
  year = 'year',
  twoYears = 'twoYears',
  fiveYears = 'fiveYears',
  date = 'date',
  range = 'range',
}

type Ids = Id.year | Id.twoYears | Id.fiveYears | Id.date | Id.range;

enum DTOId {
  year = 'year',
  twoYears = 'two_years',
  fiveYears = 'five_years',
  date = 'date',
  range = 'range',
}

type DTOIds = DTOId.year | DTOId.twoYears | DTOId.fiveYears | DTOId.date | DTOId.range;

export interface DatePreset {
  id: Ids;
  DTOid: DTOIds;
  title: Title;
  range: boolean;
}

export class Block extends AbstractFilterBlock {

  static type = 'date';

  static DTOType = 'date';

  static blockTitle = 'Дата принятия';

  static category = category.main;

  static presets = [
    {
      id: Id.year,
      DTOid: DTOId.year,
      title: 'За последний год',
      range: true,
    },
    {
      id: Id.twoYears,
      DTOid: DTOId.twoYears,
      title: 'За последние 2 года',
      range: true,
    },
    {
      id: Id.fiveYears,
      DTOid: DTOId.fiveYears,
      title: 'За последние 5 лет',
      range: true,
    },
    {
      id: Id.date,
      DTOid: DTOId.date,
      title: 'Конкретная дата',
      range: false,
    },
    {
      id: Id.range,
      DTOid: DTOId.range,
      title: 'Произвольный диапазон',
      range: true,
    },
  ];

  protected defaultData: DateBlockData = {
    scope: Block.presets[0],
    value: ['', ''],
  };

  constructor() {
    super();

    this.setCategory(Block.category);
    this.setType({
      id: Block.type,
      DTOType: Block.DTOType,
      title: Block.blockTitle,
    });
  }

  public create(block) {
    this.setId(block.id);
    this.setData(block.data);
  }

  public createNewBlock() {
    const block = {
      id: uuid(),
      data: this.defaultData,
    };

    this.create(block);
  }

  public createFromScratch(ownId, data, contains, presets) {
    const id = ownId || uuid();

    if (!data) {
      throw Error('data is required');
    }

    const scope = presets.find((preset) => preset.DTOid === data.scope);

    const getDate = (stringOfDate: string) => new Date(moment(stringOfDate, 'YYYYMMDD').toDate());

    const firstValue = data.value[0] ? getDate(data.value[0]) : '';
    const secondValue = data.value[1] ? getDate(data.value[1]) : '';

    const value = [
      firstValue,
      secondValue,
    ];

    const updatedData = {
      ...data,
      value,
      scope,
    };

    const block = {
      id,
      data: updatedData,
    };

    this.create(block);
  }

  public decorate() {
    return {
      id: this.getId(),
      contains: null,
      data: this.getData(),
      DTOType: this.getType().DTOType,
    };
  }

  public undecorate() {
    const data = {
      scope: this.getData().scope.DTOid,
      value: [],
    };

    const formatString = 'YYYYMMDD';

    const isSingleDate = this.getData().scope.id === Block.presets[3].id;
    const isRange = this.getData().scope.id === Block.presets[4].id;

    if (!(isSingleDate || isRange)) {
      data.value = [];
    }

    if (isSingleDate || isRange) {
      const firstDate = moment(this.getData().value[0]);
      if (firstDate.isValid()) {
        // @ts-ignore
        data.value[0] = (firstDate.format(formatString));
      }
    }

    if (isRange) {
      const secondDate = moment(this.getData().value[1]);
      if (secondDate.isValid()) {
        // @ts-ignore
        data.value[1] = (secondDate.format(formatString));
      }
    }

    return {
      id: this.getId(),
      type: Block.DTOType,
      data,
    };
  }

  // eslint-disable-next-line
  public validate() { }

  getItemsForSuggest(): Promise<any> {
    throw new Error('Method not implemented.');
  }

}

export default Block;
