import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { BlocksMenu } from './blocksMenu';

import s from './addNewBlock.style';

const plusPoints = iconsPath.get('plus');

const InputComponent = () => (
  <s.Button
    linkLike
  >
    <Icon
      color="#4183d7"
      points={plusPoints}
    />
    <s.Text
      linkLike
    >
      Добавить фильтр
    </s.Text>
  </s.Button>
);

const ListComponent = (props: any) => (
  <BlocksMenu {...props} />
);

interface AddNewBlockProps {
  blocks: any[];
  onAddBlock: (blockType: any, type: any) => void;
}

export const AddNewBlock = (props: AddNewBlockProps) => (
  <BaseDropdown
    renderInput={() => <InputComponent />}
    renderMenu={(p) => <ListComponent blocks={props.blocks} onHandleItemSelect={props.onAddBlock} {...p} />}
  />
);

export default AddNewBlock;
