import React, { Component } from 'react';
import {
  Icon,
  iconsPath,
} from '@components/icons';

import type { Document } from '../redux/state';

import s from './list.style';

interface State {
  showPreview: boolean;
}

type Id = string | number;

interface Props {
  sourceId: string;
  item: Document;
  isActive: boolean;
  onItemClick: (id: Id, activeId: string, title?: string) => void;
}

export class Item extends Component<Props, State> {

  handleItemClick = () => {
    const title = this.prepareTitle();
    this.props.onItemClick(this.props.item.id, this.props.item.uniqueId, title);
  };

  handleTitleClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  private prepareTitle() {
    const { item } = this.props;

    const typePart = item.type ? `${item.type}` : '';
    const departmentPart = item.departmentType ? ` ${item.departmentType}` : '';
    const datePart = item.date ? ` от ${item.date}` : '';
    const numberPart = item.number ? ` № ${item.number}` : '';

    return `${typePart}${departmentPart}${datePart}${numberPart}`;
  }

  private prepareHref() {
    const { item } = this.props;

    return `#/s_law/${this.props.sourceId}/documents/${item.id}`;

  }

  renderTitle = () => {
    const href = this.prepareHref();
    const title = this.prepareTitle();

    return (
      <s.Title
        onClick={this.handleTitleClick}
        href={href}
        target="_blank"
      >
        {title}
      </s.Title>
    );
  };

  renderSharedIcon() {
    const iconPath = iconsPath.get('open');

    return (
      <s.Icon>
        <Icon path={iconPath} size={13} color="currentColor" />
      </s.Icon>
    );
  }

  renderNoActual() {
    if (this.props.item.isActual) {
      return null;
    }

    return (
      <s.ActualStatus>
        Утратил силу
      </s.ActualStatus>
    );
  }

  render() {
    const {
      item,
    } = this.props;

    return (
      <s.Item
        isActive={this.props.isActive}
        onClick={this.handleItemClick}
      >
        <s.CellDocument>
          <s.BodyCellContainer>
            <s.TitleLink>
              {this.renderTitle()}
              {this.renderNoActual()}
              {this.renderSharedIcon()}
            </s.TitleLink>
            <s.BodyText>
              {item.title}
            </s.BodyText>
          </s.BodyCellContainer>
        </s.CellDocument>
      </s.Item>
    );
  }

}

export default Item;
