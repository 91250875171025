export interface Type {
  id: string;
  title: string;
}

export const searchLawBootstrappedReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const presets = action.data;

  const lawType = presets.lawType.map(
    (d) => (
      { id: d.id, title: d.name }
    ),
  );

  const departmentType = presets.departmentType.map(
    (d) => (
      { id: d.id, title: d.name }
    ),
  );

  const lawStatus = presets.lawStatus.map(
    (d) => (
      { id: d.id, title: d.name }
    ),
  );

  const publicationStatus = presets.publicationStatus.map(
    (d) => (
      { id: d.id, title: d.name }
    ),
  );

  return {
    ...state,
    presets: {
      ...state.presets,
      lawType,
      departmentType,
      lawStatus,
      publicationStatus,
    },
  };
};

export default searchLawBootstrappedReduce;
