import { SEARCH_LAW_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateValueReduce } from './updateValue.reduce';

const actionType = `${SEARCH_LAW_FORM_BLOCK}/LAW_TYPE/UPDATE`;

export interface Data {
  id: string;
  data: {
    id: number;
    title: string;
  }
}

export interface Action {
  type: typeof actionType;
  data: Data;
}

const createAction = (data: Data): Action => ({
  type: actionType,
  data,
});

export const updateValue = {
  actionType,
  createAction,
  reduce: updateValueReduce,
};

export default updateValue;
