import { connect } from 'react-redux';

import { addBlock } from './redux/addBlock';
import { duplicateBlock } from './redux/duplicateBlock';
import { removeBlock } from './redux/removeBlock';
import { resetForm } from './redux/resetForm';
import { createSearchLawThunk } from './redux/createSearchLaw.thunk';

import { Block } from './block';

const mapStateToProps = (state) => {
  const { query } = state.searchLaw;
  const items = query.blocksList.map((item) => (
    query.blocksMap.get(item)
  ));

  return {
    items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddBlock(type) {
    dispatch(addBlock.createAction({ type }));
  },
  onDuplicateBlock(data) {
    dispatch(duplicateBlock.createAction(data));
  },
  onRemoveBlock(data) {
    dispatch(removeBlock.createAction(data));
  },
  onResetForm() {
    dispatch(resetForm.createAction());
  },
  onCreateSearch() {
    createSearchLawThunk(dispatch);
  },
});

export const BlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default BlockConnect;
