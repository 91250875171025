import { connect } from 'react-redux';

import { Source } from '@pages/document/redux/state';

import {
  isParagraphInBookmarks,
  isParagraphInReason,
} from '@pages/document/workspace/block.connect';

import { Block } from '@pages/document/workspace/block';

import { addBookmarkThunk } from '@layouts/bookmarks/redux/addBookmark.thunk';
import { addCommentaryThunk } from '@layouts/bookmarks/redux/addCommentary.thunk';
import { deleteBookmarkFromBufferThunk } from '@layouts/bookmarks/redux/deleteBookmark.thunk';

import { prepareReasonBlockIds } from '../utils';

const mapStateToProps = (state, ownProps) => {
  const { paragraphInBookmarks, commentary } = isParagraphInBookmarks(state.bookmarksBuffer.queue, ownProps);
  const { claimant, defendant } = state.document.reasons;
  const reasonIds = prepareReasonBlockIds(claimant, defendant);
  const paragraphInReasons = isParagraphInReason(reasonIds, ownProps).paragraphInReason;
  const activeReason = state.document.reasons.showReasons;

  return {
    paragraphInBookmarks,
    paragraphInReasons,
    commentary,
    isPreview: true,
    activeReason,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddBookmark(text) {
    const params = {
      paragraphId: Number(ownProps.block.id),
      text,
      title: ownProps.bookmarkTitle,
      source: ownProps.source,
      sourceId: Number(ownProps.sourceId),
      documentId: Number(ownProps.documentId),
      path: `${ownProps.source}/${ownProps.sourceId}/documents/${ownProps.documentId}`,
    };

    if (params.source === `${Source.authorPackage}s`) {
      params.source = Source.authorPackage;
    }

    addBookmarkThunk(dispatch, params);
  },
  onAddCommentary(commentary) {
    const params = {
      paragraphId: Number(ownProps.block.id),
      commentary,
    };

    addCommentaryThunk(dispatch, params);
  },
  onRemoveBookmark() {
    const params = {
      paragraphId: Number(ownProps.block.id),
    };

    deleteBookmarkFromBufferThunk(dispatch, params);
  },
});

export const DocumentBodyBlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default DocumentBodyBlockConnect;
