import { connect } from 'react-redux';

import { TooltipReasonsWithClickOutside } from './tooltipReasons';

interface Item {
  id: string;
  title: string;
  blockIds: number[]
}

export const tooltipReasonBuilder = (reasonItems: Item[], description: string, blockId: number) => {
  const results = reasonItems.filter((item) => item.blockIds.includes(blockId));

  if (!results.length) {
    return null;
  }

  const titles = results.map((item) => item.title);

  return {
    description,
    titles,
  };
};

const mapStateToProps = (state, ownProps) => {
  const { claimant, defendant } = state.document.reasons;
  const claimantItems = claimant.itemsList.map((id) => claimant.itemsMap.get(id));
  const defendantItems = defendant.itemsList.map((id) => defendant.itemsMap.get(id));
  const descriptionClaimant = 'Доводы истца';
  const descriptionDefendant = 'Доводы ответчика';

  const reasonClaimant = tooltipReasonBuilder(claimantItems, descriptionClaimant, Number(ownProps.tooltipIdReason));
  const reasonDefendant = tooltipReasonBuilder(defendantItems, descriptionDefendant, Number(ownProps.tooltipIdReason));

  return {
    reasonClaimant,
    reasonDefendant,
  };
};

export const TooltipReasonWithClickConnect = connect(
  mapStateToProps,
)(TooltipReasonsWithClickOutside);

export default TooltipReasonWithClickConnect;
