import React from 'react';

import { DocumentId } from '../../redux/state';

import s from './list.style';

import { ItemConnect } from './item.connect';

interface Props {
  items: DocumentId[],
  documentPreviewId: number;
  onItemClick(id: DocumentId, activeId: string): void;
}

export class List extends React.PureComponent<Props> {

  renderItem = (item) => (
    <ItemConnect
      key={item}
      id={item}
      isActive={this.props.documentPreviewId === item}
      onItemClick={this.props.onItemClick}
    />
  );

  render() {
    return (
      <s.Root>
        <s.Table>
          <s.Group>
            <s.ColumnDocument />
          </s.Group>

          <s.Header>
            <s.CellDocument>
              <s.ColumnWidthDocument>
                <s.CellContainer>
                  <s.HeaderText>Документ</s.HeaderText>
                </s.CellContainer>
              </s.ColumnWidthDocument>
            </s.CellDocument>
          </s.Header>

          <s.Body>{this.props.items.map(this.renderItem)}</s.Body>
        </s.Table>
      </s.Root>
    );
  }

}

export default List;
