import { connect } from 'react-redux';

import { Editions } from './editions';

function buildItems(params) {
  return params.items.map((item) => {
    const isActive = item.id === params.activeId;

    return {
      ...item,
      isActive,
    };
  });
}

const mapStateToProps = (state) => ({
  items: buildItems({ items: state.lawDocument.editions.items, activeId: state.lawDocument.attributes.edition }),
  loading: state.lawDocument.editions.loading,
});

export const EditionsConnect = connect(
  mapStateToProps,
)(Editions);

export default EditionsConnect;
