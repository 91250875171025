import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  theme: Record<string, Record<string, string>>;
  disabled: boolean;
  isEmptyIds: boolean;
  emptyReasons: boolean;
  isUserGuest: boolean;
  shouldDisabled: boolean;
}

style.Root = styled.div`
  z-index: 3;

  ${(props: Props) => props.isUserGuest && css`
    display: none;
  `}
`;

style.Navigation = styled.div`
  display: flex;
  flex-direction: row;
  width: 217px;
  height: 40px;
  border-radius: 20px;
  background: rgba(34, 49, 63, 0.9);
  align-items: center;
  padding: 0 0 0 12px;
  user-select: none;
  position: relative;

  ::before {
    background: rgba(85, 98, 112, 0.9);
    pointer-events: none;
    content: 'Функция находится в тестовом режиме. По окончании тестирования доступ может быть ограничен.';
    text-align: center;
    display: flex;
    align-items: center;
    border-radius: 2px;
    height: 40px;
    width: 304px;
    position: absolute;
    top: -44px;
    left: -43px;
    right: 0;
    opacity: 0;
    transition: .5s;
    font-size: 11px;
    color: white;
  }

  &:hover::before {
    opacity: 1;
    background: rgba(85, 98, 112, 0.9);
  }

  ${(props: Props) => props.emptyReasons && css`

    & > div:first-child > span {
      opacity: 0.2;
      pointer-events: none;
    }

  `}
`;

style.Beta = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props: Props) => props.theme.colors.white};
  font-size: 13px;
  font-family: "Roboto-Regular", Helvetica, Arial, sans-serif;

  ${(props: Props) => props.isEmptyIds && css`
    color: ${props.theme.colors.disabled};
    opacity: 0.35;
  `}
`;

style.Title = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props: Props) => props.theme.colors.white};
  font-size: 13px;
  font-family: "Roboto-Regular", Helvetica, Arial, sans-serif;
  padding: 0 8px 0 8px;

  ${(props: Props) => props.isEmptyIds && css`
    color: ${props.theme.colors.disabled};
    opacity: 0.35;
  `}
`;

style.Actions = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 6px;
`;

style.Loader = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;

  & div {
    margin: 0;
    position: static;
    border: 2px solid transparent;
    border-color: ${(props) => `${props.theme.colors.globalLines} 
                                ${props.theme.colors.globalLines} 
                                rgba(34, 49, 63, 0)`};
  }
`;

style.Action = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 6px;

  &:last-of-type {
    padding: 6px 5px;
  }

  ${(props: Props) => props.disabled && css`
    i {
      opacity: 0.2;
    }
  `}

  ${(props: Props) => props.shouldDisabled && css`
    pointer-events: none;

    i {
      opacity: 0.2;
      color: ${props.theme.colors.disabled};
    }
  `}
`;

export default style;
