import { Filters } from '../../../filters';
import { Block } from './block';

export const updateFirstDateReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);

  const block = state.query.blocksMap.get(action.data.id);

  let newData;
  if (block.getData().scope.id === Block.presets[3].id) {
    newData = {
      id: action.data.id,
      data: {
        value: [action.data.data.value],
      },
    };
  }

  if (block.getData().scope.id === Block.presets[4].id) {
    newData = {
      id: action.data.id,
      data: {
        value: [action.data.data.value, block.getData().value[1]],
      },
    };
  }

  const blocksMap = filters.updateData(newData);

  return {
    ...state,
    query: {
      ...state.query,
      blocksMap,
    },
  };
};

export default updateFirstDateReduce;
