import React from 'react';

import { TabBarItem } from './tabBarItem';

import s from './tabBar.style';

import type { ItemId } from './tabBarItem';

interface TabBarProps {
  activeId: string;
  renderNavigation: boolean;
  tabs: any[];
  onTabClick: (id: ItemId) => void;
}

export const TabBar = (props: TabBarProps) => {
  if (!props.renderNavigation) {
    return null;
  }

  const renderTabs = () => props.tabs.map((tab) => (
    <TabBarItem
      activeId={props.activeId}
      key={tab.id}
      id={tab.id}
      title={tab.title}
      onClick={props.onTabClick}
    />
  ));

  return (
    <s.TabBar>
      {renderTabs()}
    </s.TabBar>
  );
};

export default TabBar;
