import { SEARCH_LAW_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateSecondDateReduce } from './updateSecondDate.reduce';

const actionType = `${SEARCH_LAW_FORM_BLOCK}/DATE/UPDATE_SECOND_DATE`;

export interface Data {
  value: Date;
}

export interface ActionData {
  id: string;
  data: Data;
}

export interface Action {
  type: typeof actionType;
  data: ActionData;
}

const createAction = (data: ActionData): Action => ({
  type: actionType,
  data,
});

export const updateSecondDate = {
  actionType,
  createAction,
  reduce: updateSecondDateReduce,
};

export default updateSecondDate;
