import { Filters } from '../../../filters';

export const updateValueReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const block = state.query.blocksMap.get(action.data.id);

  if (block.getData().value === action.data.value) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);

  const newData = {
    id: action.data.id,
    data: {
      value: action.data.data.value,
    },
  };

  const blocksMap = filters.updateData(newData);

  return {
    ...state,
    query: {
      ...state.query,
      blocksMap,
    },
  };
};

export default updateValueReduce;
