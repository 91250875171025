import { Map } from 'immutable';

const prepareItem = (item) => ({
  id: item.id,
  title: item.name,
  type: item.type,
  link: item.link,
  appealState: item.appeal_state,
  caseResult: item.case_result,
  hasClauses: item.has_clauses,
});

const prepareItems = (fetchItems) => fetchItems.map(prepareItem);

const convertItems = (fetchItems) => fetchItems.reduce((accumulator, currentValue) => {
  accumulator.items = accumulator.items.concat(currentValue.id);
  accumulator.itemsMap = accumulator.itemsMap.set(currentValue.id, currentValue);

  return accumulator;
}, { items: [], itemsMap: Map() });

const updateDocument = (itemsMap, data) => {
  const articles = prepareItems(data.articles);
  const transformItems = convertItems(articles);

  const document = itemsMap.get(data.documentId);

  const newDocument = {
    ...document,
    articles: {
      shouldLoadArticles: false,
      ...transformItems,
    },
  };

  return itemsMap.set(data.documentId, newDocument);
};

export interface ActionData {
  documentId: string | number;
  result: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchArticlesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const currentLawsMap = state.law.documents.itemsMap;

  const data = {
    documentId: action.data.documentId,
    articles: action.data.result,
  };

  const itemsMap = updateDocument(currentLawsMap, data);

  return {
    ...state,
    law: {
      ...state.law,
      documents: {
        ...state.law.documents,
        itemsMap,
      },
    },
  };
};

export default fetchArticlesReduce;
