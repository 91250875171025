import { StateBuilder } from './state';

type Id = number;

interface Item {
  title: string;
  blockIds: Id[];
}

export interface ActionData {
  reason: {
    reasons: {
      claimant: Item[];
      defendant: Item[];
    };
  }
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchReasonReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const { claimant, defendant } = action.data.reason.reasons;

  const newState = new StateBuilder(state);
  newState.resetReasons();
  const claimantItems = newState.addItemsReason(claimant);
  const defendantItems = newState.addItemsReason(defendant);

  return {
    ...state,
    reasons: {
      ...state.reasons,
      claimant: claimantItems,
      defendant: defendantItems,
      loading: false,
    },
  };
};

export default fetchReasonReduce;
