import { changePreviewDocumentReduce } from './changePreviewDocument.reduce';

const actionType = 'SEARCH_LAW/RESULT/DOCUMENT_PREVIEW__CHANGE_PARAMS';

const createAction = (data: { documentId: number }) => ({
  type: actionType,
  data,
});

export const changePreviewDocument = {
  actionType,
  createAction,
  reduce: changePreviewDocumentReduce,
};

export default changePreviewDocument;
