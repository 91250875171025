import { Filters } from '../../../filters';
import { Block } from './block';

export const updateScopeReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const block = state.query.blocksMap.get(action.data.id);

  if (block.getData().scope === action.data.data) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);

  let newData;

  const isSimple = action.data.data.id === Block.presets[0].id ||
    action.data.data.id === Block.presets[1].id ||
    action.data.data.id === Block.presets[2].id;

  if (isSimple) {
    newData = {
      id: action.data.id,
      data: {
        scope: action.data.data,
        value: [],
      },
    };
  }

  if (action.data.data.id === Block.presets[3].id) {
    newData = {
      id: action.data.id,
      data: {
        scope: action.data.data,
        value: [new Date()],
      },
    };
  }

  if (action.data.data.id === Block.presets[4].id) {
    newData = {
      id: action.data.id,
      data: {
        scope: action.data.data,
        value: [new Date(), new Date()],
      },
    };
  }

  const blocksMap = filters.updateData(newData);

  return {
    ...state,
    query: {
      ...state.query,
      blocksMap,
    },
  };
};

export default updateScopeReduce;
