import styled, { css } from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
  active: boolean;
}

style.Root = styled.div`
text-transform: uppercase;
position: relative;
font: 13px Roboto-Medium;
height: 47px;
display: flex;
margin: 0 8px;
align-items: center;
color: ${(props) => props.theme.colors.stone};
font-family: "Roboto-Medium", Helvetica, Arial, sans-serif;

::after {
  ${(props: Props) => props.active && css`
    content: '';
    background: ${props.theme.colors.orange};
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
  `}
}
`;

style.InputBlock = styled.div`

  transition: color 0.2s ease;

  ${(props: Props) => props.active && css`
    color: ${props.theme.colors.defaultText};
  `}

  &:hover {
    color: ${(props: Props) => props.theme.colors.defaultText};
  }
`;

style.MenuBloks = styled.div`
  width: 192px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 17px;
  right: 0px;
`;

export default style;
