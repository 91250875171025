import { Category } from './categories';

export type Id = string;
export type Data = Record<string, any>;
type Contains = boolean;

export interface Type {
  DTOType: string;
  id: string;
  title: string;
}

export interface PresetsProps {
  id: number;
  title: string;
}

interface Abstract {
  create: (block: any) => void;
  createNewBlock: () => void;
  createFromScratch: (
    id: Id, data: Data, contains: Contains, presets: PresetsProps
  ) => void;
  getItemsForSuggest?: (query: string) => Promise<any>,
  undecorate: () => void;
  decorate: () => void;
  validate: () => void;
}

export abstract class AbstractFilterBlock implements Abstract {

  private id: Id;

  private type: Type;

  private data: Data;

  private contains: Contains;

  private category: Category;

  constructor() {
    if (new.target === AbstractFilterBlock) {
      throw new TypeError('Cannot construct Abstract instances directly');
    }

    // @ts-ignore
    this.id = null;
    // @ts-ignore
    this.type = null;
    // @ts-ignore
    this.data = null;
    // @ts-ignore
    this.contains = null;
    // @ts-ignore
    this.category = null;
  }

  protected setId(id: Id): void {
    const isCorrectType = typeof (id) === 'string';
    if (!isCorrectType) {
      throw Error('Incorrect filter id value. Expect type string');
    }

    this.id = id;
  }

  getId = (): Id => this.id;

  protected setType(type: Type): void {
    const isCorrectType = typeof (type) === 'object';
    if (!isCorrectType) {
      throw Error('Incorrect filter type value. Expect type string');
    }

    this.type = type;
  }

  public getType = (): Type => this.type;

  protected setContains(contains: Contains): void {
    const isCorrectType = typeof (contains) === 'boolean';
    if (!isCorrectType) {
      throw Error('Incorrect filter contains value. Expect type boolean');
    }

    this.contains = contains;
  }

  getContains = (): Contains => this.contains;

  protected setCategory(category: Category): void {
    this.category = category;
  }

  getCategory = (): Category => this.category;

  protected setData(data: Data): void {
    this.data = { ...data };
  }

  getData = (): Data => this.data;

  abstract create(block: any): void;

  abstract createNewBlock(): void;

  abstract createFromScratch(
    id: Id, data: Data, contains: Contains, presets: PresetsProps
  ): void;

  abstract getItemsForSuggest(query: string): Promise<any>;

  abstract decorate(): void;

  abstract undecorate(): void;

  abstract validate(): void;

}

export default AbstractFilterBlock;
