import { fetchDocumentsReduce } from './fetchDocuments.reduce';

import type { ActionData } from './fetchDocuments.reduce';

const actionType = 'SEARCH_LAW/RESULT/FETCH_DOCUMENTS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchDocuments = {
  actionType,
  createAction,
  reduce: fetchDocumentsReduce,
};

export default fetchDocuments;
