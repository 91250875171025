import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 300px;
`;

style.FilterTitle = styled.div`
  text-transform: uppercase;
  font: 11px Roboto-Medium, sans-serif;
  color: rgb(166, 176, 186);
  line-height: 16px;
`;

style.Items = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  width: 100%;
  flex-wrap: wrap;
`;

style.Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  line-height: 16px;

  position: relative;

  color: ${(props) => props.theme.colors.defaultText};

  cursor: pointer;

  &:hover {
    background: #f6fafe;
  }
`;

export default style;
