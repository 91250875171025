import { connect } from 'react-redux';

import { fetchFiltersThunk } from '../../redux/fetchFilters.thunk';

import { SearchDrawer } from './searchDrawer';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMount() {
    fetchFiltersThunk(dispatch, ownProps.id);
  },
  onClose() {
    fetchFiltersThunk(dispatch, ownProps.id);
  },
});

export const SearchDrawerConnect = connect(
  null,
  mapDispatchToProps,
)(SearchDrawer);

export default SearchDrawerConnect;
