import { SEARCH_LAW_FORM_BLOCK } from '../../../middleware/resetActiveItemMiddleware';
import { updateScopeReduce } from './updateScope.reduce';

const actionType = `${SEARCH_LAW_FORM_BLOCK}/PUBLICATION_DATE/UPDATE_SCOPE`;

export interface Data {
  id: string;
  title: string;
  range: boolean;
}

export interface ActionData {
  id: string;
  data: Data;
}

export interface Action {
  type: typeof actionType;
  data: ActionData;
}

const createAction = (data: ActionData): Action => ({
  type: actionType,
  data,
});

export const updateScope = {
  actionType,
  createAction,
  reduce: updateScopeReduce,
};

export default updateScope;
