import { css } from 'styled-components';
import { styled } from '@components/styled';

interface Props {
  theme: any;
  disabled: boolean;
}

const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const style: any = {};

style.BaseInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  padding-left: 16px;
  padding-top: 0;
`;

style.Value = styled.div`
  width: 100%;

  color: ${(props) => props.theme.colors.defaultText};
  font-size: 13px;
  line-height: 1.23;

  ${ellipsis}
  ${(props: Props) => props.disabled && css`
    color: ${props.theme.colors.silver};
  `}
`;

export default style;
