import { hideReasonsReduce } from './hideReasons.reduce';

const actionType = 'DOCUMENT/HIDE_REASONS';

const createAction = () => ({
  type: actionType,
});

export const hideReasons = {
  actionType,
  createAction,
  reduce: hideReasonsReduce,
};

export default hideReasons;
