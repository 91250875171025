import React from 'react';

import { AuthRouteConnect } from '@router/authRoute.connect';
import { RoutePath } from '@router/path';
import { DocumentConnect } from './document.connect';

import { withFetchSearchLaw } from '../withFetchSearchLaw';

export const RouteSearchLawDocument = (
  <AuthRouteConnect
    path={RoutePath.searchLawDocument}
    component={withFetchSearchLaw(DocumentConnect)}
    exact
  />
);

export default RouteSearchLawDocument;
