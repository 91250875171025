import { connect } from 'react-redux';

import { AttributesConnect } from '@pages/lawDocument/sidebar/attributes.connect';
import { TableOfContentsConnect } from './tableOfContents.connect';

import { EditionsConnect } from './editions.connect';

import { Sidebar } from './sidebar';

import { setActiveTab } from './redux/setActiveTab';

import { TabId } from '../redux/state';

const mapStateToProps = (state) => {
  const tabMap = new Map();
  tabMap.set(TabId.attributes, AttributesConnect);
  tabMap.set(TabId.tableOfContents, TableOfContentsConnect);
  tabMap.set(TabId.editions, EditionsConnect);

  const { activeTabId } = state.lawDocument.layout.sidebar;

  const tabs = [
    {
      id: TabId.tableOfContents,
      title: 'Оглавление',
    },
    {
      id: TabId.attributes,
      title: 'Атрибуты',
    },
    {
      id: TabId.editions,
      title: 'Редакции',
    },
  ];

  return {
    Tab: tabMap.get(activeTabId),
    activeTabId,
    tabs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateActiveTab(tabId) {
    dispatch(setActiveTab.createAction({ tabId }));
  },
});

export const SidebarConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);

export default SidebarConnect;
