import React from 'react';

import { BookmarksConnect } from '@layouts/bookmarks/components/bookmarks.connect';

import {
  CloseButton,
  OpenButton,
} from '@components/buttons';

import { DisolveLayer } from '@pages/searchResult/documents/components/disolveLayer/disolveLayer';

import s from '@pages/searchResult/documents/preview/documentPreview.style';

import { DocumentConnect as DocumentTab } from './document.connect';
import { AttributesConnect as AttributesTab } from './attributesTab.connect';

export interface DocumentProps {
  sourceId: string;
  documentId: string;
  baseHref: string;
  title: string;
  data: any;
  onFetch: () => void;
  loading: boolean;
  onClose: () => void;
}

interface HeaderProps {
  baseHref: string;
  onClose: () => void;
  sourceId: string;
  documentId: string;
  title: string;
}

const HeaderTitle = (props: { title: string }) => <s.HeaderTitle>{props.title}</s.HeaderTitle>;

const Header = (props: HeaderProps) => (
  <s.Header>
    <s.HeaderCloseButton>
      <CloseButton
        color="#4183d7"
        onClick={props.onClose}
      />
    </s.HeaderCloseButton>
    <HeaderTitle title={props.title} />
    <s.Tools>
      <s.Tool>
        <BookmarksConnect />
      </s.Tool>
      <s.Tool />
      <s.Tool marginRight={4}>
        <OpenButton
          href={props.baseHref}
        />
      </s.Tool>
    </s.Tools>
  </s.Header>
);

type ItemId = string;

interface TabBarItemProps {
  onClick: (id: ItemId) => void;
  title: string | React.Component;
  activeId: ItemId;
  id: ItemId;
}

const TabBarItem = (props: TabBarItemProps) => {
  const handleClick = () => {
    props.onClick(props.id);
  };

  const isActive = props.activeId === props.id;

  return <s.TabItem active={isActive} onClick={handleClick}>{props.title}</s.TabItem>;
};

interface TabBarProps {
  activeId: ItemId;
  tabs: any[];
  onTabClick: (id: ItemId) => void;
}

const TabBar = (props: TabBarProps) => {
  const renderTabs = () => props.tabs.map((tab) => (
    <TabBarItem
      activeId={props.activeId}
      key={tab.id}
      id={tab.id}
      title={tab.title}
      onClick={props.onTabClick}
    />
  ));

  return (
    <s.TabBar>
      {renderTabs()}
    </s.TabBar>
  );
};

export class DocumentPreview extends React.PureComponent<DocumentProps, any> {

  protected tabs = {
    document: {
      id: 'document',
      title: 'Документ',
      component: DocumentTab,
    },
    attributes: {
      id: 'attributes',
      title: 'Атрибуты',
      component: AttributesTab,
    },
  };

  state = {
    activeTabId: this.tabs.document.id,
  };

  componentDidMount() {
    if (this.props.data.loading) {
      this.props.onFetch();
    }
  }

  componentDidUpdate(prevProps: Readonly<DocumentProps>) {
    if (this.props.documentId !== prevProps.documentId) {
      this.props.onFetch();
    }
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  handleChangeActiveTab = (tabId: ItemId) => {
    this.setActiveTab(tabId);
  };

  setActiveTab = (tabId) => {
    this.setState({
      activeTabId: tabId,
    });
  };

  renderTabBar() {
    const tabs = Object.values(this.tabs);

    return (
      <TabBar
        tabs={tabs}
        activeId={this.state.activeTabId}
        onTabClick={this.handleChangeActiveTab}
      />
    );
  }

  renderActiveTab() {
    const Component = this.tabs[this.state.activeTabId].component;

    const isDocument = this.state.activeTabId === this.tabs.document.id;

    if (isDocument) {
      return (
        <s.Workspace>
          <DisolveLayer shouldRender={this.props.loading} />
          <Component />
        </s.Workspace>
      );
    }

    return (
      <s.Workspace>
        <DisolveLayer shouldRender={this.props.loading} />
        <Component />
      </s.Workspace>
    );
  }

  render() {
    return (
      <>
        <s.Root>
          <Header
            baseHref={this.props.baseHref}
            sourceId={this.props.sourceId}
            documentId={this.props.documentId}
            title={this.props.title}
            onClose={this.props.onClose}
          />
          {this.renderTabBar()}
          {this.renderActiveTab()}
        </s.Root>
        <s.Background />
      </>
    );
  }

}

export default DocumentPreview;
