import { Block as TextBlock } from '../filterBlocks/text/redux/block';
import { Block as TitleBlock } from '../filterBlocks/title/redux/block';
import { Block as LawBlock } from '../filterBlocks/law/redux/block';
import { Block as LawNumberBlock } from '../filterBlocks/lawNumber/redux/block';
import { Block as LawTypeBlock } from '../filterBlocks/lawType/redux/block';
import { Block as LawStatusBlock } from '../filterBlocks/lawStatus/redux/block';
import { Block as DepartmentTypeBlock } from '../filterBlocks/departmentType/redux/block';
import { Block as DateBlock } from '../filterBlocks/date/redux/block';
import { Block as PublicationDateBlock } from '../filterBlocks/publicationDate/redux/block';
import { Block as PublicationStatusBlock } from '../filterBlocks/publicationStatus/redux/block';

const blocksTypesMap = new Map();

blocksTypesMap.set(TextBlock.DTOType, TextBlock.type);
blocksTypesMap.set(TitleBlock.DTOType, TitleBlock.type);
blocksTypesMap.set(LawBlock.DTOType, LawBlock.type);
blocksTypesMap.set(LawNumberBlock.DTOType, LawNumberBlock.type);
blocksTypesMap.set(LawTypeBlock.DTOType, LawTypeBlock.type);
blocksTypesMap.set(LawStatusBlock.DTOType, LawStatusBlock.type);
blocksTypesMap.set(DepartmentTypeBlock.DTOType, DepartmentTypeBlock.type);
blocksTypesMap.set(DateBlock.DTOType, DateBlock.type);
blocksTypesMap.set(PublicationDateBlock.DTOType, PublicationDateBlock.type);
blocksTypesMap.set(PublicationStatusBlock.DTOType, PublicationStatusBlock.type);

export const normalizeType = function (blockType: string) {
  return blocksTypesMap.get(blockType);
};

export default normalizeType;
