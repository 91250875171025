import React from 'react';

import { NotFound } from '@components/notFound';
import { ServiceError } from '@components/serviceError/serviceError';
import { Loader } from '@components/loader';

import {
  ReasonItems,
  Item,
} from './reasonItems';

import s from './reason.style';

type DocumentId = string;

interface ReasonsProps {
  claimantItems: Item[];
  defendantItems: Item[]
  titleClaimant: string;
  titleDefendant: string;
  loading: boolean;
  loadingSuccess: boolean;
  documentId: DocumentId;
  emptyReasonItems: boolean
  onFetchReason(documentId: DocumentId): void;
}

export class Reasons extends React.PureComponent<ReasonsProps> {

  state = {
    activeId: null,
    activePointId: null,
  };

  setActiveId = (id, blockId) => {
    this.setState({
      activeId: id,
      activePointId: blockId,
    });
  };

  handleFetchReason = () => {
    this.props.onFetchReason(this.props.documentId);
  };

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderError() {
    return (
      <ServiceError
        title="Не удалось выделить доводы"
        onClick={this.handleFetchReason}
      />
    );
  }

  renderNotFound() {
    return (
      <NotFound title="Документ не содержит доводов" />
    );
  }

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    if (!this.props.loadingSuccess) {
      return this.renderError();
    }

    if (this.props.emptyReasonItems) {
      return this.renderNotFound();
    }

    return (
      <s.Root>
        <ReasonItems
          items={this.props.claimantItems}
          title={this.props.titleClaimant}
          activeId={this.state.activeId}
          onItemClick={this.setActiveId}
          activePointId={this.state.activePointId}
        />
        <ReasonItems
          items={this.props.defendantItems}
          title={this.props.titleDefendant}
          activeId={this.state.activeId}
          onItemClick={this.setActiveId}
          activePointId={this.state.activePointId}
        />
      </s.Root>
    );
  }

}

export default Reasons;
