import React, { useState } from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { Button } from '@pages/searchResult/components/button/button';

import { Menu } from './menu';

import s from './onlineHelp.style';

interface Props {
  disabled?: boolean;
  shouldRender: boolean;
}

export const OnlineHelp = (props: Props) => {
  if (!props.shouldRender) {
    return null;
  }

  const [wide, setWide] = useState(false);
  const [level, setLevel] = useState(0);

  const handleLevelChange = (params: { wide: boolean; level: number }) => {
    setWide(params.wide);
    setLevel(params.level);
  };

  const renderMenu = (p) => (
    <Menu {...p} onLevelChange={handleLevelChange} />
  );

  return (
    <s.Root>
      <BaseDropdown
        usePortal
        wide={wide}
        level={level}
        forceLeftRecalculate
        leftMargin={128}
        onLevelChange={handleLevelChange}
        placement="bottom-end"
        renderInput={(p) => (
          <Button
            title="ОНЛАЙН-ЮРИСТ"
            disabled={props.disabled}
            onClick={() => {
            }}
            {...p}
          />
        )}
        renderMenu={(p) => renderMenu(p)}
      />
      <s.Drawer />
    </s.Root>
  );
};

export default OnlineHelp;
