import { connect } from 'react-redux';
import { LicenseType } from '@router/pathPermissions';

import { OnlineHelp } from './onlineHelp';

const mapStateToProps = (state) => {
  const { accessTo } = state.currentUser;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    shouldRender: multiLicense || caselookLicense,
  };
};

export const OnlineHelpConnect = connect(
  mapStateToProps,
)(OnlineHelp);

export default OnlineHelpConnect;
