import { connect } from 'react-redux';

import { saveCommentaryThunk } from './redux/saveCommentary.thunk';

import { Attributes } from './attributes';

const mapStateToProps = (state) => {
  const { attributes } = state.document;
  const descriptionDocument = 'Атрибуты документа';
  const descriptionNote = 'Примечание к документу';

  return {
    ...attributes,
    descriptionDocument,
    descriptionNote,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCommentaryChange(commentary) {
    saveCommentaryThunk(dispatch, { commentary });
  },
});

export const AttributesConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Attributes);

export default AttributesConnect;
