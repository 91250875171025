import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { processingStatus } from '@pages/searchLawResult/redux/processingStatus';
import { ProcessingStatus } from '@pages/searchLawResult/redux/state';
import { resetToInitial } from '@pages/searchLawResult/redux/resetToInitial';

import { Filters } from '../filters';
import { httpCreateSearch } from './createSearchLaw.http';

interface Response {
  channel_id: string;
  rsearch_id: number;
}

export class CreateSearchLawThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  private response: Response | undefined;

  async invoke() {
    const filters = new Filters();
    const search = this.getState().searchLaw;
    if (!search.query.blocksList.length) {
      return;
    }

    this.resetToInitial();

    sendAmplitudeData('search_law documents');
    Userpilot.track('search_law documents');

    try {
      this.dispatchProcessing();
      filters.createFilters(search.query);
      const conditions = filters.undecorate();
      const requestParams = this.buildRequestParams(conditions);
      this.response = await httpCreateSearch(requestParams);
      this.dispatchProcessingDone();
      this.redirectToDocuments();
    } catch (error) {
      throw Error(error.status);
    }
  }

  private resetToInitial() {
    const state = this.getState();
    const { id } = state.searchLawResult;

    if (!id) {
      return;
    }

    this.dispatch(resetToInitial.createAction());
  }

  private buildRequestParams(conditions) {
    return {
      conditions,
    };
  }

  private dispatchProcessing() {
    this.dispatch(processingStatus.createAction(ProcessingStatus.processing));
  }

  private dispatchProcessingDone() {
    this.dispatch(processingStatus.createAction(ProcessingStatus.done));
  }

  private redirectToDocuments() {
    // @ts-ignore
    window.location.href = `#/s_law/${this.response.search_id}/documents`;
  }

}

export function createSearchLawThunk(dispatch) {
  const thunk = new CreateSearchLawThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();

}
