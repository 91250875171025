import { connect } from 'react-redux';

import { Breadcrumbs } from '@components/header';
import { removeTagsFromText } from '@utils/utils';

import { buildBreadcrumbs } from './breadcrumbs';

const mapStateToProps = (state, ownProps) => ({
  breadcrumbs: buildBreadcrumbs({
    searchId: ownProps.searchId,
    title: removeTagsFromText(ownProps.title),
  }),
});

export const BreadcrumbsConnect = connect(
  mapStateToProps,
)(Breadcrumbs);

export default BreadcrumbsConnect;
