import { Dispatch } from 'redux';

import { fetchDocumentThunk } from '@pages/searchLawResult/document/redux/fetchPreviewDocument.thunk';
import { resetToInitial } from '@pages/searchLawResult/document/redux/resetToInitial';

import { changePreviewDocument } from './changePreviewDocument';

export class FetchDocumentPreviewThunk {

  getState;

  private readonly dispatch: Dispatch;

  private documentId: number = null;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params) {
    this.dispatch(resetToInitial.createAction());
    this.documentId = params.documentId;

    this.dispatch(changePreviewDocument.createAction({ documentId: this.documentId }));
    await fetchDocumentThunk(this.dispatch, params);
  }

}

export function fetchDocumentPreviewThunk(dispatch, params) {
  const thunk = new FetchDocumentPreviewThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchDocumentPreviewThunk;
