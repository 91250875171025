import React from 'react';

import { Loader } from '@components/loader';

import { DocumentBody } from './documentBody';
import { HighlightNavigation } from './highlightNavigation';
import { ReasonNavigation } from './reasonNavigation';

import type { Paragraph } from './redux/document';

import s from './documentTab.style';

interface ResolutionTabProps {
  body: Paragraph[];
  loading: boolean;
  highlightIds: string[];
  reasonIds: string[];
  activeReason: boolean;
  loadingReasons: boolean;
  loadingSuccess: boolean;
  userGuest?: boolean;
}

export const ResolutionTab = (props: ResolutionTabProps) => {

  if (props.loading) {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  const targetId = 'resolution-preview-container';
  const firstParagraphId = props.body[0] && props.body[0].id;

  return (
    <s.Root
      id={targetId}
    >
      <s.DocumentContainer>
        <DocumentBody
          noBookmarks
          body={props.body}
          activeReason={props.activeReason}
          reasonIds={props.reasonIds}
        />
        <s.Wrapper>
          <HighlightNavigation
            title="Результаты"
            ids={props.highlightIds}
            targetId={targetId}
            noDuplicateLayer
            firstParagraphId={firstParagraphId}
          />
          <ReasonNavigation
            isUserGuest={props.userGuest}
            loadingSuccess={props.loadingSuccess}
            loadingReasons={props.loadingReasons}
            isActive={!props.activeReason}
            title="Доводы"
            noDuplicateLayer
            ids={props.reasonIds}
            targetId={targetId}
            firstParagraphId={firstParagraphId}
          />
        </s.Wrapper>
      </s.DocumentContainer>
    </s.Root>
  );
};

export default ResolutionTab;
