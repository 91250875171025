import { connect } from 'react-redux';

import { Relations } from './relations';

const mapStateToProps = (state) => {
  const { relations } = state.document;
  const descripton = 'Нормативные правовые акты';

  return {
    ...relations,
    descripton,
  };
};

export const RelationsConnect = connect(
  mapStateToProps,
)(Relations);

export default RelationsConnect;
