import { connect } from 'react-redux';

import { Chronology } from './chronology';

const mapStateToProps = (state) => ({
  description: 'Хронология',
  baseId: state.document.id,
  isChronologyDocument: state.document.isChronologyDocument,
  isSimilarDocument: state.document.isSimilarDocument,
  parentDocumentId: state.document.parentDocumentId,
  items: state.document.chronology.data,
  loading: state.document.chronology.loading,
  activeId: state.document.id,
});

export const ChronologyConnect = connect(
  mapStateToProps,
)(Chronology);

export default ChronologyConnect;
