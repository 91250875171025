import { Filters } from '../filters';

export const duplicateBlockReduce = (state, action) => {
  if (!state) {
    return state;
  }

  const filters = new Filters();
  filters.createFilters(state.query);
  const type = state.query.blocksMap.get(action.data.id).getType().id;
  const data = {
    id: action.data.id,
    type,
    defaultData: undefined,
  };

  if (state.presets[type]) {
    data.defaultData = {
      ...state.presets[type][0],
    };
  }

  filters.duplicateFilterBlock(data);
  const query = {
    blocksList: filters.blocksList,
    blocksMap: filters.blocksMap,
  };

  return {
    ...state,
    query: {
      ...state.query,
      ...query,
    },
  };
};

export default duplicateBlockReduce;
