export const setActiveReasonToggleReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    reasons: {
      ...state.reasons,
      showReasons: true,
    },
  };
};

export default setActiveReasonToggleReduce;
