import styled, { css } from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

interface Props {
  isActive: boolean;
  isChildren: boolean;
}

style.Root = styled.div`
  background: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.borderColor};
`;

style.Description = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.backgroundGrey};
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 0 16px;
  font-family: "Roboto-medium", sans-serif;
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;

  > div {
    margin-top: 10px;
  }
`;

style.Item = styled.div`
  cursor: pointer;
  padding: 12px 16px;

  :hover {
    background: #4183D712;
  }

  ${(props: Props) => props.isActive && css`
    background: ${theme.colors.blue};

    :hover {
      background: ${theme.colors.blue};
    }

    div {
      color: ${theme.colors.white};
    }
  `}

  ${(props: Props) => props.isChildren && css`
    padding-left: 48px;
  `}
`;

style.ItemTitle = styled.div`
  font-size: 11px;
  color: ${theme.colors.stone}
`;

style.ItemBody = styled.div`
  font-size: 13px;
  color: ${theme.colors.defaultText}
`;

export default style;
