import { connect } from 'react-redux';
import queryString from 'query-string';

import { getParamsFromUrl } from '@utils/utils';
import { LawDocument } from '@pages/lawDocument/lawDocument';

import { updateLawDocumentThunk } from '@pages/lawDocument/redux/updateLawDocument.thunk';
import { fetchDocumentThunk } from './redux/fetchDocument.thunk';

const mapStateToProps = (state, ownProps) => {
  const values = queryString.parse(ownProps.location.search);

  let article = null;
  let articleId = null;
  if (!state.lawDocument.document.loading) {
    article = state.lawDocument.document.titles.find((title) => title.id === values.articleId);
  }

  if (article) {
    articleId = article.id;
  }

  const { searchId } = getParamsFromUrl(ownProps);

  return {
    loading: state.lawDocument.document.loading,
    articleId,
    editionDate: values.editionDate,
    searchId,
  };

};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit() {
    const {
      searchId,
      documentId,
    } = getParamsFromUrl(ownProps);

    const params = {
      documentId,
      searchId,
    };

    fetchDocumentThunk(dispatch, params);
  },
  onUpdate() {
    const {
      documentId,
    } = getParamsFromUrl(ownProps);

    const values = queryString.parse(ownProps.location.search);

    const requestParams = {
      documentId,
      articleId: values.articleId,
      editionDate: values.editionDate,
    };

    updateLawDocumentThunk(dispatch, requestParams);
  },
});

export const DocumentConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LawDocument);

export default DocumentConnect;
