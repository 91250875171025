import React from 'react';
import { RoutePath } from '@router/path';

import { WithSidebar } from '@components/withSidebar';
import { StaticSidebar } from '@components/staticSidebar/staticSidebar';

import { AuthRouteConnect } from '@router/authRoute.connect';

import { SearchLawConnect } from './searchLaw.connect';

export const RouteSearchLaw = (
  <AuthRouteConnect
    exact
    path={RoutePath.searchLaw}
    component={WithSidebar(StaticSidebar, SearchLawConnect)}
  />
);

export default RouteSearchLaw;
