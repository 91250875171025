import { connect } from 'react-redux';

import { LicenseType } from '@router/pathPermissions';

import { AddNewBlock } from './addNewBlock';

import { blocks } from './utils';

const mapSateToProps = (state) => {

  const accessTo = state.currentUser.accessTo;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  const multiLicense = caselookLicense && monitoringLicense;

  if (!multiLicense) {
    return {
      blocks: blocks.filter((item, index) => index !== 5),
    };

  }

  return {
    blocks,
  };
};

export const AddNewBlockConnect = connect(
  mapSateToProps,
)(AddNewBlock);

export default AddNewBlockConnect;
