import React from 'react';

import { Blocks } from './blocks';

import s from './blocksMenu.style';

export interface BlocksMenuProps {
  blocks: any[];
  onHandleItemSelect: (type: string) => void
  closePopover: () => void
}

export const BlocksMenu = (props: BlocksMenuProps) => (
  <s.BlocksMenu>
    <Blocks {...props} />
  </s.BlocksMenu>
);

export default BlocksMenu;
