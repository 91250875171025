import { Dispatch } from 'redux';

import { fetchLawDocument } from '@pages/lawDocument/redux/fetchLawDocument';
import { fetchEditionsThunk } from '@pages/lawDocument/sidebar/redux/fetchEditions.thunk';
import { resetToInitial } from './resetToInitial';

import { httpFetchDocument } from './fetchDocument.http';

import type { Response } from './fetchDocument.http';

export class FetchDocumentThunk {

  getState;

  private readonly dispatch: Dispatch;

  private documentId: string = null;

  private searchId: string = null;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params) {

    this.documentId = params.documentId;
    this.searchId = params.searchId;

    const requestParams = {
      searchId: params.searchId,
      documentId: params.documentId,
    };

    this.dispatch(resetToInitial.createAction());
    await this.fetchDocument(requestParams);
    await fetchEditionsThunk(this.dispatch, { documentId: this.documentId });

  }

  private async fetchDocument(requestParams) {
    try {
      const result = await httpFetchDocument(requestParams);
      this.dispatchFetchDocumentSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchDocumentSucceed(response: Response) {
    const actionData = {
      id: this.documentId,
      body: response.body,
      edition_id: response.edition_id,
      edition: response.edition,
      date: response.date,
      is_actual: response.is_actual,
      title: response.title,
      type: response.type,
      department_type: response.department_type,
      url: response.url,
      number: response.number,
      publications: response.publications,
    };

    this.dispatch(fetchLawDocument.createAction(actionData));
  }

}

export async function fetchDocumentThunk(dispatch, params) {
  const thunk = new FetchDocumentThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default FetchDocumentThunk;
