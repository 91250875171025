import { getQuery } from '@pages/searchLaw/history/redux/fetchHistory.reduce';

export interface ActionData {
  filter: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchFiltersReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = {
    ...state,
    query: {
      ...state.query,
      ...getQuery(action.data.filter, state.presets),
    },
  };

  return newState;
};

export default fetchFiltersReduce;
