export const fetchReasonFailReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    reasons: {
      ...state.reasons,
      loading: false,
      loadingSuccess: false,
    },
  };
};

export default fetchReasonFailReduce;
