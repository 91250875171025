const chronologyPostfix = 'chronology/:chronologyDocumentId';
const similarPostfix = 'similar/:similarDocumentId';
const searchDocumentBaseUrl = '/search/:sourceId/documents/:documentId';
const packageDocumentBaseUrl = '/package/:sourceId/documents/:documentId';
const subscriptionDocumentBaseUrl = '/subscription/:sourceId/documents/:documentId';
const authorPackageDocumentBaseUrl = '/authorpackages/:sourceId/documents/:documentId';
const searchLawBaseUrl = '/s_law';

export const RoutePath = {

  landing: '/',

  login: '/authentication',

  passwordRecovery: '/passwordrecovery',

  passwordRecoveryEmailSent: '/recoveryemailsent',

  createNewPassword: '/authentication/reset/',

  createNewPasswordSuccess: '/createnewpasswordsuccess',

  outDatePasswordResetLink: '/outdatepasswordresetlink',

  profile: '/profile',

  bookmarks: '/bookmarks',

  paymentSelect: '/paymentselect',

  search: '/search',

  documents: '/search/:sourceId/documents',

  searchLaw: searchLawBaseUrl,

  searchLawDocuments: `${searchLawBaseUrl}/:sourceId/documents`,

  searchLawDocument: `${searchLawBaseUrl}/:searchId/documents/:documentId`,

  searchWithFilters: '/search/:sourceId',

  subscriptions: '/subscriptions',

  subscription: '/subscriptions/:subscriptionId',

  regulations: '/regulation',

  regulation: '/regulation/:folderId',

  regulationPreview: '/regulation/project_preview/:previewId',

  regulationSmartFolder: '/regulation/filters/:filterId',

  packages: '/packages',

  package: '/packages/:packageId',

  authorPackages: '/authorpackages',

  authorPackage: '/authorpackages/:packageId',

  searchDocument: searchDocumentBaseUrl,

  searchChronologyDocument: `${searchDocumentBaseUrl}/${chronologyPostfix}`,

  searchSimilarDocument: `${searchDocumentBaseUrl}/${similarPostfix}`,

  packageDocument: packageDocumentBaseUrl,

  packageChronologyDocument: `${packageDocumentBaseUrl}/${chronologyPostfix}`,

  packageSimilarDocument: `${packageDocumentBaseUrl}/${similarPostfix}`,

  authorPackageDocument: authorPackageDocumentBaseUrl,

  authorPackageChronologyDocument: `${authorPackageDocumentBaseUrl}/${chronologyPostfix}`,

  authorPackageSimilarDocument: `${authorPackageDocumentBaseUrl}/${similarPostfix}`,

  subscriptionDocument: subscriptionDocumentBaseUrl,

  subscriptionChronologyDocument: `${subscriptionDocumentBaseUrl}/${chronologyPostfix}`,

  lawDocument: '/laws/:documentId',

  subscriptionSimilarDocument: `${subscriptionDocumentBaseUrl}/${similarPostfix}`,

  courts: '/search/:sourceId/judges',

  law: '/search/:sourceId/law',

  analysis: '/search/:sourceId/analysis',

  statistics: '/search/:sourceId/statistics',

};

export default RoutePath;
