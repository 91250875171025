import { styled } from '@components/styled';

import { css } from 'styled-components';

const style: any = {};

interface Props {
  showHighlight: boolean;
}

style.Root = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 12px;
  position: relative;
`;

style.Slider = styled.span<Props>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
  box-shadow: 0 0 1px #2196F3;
  width: 24px;
  height: 14px;

  ${(props) => props.showHighlight && css`
    background-color: #4183d7;
  `};

  &::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    transition: .4s;
    border-radius: 50%;

    ${(props) => props.showHighlight && css`
      transform: translateX(10px);
  `};
  };

`;

export default style;
