import { Filters } from '@pages/searchLaw/form/filters';
import { normalizeType } from '@pages/searchLaw/form/utils/blocksTypesMap';

export function getQuery(filters, presets) {
  const data = filters.map((filter) => {
    const item = { ...filter };
    item.type = normalizeType(item.type);

    return item;
  });

  const updatedFilters = new Filters();

  updatedFilters.decorate(data, presets);

  return {
    blocksList: updatedFilters.blocksList,
    blocksMap: updatedFilters.blocksMap,
  };
}

export default getQuery;
