import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './serviceError.style';

interface ServiceErrorProps {
  title?: string;
  onClick: () => void;
}

export const ServiceError = (props: ServiceErrorProps) => {
  const iconPath = iconsPath.get('serviceError');

  const title = props.title || 'Ничего не найдено';

  return (
    <s.Root>
      <s.IconBlock>
        <s.Icon>
          <Icon viewBoxSize={72} size={72} path={iconPath} color="#6c7b89" />
          <s.IconTitle>{title}</s.IconTitle>
          <s.Button
            onClick={props.onClick}
          >
            Повторить Попытку
          </s.Button>
        </s.Icon>
      </s.IconBlock>
    </s.Root>
  );
};

export default ServiceError;
