import { setActiveReasonToggleReduce } from './setActiveReasonToggle.reduce';

const actionType = 'DOCUMENT/ACTIVE_REASON_TAB';

const createAction = () => ({
  type: actionType,
});

export const setActiveReasonToggle = {
  actionType,
  createAction,
  reduce: setActiveReasonToggleReduce,
};

export default setActiveReasonToggle;
