import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { fetchDocumentsThunk } from './redux/fetchDocuments.thunk';
import { changePageNumber } from './redux/changePageNumber';

import { Documents } from './documents';

const mapStateToProps = (state, ownProps) => {
  const { sourceId } = getParamsFromUrl(ownProps);
  const {
    loading,
    pageLoading,
    currentPageNumber,
    pages,
  } = state.searchLawResult.documents;

  const items = pages[currentPageNumber] ? pages[currentPageNumber].items : [];

  return {
    id: sourceId,
    isEmpty: !items.length,
    loading,
    pageLoading,
    processingStatus: state.searchLawResult.processingStatus,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onResetPageNumber() {
    dispatch(changePageNumber.createAction({
      pageNumber: 1,
      globalPageNumber: 1,
    }));
  },
  onFetch() {
    const { sourceId } = getParamsFromUrl(ownProps);
    fetchDocumentsThunk(dispatch, sourceId, null);
  },
});

export const DocumentsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Documents);

export default DocumentsConnect;
