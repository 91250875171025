import { ReducerBuilder } from '@redux/reducer.builder';

import { resetToInitial } from './resetToInitial';
import { reducerBuilder as documentsReducer } from '../documents/redux/reducer';
import { reducerBuilder as documentReducer } from '../document/redux/reducer';

import { processingStatus } from './processingStatus';

import { initial } from './state';

export const reducerBuilder = new ReducerBuilder([
  processingStatus,
  resetToInitial,
]);

reducerBuilder.merge(documentsReducer);
reducerBuilder.merge(documentReducer);

const initialState = initial();
reducerBuilder.setInitialState(initialState);

export default reducerBuilder;
