import { connect } from 'react-redux';

import { Block } from '@pages/searchLaw/form/filterBlocks/lawType/block';
import { changeContains } from '../redux/changeContains';
import { updateValue } from './redux/updateValue';

const mapStateToProps = (state) => {
  const items = state.searchLaw.presets.publicationStatus;

  return {
    items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateValue(data) {
    dispatch(updateValue.createAction(data));
  },
});

export const BlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default BlockConnect;
