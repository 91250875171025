import { Dispatch } from 'redux';
import { socket } from '@utils/utils';

import { Broadcaster } from '@utils/broadcaster';
import { initAmplitude } from '@utils/amplitude';
import { initUserpilot } from '@utils/userpilot';

import { addBookmark } from '@layouts/bookmarks/redux/addBookmark';
import { addCommentary } from '@layouts/bookmarks/redux/addCommentary';
import { deleteBookmarkInBuffer } from '@layouts/bookmarks/redux/deleteBookmark';
import { cleanBookmarksBuffer } from '@layouts/bookmarks/redux/cleanBookmarks';

import { ActionData as UserActionData } from '@pages/userPages/redux/state';
import { fetchUser } from '@pages/userPages/redux/fetchUser';
import { httpFetchUser } from '@pages/userPages/http/user.http';
import { httpFetchToken } from '@pages/userPages/http/token.http';

import { notifierManager } from '@components/notification';
import { searchBootstrapped } from '@pages/search/redux/searchBootstrapped';
import { searchLawBootstrapped } from '@pages/searchLaw/redux/searchLawBootstrapped';
import { packagesBootstrap } from '@pages/packages/redux/packagesBootstrap';
import { paymentProcessEnd } from '@pages/userPages/redux/paymentProcessEnd';

import { httpFetchDocumentType } from '@bootstrap/fetchDocumentType.http';
import { httpFetchCaseType } from '@bootstrap/fetchCaseType.http';
import { httpFetchCourtType } from '@bootstrap/fetchCourtType.http';
import { httpFetchCaseResult } from '@bootstrap/fetchCaseResult.http';
import { httpFetchAppealState } from '@bootstrap/fetchAppealState.http';
import { httpFetchSideMode } from '@bootstrap/fetchSideMode.http';
import { httpFetchTags } from '@bootstrap/fetchTags.http';
import { httpFetchPaymentPresets } from '@bootstrap/fetchPaymentPresets.http';
import { httpFetchIsPayment } from '@bootstrap/fetchIsPayment.http';

import { httpFetchSearchLawPresets } from '@bootstrap/fetchSearchLawPresets.http';

import { fetchBookmarksThunk } from '@layouts/bookmarks/redux/fetchBookmarks.thunk';

import { caselookSocketManager } from './caselookSocket';
import { regulationSocketManager } from './regulationSocket';

export class BootstrapThunk {

  dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  async invoke() {
    this.setBroadcaster();
    try {
      const user = await httpFetchUser();
      const freeRole = user.role === 'sales' || user.role === 'teamlead' || user.role === 'support';

      let paymentOptions = {};
      let paymentStatus = {};
      if (!freeRole) {
        paymentOptions = await httpFetchPaymentPresets();
        paymentStatus = await httpFetchIsPayment();
      }

      this.initAmplitude(user);
      this.initUserpilot(user);

      await this.searchBootstrap();
      await this.searchLawBootstrap();
      const token = await httpFetchToken();
      this.dispatchUserFetchSucceeded({ ...user, paymentOptions, paymentStatus });
      this.stayOnSocket(token);
      await this.packagesBootstrap();
      await fetchBookmarksThunk(this.dispatch);
    } catch (error) {
      await Promise.reject(error);
    }
  }

  private setBroadcaster() {
    const broadcaster = Broadcaster.getInstance();
    broadcaster
      .setCallback(addBookmark.actionType, (params) => this.dispatch(addBookmark.createAction(params.data)));

    broadcaster
      .setCallback(addCommentary.actionType, (params) => this.dispatch(addCommentary.createAction(params.data)));

    broadcaster
      .setCallback(
        deleteBookmarkInBuffer.actionType,
        (params) => this.dispatch(deleteBookmarkInBuffer.createAction(params.data)),
      );

    broadcaster
      .setCallback(cleanBookmarksBuffer.actionType, () => this.dispatch(cleanBookmarksBuffer.createAction()));
  }

  private initAmplitude(user) {
    if (user.id && !user.is_maskarade) {
      initAmplitude(user);
    }
  }

  private initUserpilot(user) {
    if (!user.id) {
      return;
    }

    initUserpilot(user);
  }

  async searchBootstrap() {
    const searchBootstrap = Promise.all([
      httpFetchDocumentType(),
      httpFetchCaseType(),
      httpFetchCourtType(),
      httpFetchCaseResult(),
      httpFetchAppealState(),
      httpFetchSideMode(),
    ]);

    const [
      documentType,
      caseType,
      courtType,
      caseResult,
      appealState,
      sideMode,
    ] = await searchBootstrap;

    const data = {
      documentType,
      caseType,
      courtType,
      caseResult,
      appealState,
      sideMode,
    };

    this.dispatchSearchBootstrapSucceeded(data);
  }

  async searchLawBootstrap() {
    const response = await httpFetchSearchLawPresets();

    const data = {
      lawType: response.law_type,
      departmentType: response.department_type,
      lawStatus: response.law_status,
      publicationStatus: response.publication_status,
    };

    this.dispatchSearchLawBootstrapSucceeded(data);
  }

  async packagesBootstrap() {
    const packageBootstrap = Promise.all([
      httpFetchTags(),
    ]);

    const [tagsResult] = await packageBootstrap;
    const data = { tags: tagsResult.result };

    this.dispatchPackagesBootstrapSucceeded(data);
  }

  dispatchUserFetchSucceeded = (user: UserActionData) => {
    const action = fetchUser.createAction(user);
    this.dispatch(action);
  };

  dispatchSearchBootstrapSucceeded(searchBootstrap: any) {
    const action = searchBootstrapped.createAction(searchBootstrap);
    this.dispatch(action);
  }

  dispatchSearchLawBootstrapSucceeded(searchBootstrap: any) {
    const action = searchLawBootstrapped.createAction(searchBootstrap);
    this.dispatch(action);
  }

  dispatchPackagesBootstrapSucceeded(data: any) {
    const action = packagesBootstrap.createAction(data);
    this.dispatch(action);
  }

  stayOnSocket(tokens: { user: string; company: string; }) {
    if (tokens.user) {
      this.stayOnUserSocket(tokens.user);
    }

    const shouldStayOnCompany = tokens.user !== tokens.company;
    if (!shouldStayOnCompany) {
      return;
    }

    if (tokens.company) {
      this.stayOnCompanySocket(tokens.company);
    }
  }

  stayOnUserSocket(token) {
    if (!socket.getChannel(token)) {
      socket.setChannel(token);
    }

    const userSocket = socket.getSocket();
    userSocket.emit('enter', token);

    userSocket.on(token, (response: any) => {
      caselookSocketManager(this.dispatch, response);

      const PAYMENT_END_ID = 112233;

      const isPaymentType = response.type === 'payment:end';
      const paymentSuccess = isPaymentType && response.status;
      const paymentSuccessMessage = 'Лицензия успешно оплачена';
      if (paymentSuccess) {
        notifierManager.done({ id: PAYMENT_END_ID, message: paymentSuccessMessage });

        setTimeout(() => {
          notifierManager.remove({ id: PAYMENT_END_ID });
        }, 1000);

        const action = paymentProcessEnd.createAction(response);

        return this.dispatch(action);
      }

      const paymentFail = isPaymentType && !response.status;
      const paymentFailMessage = 'Ошибка оплаты лицензии';
      if (paymentFail) {
        notifierManager.fail({ id: PAYMENT_END_ID, message: paymentFailMessage });

        setTimeout(() => {
          notifierManager.remove({ id: PAYMENT_END_ID });
        }, 1000);

        const action = paymentProcessEnd.createAction(response);

        this.dispatch(action);
      }

      return null;
    });
  }

  stayOnCompanySocket(token) {
    if (!socket.getChannel(token)) {
      socket.setChannel(token);
    }

    const companySocket = socket.getSocket();
    companySocket.emit('enter', token);

    companySocket.on(token, (response: any) => {
      regulationSocketManager(this.dispatch, response);
    });
  }

}

export default BootstrapThunk;
