import { resetDocumentsReduce } from './resetDocuments.reduce';

const actionType = 'SEARCH_LAW/RESULT/DOCUMENTS/RESET';

export interface Action {
  type: typeof actionType;
}

const createAction = (): Action => ({
  type: actionType,
});

export const resetDocuments = {
  actionType,
  createAction,
  reduce: resetDocumentsReduce,
};

export default resetDocuments;
