import { connect } from 'react-redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';
import { UserRoleType } from '@pages/userPages/redux/state';

import { More } from './more';

import { TabId } from '../redux/state';

import { setActiveTab } from './redux/setActiveTab';

const isActiveTabMore = (tabsMore, activeId) => {
  const activeTab = tabsMore.find((item) => item.id === activeId);

  return {
    active: !!activeTab,
  };
};

const mapStateToProps = (state) => {

  const tabTitle = 'Еще';

  const activeId = state.document.layout.sidebar.activeTabId;
  const isReal = state.currentUser.role !== UserRoleType.guest;
  const isDocument = state.document.id;

  const tabsMore = [
    {
      id: 'chronology',
      title: 'Хронология',
    },
    {
      id: 'similar',
      title: 'Похожие',
    },
  ];

  const active = isActiveTabMore(tabsMore, activeId).active;

  return {
    activeId,
    tabsMore,
    tabTitle,
    active,
    isReal,
    isDocument,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateActiveTab(tabId) {
    dispatch(setActiveTab.createAction({ tabId }));

    if (tabId === TabId.similar) {
      sendAmplitudeData('document: open similar tab');
      Userpilot.track('document: open similar tab');
    }

    if (tabId === TabId.chronology) {
      sendAmplitudeData('document: open chronology tab');
      Userpilot.track('document: open chronology tab');
    }
  },
});

export const MoreConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(More);

export default MoreConnect;
