import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.ReasonTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

style.Title = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.backgroundGrey};
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 0 16px;
  font-family: "Roboto-medium", sans-serif;
`;

style.BetaIcon = styled.div`
  margin-left: 5px;

  ::before {
    pointer-events: none;
    content: 'Функция находится в тестовом режиме. По окончании тестирования доступ может быть ограничен.';
    text-align: center;
    display: flex;
    align-items: center;
    border-radius: 2px;
    height: 40px;
    width: 304px;
    position: absolute;
    top: 70px;
    right: 0;
    opacity: 0;
    transition: .5s;
    font-size: 11px;
    color: white;
    right: 150px;
  }

  &:hover::before {
    opacity: 1;
    background: rgba(85, 98, 112, 0.9);
  }
`;

style.Icon = styled.div`
  position: relative;
`;

export default style;
