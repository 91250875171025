type Id = string;
type Publication = string;

interface DFR {
  title: string;
  url: string;
}

export interface Attributes {
  editionId: Id;
  edition: string;
  date: string;
  publicationDate: string;
  isActual: boolean;
  title: string;
  type: string;
  departmentType: string;
  dfr: DFR;
  url: string;
  number: string;
  publications: Publication[];
}

export interface BodyBlock {
  id: string;
  title: string;
  body: string;
}

type Document = BodyBlock[];

export interface DocumentDTO {
  documentId: Id;
  attributes: Attributes;
  document: Document;
}

export interface State {
  documentId: Id;
  attributes: Attributes;
  document: Document;
  loading: boolean;
}

export class StateBuilder {

  documentId: string = null;

  attributes: Attributes = {
    editionId: null,
    edition: null,
    date: null,
    publicationDate: null,
    isActual: true,
    title: null,
    type: null,
    departmentType: null,
    dfr: {
      title: null,
      url: null,
    },
    url: null,
    number: null,
    publications: [],
  };

  loading = true;

  document = [];

  public createState(state: State) {
    this.documentId = state.documentId;
    this.attributes = { ...state.attributes };
    this.loading = state.loading;
    this.document = [...state.document];
  }

  static createInitialState(): State {
    return {
      documentId: null,
      attributes: {
        edition: null,
        editionId: null,
        date: null,
        publicationDate: null,
        isActual: true,
        title: null,
        type: null,
        departmentType: null,
        dfr: {
          title: null,
          url: null,
        },
        url: null,
        number: null,
        publications: [],
      },
      loading: true,
      document: [],
    };
  }

  public setAttributes(params) {
    const publicationDate = params.publicationDate || this.attributes.publicationDate;
    const dfr = params.dfr || this.attributes.dfr;
    this.attributes = {
      edition: params.edition,
      editionId: params.editionId,
      date: params.date,
      isActual: params.isActual,
      title: params.title,
      type: params.type,
      departmentType: params.departmentType,
      url: params.url,
      number: params.number,
      dfr,
      publicationDate,
      publications: [...params.publications],
    };
  }

  public setDocument(body: Document) {
    this.document = body.map((block) => ({
      id: block.id,
      title: block.title,
      body: block.body,
    }));
  }

  public setDocumentId(id: Id) {
    this.documentId = id;
  }

  public setLoading(loading = true) {
    this.loading = loading;
  }

  public getState(): State {
    return {
      documentId: this.documentId,
      attributes: this.attributes,
      loading: this.loading,
      document: this.document,
    };
  }

}

export default StateBuilder;
