import React from 'react';

import s from './label.style';

interface LabelProps {
  title: string;
  color?: string;
}

export const Label = ({ title, color }: LabelProps) => (
  <s.Root color={color}>
    {title}
  </s.Root>
);

export default Label;
