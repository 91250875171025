import { connect } from 'react-redux';

import { fetchReasonThunk } from '../redux/fetchReason.thunk';

import { Reasons } from './reason';

const mapStateToProps = (state) => {
  const { loading, loadingSuccess, claimant, defendant } = state.document.reasons;
  const claimantItems = claimant.itemsList.map((id) => claimant.itemsMap.get(id));
  const defendantItems = defendant.itemsList.map((id) => defendant.itemsMap.get(id));
  const emptyReasonItems = !claimantItems.length && !defendantItems.length;
  const documentId = state.document.id;
  const titleClaimant = 'Доводы истца';
  const titleDefendant = 'Доводы ответчика';

  return {
    claimantItems,
    defendantItems,
    loading,
    loadingSuccess,
    documentId,
    emptyReasonItems,
    titleClaimant,
    titleDefendant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchReason(documentId: string) {
    fetchReasonThunk(dispatch, { documentId });
  },
});

export const ReasonsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reasons);

export default ReasonsConnect;
