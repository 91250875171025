export const resetFormReduce = (state) => {
  if (!state) {
    return state;
  }

  const query = {
    blocksList: state.defaultQuery.blocksList,
    blocksMap: state.defaultQuery.blocksMap,
  };

  return {
    ...state,
    query,
  };
};

export default resetFormReduce;
