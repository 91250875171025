import { Dispatch } from 'redux';

import { httpFetchEditions } from './fetchEditions.http';
import { fetchEditions } from './fetchEditions';

export class FetchEditionsThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: { documentId: string; }) {
    await this.fetchEditions(params.documentId);
  }

  private async fetchEditions(documentId) {
    try {
      const result = await httpFetchEditions({ documentId });
      this.dispatchFetchEditionsSucceed(result);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchEditionsSucceed(result) {
    this.dispatch(fetchEditions.createAction(result));
  }

}

export function fetchEditionsThunk(dispatch, params) {
  const thunk = new FetchEditionsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchEditionsThunk;
