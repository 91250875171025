import { Map } from 'immutable';

import { TagDTO } from '@pages/packages/redux/state';

import type {
  Details,
  Document,
} from './state';

export const initialDetail = {
  attributes: {
    date: null,
    case: {
      number: null,
      external: null,
    },
    caseType: null,
    caseCategory: null,
    caseResult: null,
    department: null,
    judges: [],
    appealState: null,
    plaintiffs: [],
    defendants: [],
    third: [],
    others: [],
  },
  document: [],
  resolution: [],
  documentId: null,
};

export const prepareDocument = (document) : Document => ({
  id: document.document_id,
  selected: false,
  date: document.date,
  documentType: document.type_title,
  caseNumber: document.case_number,
  instanceNumber: document.instance_number,
  department: document.department,
  departmentNameGenitive: document.department_name_genitive,
  caseResult: document.case_result,
  documentTitle: document.document_title,
  comment: document.comment,
  packagesCount: document.packages_count,
  attributesLoaded: false,
  documentLoaded: false,
  // @ts-ignore
  details: initialDetail as Details,
  initialData: document,
  tag: {
    id: TagDTO[document.tag.tag_id],
    title: document.tag.name,
  },
  title: document.type_title,
  isVisited: document.is_visited,
  highlights: document.highlights,
});

export const prepareDocuments = (fetchedDocuments) => {
  const items = [];
  let itemsMap = Map();

  fetchedDocuments.forEach((document) => {
    items.push(document.document_id);
    itemsMap = itemsMap.set(document.document_id, prepareDocument(document));
  });

  return {
    items,
    itemsMap,
  };
};

type SearchId = string | number;

export interface ActionData {
  sourceId: SearchId;
  pageNumber: number;
  result: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchDocumentsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const fetchedDocuments = action.data.result.documents;

  const currentPageNumber = Number(action.data.result.page);
  const totalPages = action.data.result.total_pages;
  const totalDocuments = action.data.result.total_count;

  const pageInfo = {
    totalPages,
    currentPageNumber,
    totalDocuments,
  };

  return {
    ...state,
    id: action.data.sourceId,
    documents: {
      ...state.documents,
      ...pageInfo,
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: prepareDocuments(fetchedDocuments),
      },
      loading: false,
    },
  };
};

export default fetchDocumentsReduce;
