import styled from 'styled-components';

const style = {};

style.Root = styled.div`
  position: absolute;
  top: ${(props) => `-${props.top}px`};
  left: ${(props) => `${props.left}px`};
  min-height: 56px;
  max-height: 320px;
  flex-direction: column;
  width: 320px;
  background-color: #fff;
  z-index: 100;
  border-radius: 2px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default style;
