import { fetchReasonFailReduce } from './fetchReasonFail.reduce';

const actionType = 'DOCUMENT/FETCH_REASON_FAIL';

const createAction = () => ({
  type: actionType,
});

export const fetchReasonFail = {
  actionType,
  createAction,
  reduce: fetchReasonFailReduce,
};

export default fetchReasonFail;
