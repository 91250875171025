import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchDocument } from './fetchDocument';
import { resetToInitial } from './resetToInitial';

import { StateBuilder } from './state';

export const reducerBuilder = new ReducerBuilder([
  resetToInitial,
  fetchDocument,
]);

const initialState = StateBuilder.createInitialState();
reducerBuilder.setInitialState(initialState);

export default reducerBuilder;
