import { Map } from 'immutable';
import { v4 as uuid } from 'uuid';
import type { Document } from './state';

export const prepareDocument = (document, uniqueId) : Document => ({
  id: document.id,
  uniqueId,
  date: document.date,
  departmentType: document.department_type,
  isActual: document.is_actual,
  number: document.number,
  title: document.title,
  type: document.type,
});

export const prepareDocuments = (fetchedDocuments) => {
  const items = [];
  let itemsMap = Map();

  fetchedDocuments.forEach((document) => {
    const id = uuid();
    items.push(id);
    itemsMap = itemsMap.set(id, prepareDocument(document, id));
  });

  return {
    items,
    itemsMap,
  };
};

type SearchId = string | number;

export interface ActionData {
  sourceId: SearchId;
  pageNumber: number;
  result: Record<string, any>;
}

interface Action {
  type: string;
  data: ActionData;
}

export const fetchDocumentsReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const fetchedDocuments = action.data.result.laws;

  const currentPageNumber = Number(action.data.result.page);
  const totalPages = action.data.result.total_pages;
  const totalDocuments = action.data.result.total_count;

  const pageInfo = {
    totalPages,
    currentPageNumber,
    totalDocuments,
  };

  return {
    ...state,
    id: action.data.sourceId,
    documents: {
      ...state.documents,
      ...pageInfo,
      pages: {
        ...state.documents.pages,
        [currentPageNumber]: prepareDocuments(fetchedDocuments),
      },
      loading: false,
    },
  };
};

export default fetchDocumentsReduce;
