import React from 'react';

import { Arrow } from './arrow/arrow';

import s from './baseInput.style';

interface BaseInputProps {
  value: string;
  disabled?: boolean;
}

export const BaseInput = (props: BaseInputProps) => (
  <s.BaseInput>
    <s.Value
      disabled={props.disabled}
    >
      { props.value }
    </s.Value>
    <Arrow
      disabled={props.disabled}
    />
  </s.BaseInput>
);

BaseInput.defaultProps = {
  disabled: false,
};

export default BaseInput;
