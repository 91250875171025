import { connect } from 'react-redux';

import { UserRoleType } from '@pages/userPages/redux/state';

import {
  prepareReasonBlockIds,
  prepareReasonIds,
} from '@pages/searchResult/documents/utils';

import { Workspace } from './workspace';

const mapStateToProps = (state) => {
  const {
    body,
    additions,
    isPractice,
    department,
  } = state.document.document.document;

  const loading = state.document.document.document.loading && state.document.document.entities.loading;
  const activeReason = state.document.reasons.showReasons;
  const { claimant, defendant } = state.document.reasons;
  const reasonIds = prepareReasonBlockIds(claimant, defendant);
  const loadingReasons = state.document.reasons.loading;
  const loadingSuccess = state.document.reasons.loadingSuccess;

  return {
    body,
    additions,
    isPractice,
    department,
    loading,
    activeReason,
    reasonIds: prepareReasonIds(body.blocks, reasonIds),
    loadingReasons,
    loadingSuccess,
    isReal: state.currentUser.role !== UserRoleType.guest,
  };
};

export const WorkspaceConnect = connect(
  mapStateToProps,
)(Workspace);

export default WorkspaceConnect;
