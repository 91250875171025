import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './control.style';

type Props = {
  onClick: () => void,
  isPinned: boolean,
};

export const PinButton = ({ onClick, isPinned }: Props) => {

  let pinPath = iconsPath.get('activePin');

  if (!isPinned) {
    pinPath = iconsPath.get('unactivePin');
  }

  return (
    <s.Root
      isActive={isPinned}
      position="relative"
      title="Закрепить фильтр"
      activeTitle="Открепить фильтр"
      alignRight
      className="filter-pin-button"
      onClick={onClick}
    >
      <Icon path={pinPath} color="#6c7b89" />
    </s.Root>
  );
};

export default PinButton;
