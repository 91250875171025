import { toggleReasonReduce } from './toggleReason.reduce';

const actionType = 'DOCUMENT/TOGGLE_REASON';

const createAction = () => ({
  type: actionType,
});

export const toggleReason = {
  actionType,
  createAction,
  reduce: toggleReasonReduce,
};

export default toggleReason;
