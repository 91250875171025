import React from 'react';

import { plural } from '@components/plural';
import { separator } from '@components/separator';

import s from '@pages/searchResult/documents/header/header.style';

import { Total } from '../redux/state';

export interface HeaderProps {
  totalDocuments: Total;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    const endings = ['документ', 'документа', 'документов'];
    const pluralize = plural(this.props.totalDocuments, endings);
    const totalDocuments = separator(this.props.totalDocuments);
    const title = `Найдено ${totalDocuments} ${pluralize}`;

    return (
      <s.TopHeader>
        <s.Title>
          {title}
        </s.Title>
      </s.TopHeader>
    );
  }

}

export default Header;
