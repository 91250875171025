import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateDocument } from './redux/updateDocument';
import { updateArticle } from './redux/updateArticle';
import { updateClause } from './redux/updateClause';

import { Block } from './block';

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateDocument(data) {
    dispatch(updateDocument.createAction(data));
  },
  onUpdateArticle(data) {
    dispatch(updateArticle.createAction(data));
  },
  onUpdateClause(data) {
    dispatch(updateClause.createAction(data));
  },
});

export const BlockConnect = connect(
  null,
  mapDispatchToProps,
)(Block);

export default BlockConnect;
