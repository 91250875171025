import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { httpFetchChronologyDocuments } from '@layouts/chronology/redux/fetchChronologyDocuments.http';
import { httpFetchSimilarDocuments } from '@layouts/similar/redux/fetchSimilarDocuments.http';

import { Source } from '@router/path.rest';
import { fetchDocument } from './fetchDocument';
import { fetchMarkers } from './fetchMarkers';
import { fetchSourceTitle } from './fetchSourceTitle';
import { resetRelations } from './resetRelations';
import { resetAttributes } from './resetAttributes';
import { resetDocument } from './resetDocument';

import { fetchReasonThunk } from './fetchReason.thunk';

import { fetchAttributes } from '../sidebar/redux/fetchAttributes';
import { fetchRelations } from '../sidebar/redux/fetchRelations';
import { fetchChronology } from '../sidebar/redux/fetchChronology';
import { fetchSimilar } from '../sidebar/redux/fetchSimilar';

import { setSource } from './setSource';
import { setDocumentId } from './setDocumentId';
import { setParentDocument } from './setParentDocument';
import { setIsChronologyDocument } from './setIsChronologyDocument';
import { setIsSimilarDocument } from './setIsSimilarDocument';

import { httpFetchPackageDocuments } from '../../packages/redux/documents/fetchDocuments.http';
import { httpFetchSubscriptionDocuments } from '../../subscriptions/workspace/documents/redux/fetchDocuments.http';
import { httpFetchAuthorPackageDocuments } from '../../authorPackages2/workspace/documents/redux/fetchDocuments.http';

import { httpFetchDocument } from './fetchDocument.http';
import { httpFetchChronologyDocument } from './fetchChronologyDocument.http';
import { httpFetchSimilarDocument } from './fetchSimilarDocument.http';
import { httpFetchMarkers } from './fetchMarkers.http';

import { httpFetchAttributes } from '../sidebar/redux/fetchAtttributes.http';
import { httpFetchRelations } from '../sidebar/redux/fetchRelations.http';

import { SourceTypes } from './state';

export interface RequestParams {
  sourceId: number;
  documentId: number;
  source: SourceTypes;
  isSimilarDocument: boolean;
  isChronologyDocument: boolean;
  parentDocumentId?: number;
}

export class FetchDocumentThunk {

  getState;

  private notificationId = 'FETCH_DOCUMENT_FAIL';

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: RequestParams) {
    const {
      documentId,
    } = params;

    if (!documentId) {
      return;
    }

    const state = this.getState();
    const sameId = state.document.id === documentId;
    if (sameId) {
      return;
    }

    const sourceIsExist = !!state.document.source && !!state.document.sourceId && !!state.document.sourceTitle;
    if (!sourceIsExist) {
      this.dispatchSetSource(params);
      this.getSource(params);
    } else {
      // eslint-disable-next-line no-param-reassign
      params.source = state.document.source;
      // eslint-disable-next-line no-param-reassign
      params.sourceId = state.document.sourceId;
    }

    this.dispatchResetRelations();
    this.dispatchResetAttributes();
    this.dispatchResetDocumentView();

    const hasChronology = !state.document.chronology.loading;
    if (!hasChronology) {
      const chronologyDocumentId = params.parentDocumentId || documentId;
      this.getChronology(chronologyDocumentId);
    }

    const hasSimilar = !state.document.similar.loading;
    if (!hasSimilar) {
      const similarDocumentId = params.parentDocumentId || documentId;
      this.getSimilar(similarDocumentId);
    }

    this.getAttributes(params);
    this.getRelations(params);

    if (!params.parentDocumentId) {
      this.dispatch(setDocumentId.createAction(Number(documentId)));
      this.dispatch(setIsChronologyDocument.createAction(false));
      this.dispatch(setIsSimilarDocument.createAction(false));
      await this.getDocument(params);
      this.getMarkers(params);
      fetchReasonThunk(this.dispatch, { documentId });

      return;
    }

    this.dispatch(setDocumentId.createAction(Number(documentId)));

    if (params.isChronologyDocument) {
      this.dispatch(setIsChronologyDocument.createAction(true));
      this.dispatch(setIsSimilarDocument.createAction(false));
      await this.getChronologyDocument(params);
      fetchReasonThunk(this.dispatch, { documentId });
    }

    if (params.isSimilarDocument) {
      this.dispatch(setIsSimilarDocument.createAction(true));
      this.dispatch(setIsChronologyDocument.createAction(false));
      await this.getSimilarDocument(params);
      fetchReasonThunk(this.dispatch, { documentId });
    }

    this.getParentDocumentAttributes(params);
  }

  private async getDocument(params) {
    try {
      const result = await httpFetchDocument(params);
      this.dispatchFetchDocumentSucceed(result);
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private async getChronologyDocument(params) {
    try {
      const result = await httpFetchChronologyDocument(params);
      this.dispatchFetchDocumentSucceed(result);
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private async getSimilarDocument(params) {
    try {
      const result = await httpFetchSimilarDocument(params);
      this.dispatchFetchDocumentSucceed(result);
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private async getMarkers(params) {
    try {
      const result = await httpFetchMarkers(params);
      this.dispatchFetchMarkersSucceed(result);
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private async getSource(params) {
    try {
      if (params.source === 'package') {
        const requestParams = {
          sourceId: params.sourceId,
          pageNumber: 1,
        };

        const result = await httpFetchPackageDocuments(requestParams);
        if (result.name) {
          this.dispatchFetchSourceTitleSucceed(result);
        }
      }

      if (params.source === 'subscription') {
        const requestParams = {
          sourceId: params.sourceId,
          pageNumber: 1,
        };

        const result = await httpFetchSubscriptionDocuments(requestParams);
        if (result.name) {
          this.dispatchFetchSourceTitleSucceed(result);
        }
      }

      if (params.source === 'authorpackage') {
        const requestParams = {
          sourceId: params.sourceId,
          pageNumber: 1,
        };

        const result = await httpFetchAuthorPackageDocuments(requestParams);
        if (result.name) {
          this.dispatchFetchSourceTitleSucceed(result);
        }
      }
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private async getAttributes(params) {
    try {
      const result = await httpFetchAttributes(params);
      this.dispatchFetchAttributesSucceed(result);
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private async getParentDocumentAttributes(params) {
    try {
      const requestParams = { documentId: params.parentDocumentId, sourceId: params.sourceId, source: params.source };
      const result = await httpFetchAttributes(requestParams);

      this.dispatchGetParentDocumentAttributesSucceed({ ...result, id: params.parentDocumentId });
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private async getRelations(params) {
    try {
      const requestParams = {
        documentId: params.documentId,
      };

      const result = await httpFetchRelations(requestParams);

      this.dispatchFetchRelationsSucceed(result);
    } catch (error) {
      if (params.source === Source.package) {
        notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        this.removeNotification();
      }
    }
  }

  private async getChronology(documentId: number) {
    const items = [];
    let pageNumber = 1;
    // eslint-disable-next-line
    const result = await httpFetchChronologyDocuments({ documentId, pageNumber });
    items.push(...result.documents);

    const stack = [];

    while (result.total_pages > pageNumber) {
      pageNumber += 1;
      stack.push(httpFetchChronologyDocuments({ documentId, pageNumber }));
    }

    const nextResult = await Promise.all(stack);
    nextResult.forEach((fetchData: any) => items.push(...fetchData.documents));

    this.dispatchFetchChronologySucceed(items);
  }

  private async getSimilar(documentId: number) {
    const items = [];
    let pageNumber = 1;
    // eslint-disable-next-line
    const result = await httpFetchSimilarDocuments({ documentId, pageNumber });
    items.push(...result.documents);

    const stack = [];

    while (result.total_pages > pageNumber) {
      pageNumber += 1;
      stack.push(httpFetchSimilarDocuments({ documentId, pageNumber }));
    }

    const nextResult = await Promise.all(stack);
    nextResult.forEach((fetchData: any) => items.push(...fetchData.documents));

    this.dispatchFetchSimilarSucceed(items);
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchSetSource(params) {
    this.dispatch(setSource.createAction(params));
  }

  private dispatchFetchDocumentSucceed(result) {
    this.dispatch(fetchDocument.createAction(result));
  }

  private dispatchFetchMarkersSucceed(result) {
    this.dispatch(fetchMarkers.createAction(result));
  }

  private dispatchFetchSourceTitleSucceed(result) {
    this.dispatch(fetchSourceTitle.createAction({ title: result.name }));
  }

  private dispatchFetchAttributesSucceed(result) {
    this.dispatch(fetchAttributes.createAction(result));
  }

  private dispatchGetParentDocumentAttributesSucceed(result) {
    this.dispatch(setParentDocument.createAction(result));
  }

  private dispatchFetchRelationsSucceed(result) {
    this.dispatch(fetchRelations.createAction(result));
  }

  private dispatchFetchChronologySucceed(items) {
    this.dispatch(fetchChronology.createAction(items));
  }

  private dispatchFetchSimilarSucceed(items) {
    this.dispatch(fetchSimilar.createAction(items));
  }

  private dispatchResetRelations() {
    this.dispatch(resetRelations.createAction());
  }

  private dispatchResetAttributes() {
    this.dispatch(resetAttributes.createAction());
  }

  private dispatchResetDocumentView() {
    this.dispatch(resetDocument.createAction());
  }

}

export function fetchDocumentThunk(dispatch, params) {
  const thunk = new FetchDocumentThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchDocumentThunk;
