import { connect } from 'react-redux';

import { changeContains } from '../redux/changeContains';
import { updateValue } from './redux/updateValue';
import { updateMode } from './redux/updateMode';
import { updateProximityRange } from './redux/updateProximityRange';

import { Block } from './block';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onChangeContains(data) {
    dispatch(changeContains.createAction(data));
  },
  onUpdateValue(data) {
    dispatch(updateValue.createAction(data));
  },
  onUpdateMode(data) {
    dispatch(updateMode.createAction(data));
  },
  onUpdateProximityRange(data) {
    dispatch(updateProximityRange.createAction(data));
  },
});

export const BlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default BlockConnect;
