import { connect } from 'react-redux';

import { Block } from '@pages/searchLaw/form/filterBlocks/date/block';

import { updateScope } from './redux/updateScope';
import { updateFirstDate } from './redux/updateFirstDate';
import { updateSecondDate } from './redux/updateSecondDate';

const mapStateToProps = (state) => {
  const scope = state.searchLaw.presets.date;

  return {
    scope,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateScope(data) {
    dispatch(updateScope.createAction(data));
  },
  onUpdateFirstDate(data) {
    dispatch(updateFirstDate.createAction(data));
  },
  onUpdateSecondDate(data) {
    dispatch(updateSecondDate.createAction(data));
  },
});

export const BlockConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Block);

export default BlockConnect;
